import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withProps, withStateHandlers } from 'recompose';
import { AdditionalServicesForm } from 'travelopod-components';

import Config from '../../constants/config';
import withRefetch from '../../Hocs/withRefetch';
import { saveInsuranceQuote } from '../../store/modules/insuranceQuote/save';

const dateFormat = 'YYYY-MM-DD';

const serializeQuote = (paymentConfirmation, billingData, { passengers }) => {
  const departureDate = paymentConfirmation.pnrTrips[0].departureDate;
  const arrivalDate =
    get(paymentConfirmation, 'pnrTrips[1].arrivalDate') || get(paymentConfirmation, 'pnrTrips[0].arrivalDate');

  const priceSummary = {
    ADULT: 0,
    CHILD: 0,
    INFANT: 0,
  };

  passengers.forEach(passenger => {
    priceSummary[passenger.type] = Number(passenger.total);
  });

  return {
    passengers: passengers.map(({ birthday, type, firstName, lastName, gender }) => ({
      passengerType: type,
      firstName,
      lastName,
      gender,
      ...(birthday && {
        dob: {
          year: birthday.format('YYYY'),
          month: birthday.format('MM'),
          day: birthday.format('DD'),
        },
      }),
    })),
    countryCode: 'US',
    stateCode: billingData.billingState.code,
    zip: billingData.billingZipCode,
    address: billingData.address,
    city: billingData.city,
    departDate: moment.unix(departureDate).format(dateFormat),
    returnDate: moment.unix(arrivalDate).format(dateFormat),
    priceSummary,
  };
};

const mapDispatchToProps = {
  saveInsuranceQuote,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStateHandlers(
    {
      quote: null,
      quoteError: false,
    },
    {
      setQuote: () => quote => ({ quote }),
      setQuoteError: () => quoteError => ({ quoteError }),
    },
  ),
  withRefetch(() => ({
    getQuote: data => ({
      getQuoteResponse: {
        force: true,
        url: `${Config.oldVersionURL}/insurance/quote`,
        method: 'POST',
        body: JSON.stringify(data),
      },
    }),
  })),
  withProps(({ states, countries, paymentConfirmation, passengers, getQuoteResponse }) => ({
    getPaymentInfoValues: values => {
      const billingAddress = get(paymentConfirmation, 'billingDetail.billingAddress');
      if (billingAddress) {
        const { billingAddressCountry, billingAddressState, address, city, zipCode } = billingAddress;

        const country = countries.find(({ name }) => name === billingAddressCountry.name);
        const state = country.billingStates.find(({ name }) => name === billingAddressState.name);

        return {
          country,
          zip: zipCode,
          state,
          city,
          address,
        };
      }
      return values && values[0];
    },
    getPassengersValues: () => ({ passengers }),
    loading: getQuoteResponse && getQuoteResponse.pending,
  })),
  withHandlers({
    onQuoteReady: ({ paymentConfirmation, getQuote }) => (billingData, passengers) => {
      const serializedQuote = serializeQuote(paymentConfirmation, billingData, passengers);

      getQuote(serializedQuote);
    },
    onQuoteInvalid: ({ saveInsuranceQuote, setQuote, setQuoteError }) => () => {
      saveInsuranceQuote(null);
      setQuote(null);
      setQuoteError(false);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { getQuoteResponse, saveInsuranceQuote, setQuote, setQuoteError } = this.props;

      if (prevProps.getQuoteResponse && prevProps.getQuoteResponse.pending && getQuoteResponse.fulfilled) {
        saveInsuranceQuote(getQuoteResponse.value);
        setQuote(getQuoteResponse.value);
      }

      if (prevProps.getQuoteResponse && prevProps.getQuoteResponse.pending && getQuoteResponse.rejected) {
        saveInsuranceQuote(null);
        setQuoteError(true);
      }
    },
  }),
)(AdditionalServicesForm);
