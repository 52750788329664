/**
 * Generates v4 random UUID. If length parameter is provided will generate a pseudo UUID of the desired length.
 */
export default (length = 32) => {
  let result;
  let val;

  for (
    result = val = '';
    // eslint-disable-next-line no-plusplus
    val++ < length;
    result += (val * 51) & 52 ? (val ^ 15 ? 8 ^ (Math.random() * (val ^ 20 ? 16 : 4)) : 4).toString(16) : '-'
  );

  return result;
};
