// @flow
import './SearchButton.css';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { Button, DesktopLarge, FlexBox, Mobile, PencilIcon, Tablet, Text } from 'travelopod-components';

import { TRIP_TYPE } from '../../constants/global';
import { SERVICE_CLASSES } from '../../constants/priceCalendar';

const getFromToLabel = ({ from, to }) => {
  const fromCode = !from || !Object.keys(from).length ? 'Origin' : from.code;
  const toCode = !to || !Object.keys(to).length ? 'Destination' : to.code;

  return `${fromCode} — ${toCode}`;
};

const pluralize = (amount, singular, plural) => (amount === 1 ? singular : plural);

const getFiltersLabel = (filters) => {
  if (isEmpty(filters)) {
    return 'Undefined search criteria';
  }

  const { startDate, endDate, adults, children, infants, serviceClass } = filters;

  const values = [];

  const momentStartDate = moment.unix(startDate).utc();
  const momentEndDate = moment.unix(endDate).utc();

  const startDateMonth = momentStartDate.format('MMMM');
  const endDateMonth = momentEndDate.format('MMMM');

  if (filters.tripType === TRIP_TYPE.oneWay) {
    values.push(`${momentStartDate.format('D MMMM')}`);
  }

  if (filters.tripType === TRIP_TYPE.roundTrip) {
    if (startDateMonth === endDateMonth) {
      values.push(`${momentStartDate.format('D')} — ${momentEndDate.format('D')} ${startDateMonth}`);
    } else {
      values.push(`${momentStartDate.format('D MMMM')} ${momentEndDate.format('D MMMM')}`);
    }
  }

  values.push(`${adults} ${pluralize(adults, 'adult', 'adults')}`);

  Number(children) && values.push(`${children} ${pluralize(children, 'child', 'children')}`);
  Number(infants) && values.push(`${infants} ${pluralize(infants, 'infant', 'infants')}`);

  const selectedServiceClass = SERVICE_CLASSES.find(({ value }) => value === serviceClass);

  selectedServiceClass && values.push(selectedServiceClass.label);

  return values.join(', ');
};

const SearchButton = ({ filters, initialValues, ...props }) => {
  return (
    <Button fluid weight="normal" size="large" {...props}>
      <DesktopLarge>
        <FlexBox justifyContent="space-between">
          <FlexBox direction="column" alignItems="baseline">
            <Text as="strong" color="white" size="big">
              {getFromToLabel({
                ...filters,
                from: initialValues[0],
                to: initialValues[1],
              })}
            </Text>
            <Text color="white">{getFiltersLabel(filters)}</Text>
          </FlexBox>
          <FlexBox alignItems="center">
            <Text className="search-button-edit-label" color="white">
              Edit
            </Text>
          </FlexBox>
        </FlexBox>
      </DesktopLarge>
      <Tablet>
        <FlexBox alignItems="center" justifyContent="flex-start">
          <Text as="strong" color="white" size="big">
            {getFromToLabel({
              ...filters,
              from: initialValues[0],
              to: initialValues[1],
            })}
          </Text>
          <PencilIcon color="white" ma="l" />
        </FlexBox>
        <Text color="white">{getFiltersLabel(filters)}</Text>
      </Tablet>
      <Mobile>
        <FlexBox alignItems="center" justifyContent="flex-start">
          <Text as="strong" color="white" size="big">
            {getFromToLabel({
              ...filters,
              from: initialValues[0],
              to: initialValues[1],
            })}
          </Text>
          <PencilIcon color="white" ma="l" />
        </FlexBox>
        <Text className="search-button-info" color="white">
          {getFiltersLabel(filters)}
        </Text>
      </Mobile>
    </Button>
  );
};

export default SearchButton;
