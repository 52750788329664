import config, { IBE_BASE_URL } from '../../constants/config';
import { serializeCalendarFilters } from '../../helpers/formCalendarParams';
import API from '../base/api';
import type { ReqTypeT } from '../index';

export type AirportT = {
  airportCity: Object,
  airportCityId: number,
  airportCountry: Object,
  airportCountryId: number,

  code: string,
  conference: string,
  continent: string,
  distanceIndicator: string,
  id: number,
  imageUrl: string,
  latitude: string,
  longitude: string,
  name: string,
  searchString: string,
  timeZone: number,
  timezoneName: string,
};

export type PriceCalendarT = {
  cabinType: string,
  departDate: number,
  from: string,
  paxReference: Array<Object>,
  returnDate: number,
  to: string,
};

export type AirportsListT = Array<AirportT>;

class PriceCalendarApi extends API {
  async searchCalendarPrices(data: PriceCalendarT) {
    return this.r({
      method: 'POST',
      url: `${config.reserveURL}/flights/ibe-calendar`,
      data,
    });
  }

  async getListOfAirports(data: AirportsListT) {
    return this.r({
      method: 'GET',
      url: `${config.baseURL}/api/airports?q=${data}`,
    });
  }
}

export default function priceCalendarApi(request: ReqTypeT) {
  return new PriceCalendarApi(request);
}

export function cheapestInitLoad(filters, domain) {
  return {
    cheapestResponse: {
      url: `${IBE_BASE_URL}/flights/calendar-cheapest-day`,
      method: 'POST',
      body: JSON.stringify({ ...serializeCalendarFilters(filters), domain }),
    },
  };
}
export function cheapestLoadRecommendations(filters, domain) {
  return {
    cheapestResponse: {
      url: `${IBE_BASE_URL}/flights/cheapest-recommendations`,
      method: 'POST',
      body: JSON.stringify({ ...serializeCalendarFilters(filters), domain }),
    },
  };
}
