import PriceCalendarT from '../../../api/modules/priceCalendar';

export const SEARCH_PRICE_CALENDAR: 'priceCalendar/SEARCH_PRICE_CALENDAR' = 'priceCalendar/SEARCH_PRICE_CALENDAR';
export const SEARCH_PRICE_CALENDAR_SUCCESS: 'payment/SEARCH_PRICE_CALENDAR_SUCCESS' =
  'priceCalendar/SEARCH_PRICE_CALENDAR_SUCCESS';
export const SEARCH_PRICE_CALENDAR_FAILURE: 'payment/SEARCH_PRICE_CALENDAR_FAILURE' =
  'priceCalendar/SEARCH_PRICE_CALENDAR_FAILURE';

type SearchPriceCalendar = {
  type: typeof SEARCH_PRICE_CALENDAR,
};

export type SearchPriceCalendarSuccess = {
  type: typeof SEARCH_PRICE_CALENDAR_SUCCESS,
  result: {
    data: PriceCalendarT,
  },
};
type SearchPriceCalendarFailure = {
  type: typeof SEARCH_PRICE_CALENDAR_FAILURE,
  error: string,
};

type ActionType = SearchPriceCalendar | SearchPriceCalendarSuccess | SearchPriceCalendarFailure;

export type State = {
  loading: boolean,
  error: ?string,
  loaded: boolean,
};

const initialState: State = {
  loading: false,
  error: null,
  loaded: false,
};

export default function searchPriceCalendarReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case SEARCH_PRICE_CALENDAR:
      return {
        ...initialState,
        loading: true,
      };

    case SEARCH_PRICE_CALENDAR_SUCCESS:
      return {
        ...initialState,
        loaded: true,
      };

    case SEARCH_PRICE_CALENDAR_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function searchPriceCalendar(data: any) {
  return {
    type: SEARCH_PRICE_CALENDAR,
    data,
  };
}
