export const SAVE_INSURANCE_QUOTE = 'insuranceQuote/SAVE_INSURANCE_QUOTE';

const initialState = {
  quoteValue: null,
};

export default function saveInsuranceQuoteReducer(state = initialState, action) {
  if (action.type === SAVE_INSURANCE_QUOTE) {
    return {
      quoteValue: action.value,
    };
  }

  return state;
}

export function saveInsuranceQuote(data) {
  return {
    type: SAVE_INSURANCE_QUOTE,
    data,
  };
}
