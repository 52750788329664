// @flow
import React from 'react';
import { compose } from 'recompose';
import { Button, FilterIcon, FlexBox, withLoader } from 'travelopod-components';

const SearchPanelMobile = ({ onOpen, filtersButton, onClick }) => (
  <FlexBox
    backgroundColor="link"
    pa="l r b t"
    alignItems="center"
    justifyContent="space-between"
    className="flight-page--search-modile"
  >
    <div
      onClick={() => {
        setTimeout(onOpen, 0);
      }}
    >
      {filtersButton}
    </div>
    <Button onClick={onClick} size="large" color="primary" className="filters-button">
      <FilterIcon color="white" ma="r-half" />
      Sort & Filter
    </Button>
  </FlexBox>
);

export default compose(withLoader(({ responses }) => responses.some((el) => el && el.pending)))(SearchPanelMobile);
