import './FlightPage.css';

import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Config from '../../constants/config';
import withFilters from '../../Hocs/withFilters';
import withRefetch from '../../Hocs/withRefetch';
import FlightLayout from './components/Layout/Layout';
import PageContent from './components/PageContent/PageContent';
import { getInitialAirports } from '../../api/modules/searchFlights';
import FlightsFooter from './components/FlightsFooter/FlightsFooter';
import SearchButton from '../../components/SearchButton/SearchButton';
import { updatePhoneTFN } from '../../store/modules/responseTap/phoneData';
import FlightListFilters from '../../components/FlightListFilters/FlightListFilters';
import PriceCalendarFilters from './components/PriceCalendarFilters/PriceCalendarFilters';

const defaultFilters = () => ({
  startDate: moment().utc().startOf('day').unix(),
  endDate: moment().utc().startOf('day').unix(),
  serviceClass: 'ECONOMY',
  optionsSearch: 'EXACT',
  tripType: 'ROUND_TRIP',
  from: null,
  to: null,
  adults: 1,
  children: 0,
  infants: 0,
});

class FlightsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: [],
      isBannersShown: false,
      ipAction: '',
    };
  }

  componentDidMount() {
    this.updateAirportIATA();
  }

  async updateAirportIATA() {
    const { queryFilters } = this.props;
    if (!queryFilters.to || !queryFilters.from) {
      return;
    }

    const result = await getInitialAirports(
      queryFilters.from,
      queryFilters.to,
      queryFilters.returnFrom,
      queryFilters.returnTo,
    );

    this.onInit(result);

    if (result && result[1] && Object.keys(result[1]) && Object.keys(result[1]).length) {
      this.props.updatePhoneTFN({ destinationIATA: queryFilters.to, cabin: queryFilters.serviceClass });
    }
  }

  componentDidUpdate(prevProps) {
    const { bannerStatusFetch } = this.props;

    if (!bannerStatusFetch.pending && prevProps.bannerStatusFetch.pending !== bannerStatusFetch.pending) {
      let isBannersShown = bannerStatusFetch.value && bannerStatusFetch.value.data.show;
      let ipAction = bannerStatusFetch.value && bannerStatusFetch.value.data.ipAction;

      this.setState({ isBannersShown: isBannersShown, ipAction: ipAction });

      if (ipAction === 'block' || ipAction === 'redirect') {
        window.location.href = window.location.origin + '/404';
      }
    }
  }

  onInit(data) {
    this.setState({ initialValues: data });
  }

  handleBookFlight = (flight) => {
    const { saveFlightKey, queryFilters } = this.props;

    saveFlightKey(flight, queryFilters);
  };

  getIsPending() {
    const { calendarResponse, searchFlightsResponse } = this.props;

    if (!calendarResponse) {
      return null;
    }

    if (!searchFlightsResponse) {
      return true;
    }

    return !searchFlightsResponse.settled || !calendarResponse.settled;
  }

  render() {
    const {
      searchFlights,
      searchFlightsResponse,
      calendarResponse,
      calendarStashResponse,
      queryFilters,
      isOpen,
      onClose,
      onOpen,
      onDatesChanges,
      isFirstFetch,
      airportsToResponse,
      clearCalendar,
      modal10MinuteStatus,
      onDatesChange,
      pricesData,
      handleToSelect,
      handleFromSelect,
      pricesPending,
      ibeBannerConfigResponse,
      handleReturnToSelect,
      handleReturnFromSelect,
    } = this.props;

    const { initialValues, isBannersShown } = this.state;
    const cityName = get(airportsToResponse, 'value.data[0].airportCity.name');
    const ibeBannerConfig = ibeBannerConfigResponse?.fulfilled ? ibeBannerConfigResponse.value.data : null;

    return (
      <React.Fragment>
        <FlightLayout
          onClick={onOpen}
          calendarResponse={calendarResponse}
          calendarStashResponse={calendarStashResponse}
          searchFlightsResponse={searchFlightsResponse}
        >
          {/* FILTER TABS */}
          <FlightListFilters
            loading={this.getIsPending() && isFirstFetch}
            serverFilters={searchFlightsResponse?.value ? searchFlightsResponse.value.filters : null}
            searchFlightsResponse={searchFlightsResponse}
            closeFiltersModal={onClose}
            isOpenedFiltersBlock={isOpen}
            calendarResponse={searchFlightsResponse}
          />

          {/* FLIGHT SEARCH BAR */}
          <PriceCalendarFilters
            responses={[searchFlightsResponse, calendarResponse]}
            onSearch={clearCalendar}
            initialValues={initialValues}
            onInit={(data) => {
              this.onInit(data);
            }}
            updateAirportIATA={() => {
              this.updateAirportIATA();
            }}
            onDatesChange={onDatesChange}
            pricesData={pricesData}
            pricesPending={pricesPending}
            handleToSelect={handleToSelect}
            handleFromSelect={handleFromSelect}
            handleReturnToSelect={handleReturnToSelect}
            handleReturnFromSelect={handleReturnFromSelect}
          />

          {/* FLIGHT LIST CONTENT */}
          <PageContent
            transactionId={this.props.transactionId}
            loading={this.getIsPending()}
            onDatesChange={onDatesChanges}
            onBookFlight={this.handleBookFlight}
            calendarStashResponse={calendarStashResponse}
            calendarResponse={calendarResponse}
            searchFlights={searchFlights}
            searchFlightsResponse={searchFlightsResponse}
            cityName={cityName}
            showRefreshResultsModal={modal10MinuteStatus}
          />

          {/* ONLY ON TABLET AND MOBILE VIEWS SEARCH BTN */}
          <SearchButton filters={queryFilters} initialValues={initialValues} className="flight-page--button-modile" />
        </FlightLayout>

        {/* FOOTER BANNER */}
        {isBannersShown && (
          <FlightsFooter
            cityName={cityName}
            searchFlightsResponse={searchFlightsResponse}
            footerBannerConfig={ibeBannerConfig?.footerBanner}
          />
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  connect(null, {
    updatePhoneTFN,
  }),
  withRefetch(({ getDomainName }) => {
    let domainName = getDomainName();
    return {
      bannerStatusFetch: `${Config.callTrackingUrl}/api/call-tracking/banner?domain=${domainName}`,
    };
  }),
  withFilters(defaultFilters),
)(FlightsPage);
