import get from 'lodash/get';
import { branch, compose, renderComponent, withProps } from 'recompose';
import { PaymentInfoForm as BasePaymentInfoForm } from 'travelopod-components';

import BillingInformation from './BillingInformation';

export default compose(
  withProps(({ billingCard }) => ({
    cardType: get(billingCard, 'cardType'),
    last4: get(billingCard, 'last4'),
  })),
  branch(({ billingCard }) => Boolean(billingCard), renderComponent(BillingInformation)),
)(BasePaymentInfoForm);
