import { capitalize } from 'lodash';
import React from 'react';

import Indent from '../../../components/Indent';
import LabeledInfo from '../../../components/LabeledInfo';
import Header from '../../../components/Typography/Header';
import { SHORT_MONTH_DATE_FORMAT } from '../../../constants/global';
import { PASSENGER_NOT_NECESSARY_INFO } from '../../../constants/payment';
import { unixToUtc } from '../../../helpers/dateHelper';

const Passenger = ({ index, passengerData }) => (
  <React.Fragment>
    <Header as="h3">
      {index} Passenger&nbsp;
      {passengerData.gender && `(${capitalize(passengerData.gender)})`}
    </Header>
    <Indent padding="topP" as="div">
      <LabeledInfo
        label="Name"
        info={`${capitalize(passengerData.firstName)} ${capitalize(passengerData.lastName.toLowerCase())}`}
        className="capitalized"
      />
      {passengerData.dateOfBirth && (
        <LabeledInfo label="Date of Birth" info={unixToUtc(passengerData.dateOfBirth, SHORT_MONTH_DATE_FORMAT)} />
      )}
    </Indent>
    {PASSENGER_NOT_NECESSARY_INFO.map(passenger => {
      if (passengerData[passenger.field]) {
        return <LabeledInfo label={passenger.name} className="capitalized-str" info={passengerData[passenger.field]} />;
      }
      return null;
    })}
  </React.Fragment>
);

export default Passenger;
