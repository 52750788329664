import { get } from 'lodash';
import React from 'react';

import PassengerIcon from '../assets/icons/passengers.svg';
import { SHORT_DATE_FORMAT } from '../constants/global';
import { getFullname } from '../helpers/passengers';
import { unixToUtc } from './dateHelper';

export const tripIsNotOneWay = data => {
  return get(data.bookingData, 'pnrTrips.length') > 1;
};

export const formItinerary = (index, data) => {
  return `${unixToUtc(get(data.bookingData.pnrTrips[index], 'departureDate', ''), SHORT_DATE_FORMAT)}, ${get(
    data.bookingData.pnrTrips[index],
    'tripOriginAirport.code',
  )} - ${get(data.bookingData.pnrTrips[index], 'tripDestinationAirport.code', '')}`;
};

export const formPassengerDetails = (passengerDetail = []) => {
  return passengerDetail.map((passenger, i) => {
    return (
      <p key={i}>
        <img alt="passenger icon" src={PassengerIcon} className="passenger-icon custom-icon" />
        {getFullname(passenger)}
      </p>
    );
  });
};
