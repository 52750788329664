import React from 'react';
import { compose } from 'recompose';
import { withThemeProvider } from 'travelopod-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ModalMain from './modals';
import Content from './components/Content';
import * as routes from './constants/routes';
// import FetchUser from './components/FetchUser';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MarketingMessages from './components/MarketingMessages/MarketingMessages';

import NotFound from './screens/NotFound';
import FlightsPage from './screens/Flights/FlightsPage';
// import GoogleOneTapScript from './components/GoogleOneTapScript';
import BookFlightPage from './screens/BookFlight/BookFlightPage';
import SuccessfulConfirmation from './screens/SuccessfulPaymentConfirmation';
import BookFlightSuccessPage from './screens/BookFlight/BookFlightSuccessPage';
import BillingConfirmationPage from './screens/PaymentConfirmation/BillingConfirmationPage';
import ItineraryDetailsFlightPage from './screens/ItineraryDetails/ItineraryDetailsFlightPage';
import ItineraryDetailsPaymentPage from './screens/ItineraryDetails/ItineraryDetailsPaymentPage';

const RootLayout = ({ children }) => {
  return (
    <ModalMain>
      <Banner />
      <MarketingMessages>
        {/* HEADER */}
        <Header />

        {/* PAGE CONTENT */}
        <Content>{children}</Content>

        {/* FOOTER  */}
        <Footer />

        {/* <GoogleOneTapScript /> */}

        {/* <FetchUser /> */}
      </MarketingMessages>
    </ModalMain>
  );
};

const AppRouter = () => (
  <BrowserRouter>
    <RootLayout>
      <Routes>
        <Route path={routes.FLIGHT_ITINERARY_DETAILS} element={<ItineraryDetailsFlightPage />} />
        <Route path={routes.BOOK_FLIGHT_SUCCESS} element={<BookFlightSuccessPage />} exact />
        <Route path={routes.BOOK_FLIGHT} element={<BookFlightPage />} />
        <Route path={routes.SEARCH} element={<FlightsPage />} />
        <Route path={routes.BILLING_CONFIRMATION} element={<BillingConfirmationPage />} />
        <Route path={routes.ITINERARY_DETAILS} element={<ItineraryDetailsPaymentPage />} />
        <Route path={routes.SUCCESSFUL_CONFIRMATION} element={<SuccessfulConfirmation />} />
        <Route path={routes.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </RootLayout>
  </BrowserRouter>
);

export default compose(withThemeProvider({ mode: 'travelopod' }))(AppRouter);
