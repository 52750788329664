// @flow
import moment from 'moment';

const DATE_FORMAT = 'YYYYMMDD';

type ParsedPassT = {
  from?: string,
  to?: string,
  startDate?: string,
  optionsSearch?: string,
  serviceClass?: string,
  [paxType: string]: string,
};

export default function parseFlightsPath(path: string, campaign: boolean): ParsedPassT {
  const segments = path.split('/').filter(Boolean);
  let from, to, startDate;

  if (campaign) {
    [, , from, to] = segments.splice(0, 4);
  } else {
    [, from, to] = segments.splice(0, 3);
  }

  const parsedPath = {
    from,
    to,
  };

  // check for multicity
  if (!Number.parseInt(segments[0])) {
    parsedPath.returnFrom = segments.splice(0, 1)[0];
    parsedPath.returnTo = segments.splice(0, 1)[0];
  }

  parsedPath.startDate = segments.splice(0, 1)[0];
  // suppose that next argument is return date
  if (Number.parseInt(segments[0])) {
    parsedPath.endDate = segments.splice(0, 1)[0];
  }

  const [optionsSearch, serviceClass] = segments.splice(0, 2);

  parsedPath.optionsSearch = optionsSearch;
  parsedPath.serviceClass = serviceClass;

  // rest of path arguments are passengers
  segments.forEach((passenger) => {
    const [amount, passengerType] = passenger.split('-');

    parsedPath[passengerType] = amount;
  });

  if (Object.keys(parsedPath).some((key) => !Boolean(parsedPath[key]))) {
    return null;
  }

  const filters = {
    ...parsedPath,
    tripType: parsedPath.returnFrom ? 'MULTI_CITY' : parsedPath.endDate ? 'ROUND_TRIP' : 'ONE_WAY',
    startDate: moment.utc(parsedPath.startDate, DATE_FORMAT).unix(),
  };

  parsedPath.endDate && (filters.endDate = moment.utc(parsedPath.endDate, DATE_FORMAT).unix());

  return filters;
}
