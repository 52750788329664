// @flow
import get from 'lodash/get';
import { compose } from 'recompose';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Aside from '../../components/Aside';
import withLayout from '../../Hocs/withLayout';
import withDomain from '../../Hocs/withDomain';
import { getParameterByName } from '../../helpers';
import Loader from '../../components/Loader/Loader';
import { connect } from 'react-redux';
import BookingConfirmation from './BookingConfirmation';
import ErrorMessage from '../../components/ErrorMessage';
import { triggerPageView } from '../../helpers/GoogleAnalytics';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import Suggestions from '../../components/Suggestions/Suggestions';
import { fetchReceipt } from '../../store/modules/payment/receipt';
import withScrollRestoration from '../../Hocs/withScrollRestoration';
import { setBookingPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';

type PropsT = {};

class SuccessfulPaymentConfirmation extends Component<PropsT> {
  componentDidMount() {
    triggerPageView();
    this.props.fetchReceipt(getParameterByName('token'));
    if (!localStorage.getItem('call_tracking_initial_params')) {
      this.props.setBookingPhone();
    }
  }

  render() {
    const { data, loading, error } = this.props;

    if (loading && !error) {
      return <Loader />;
    }

    if (error) {
      return <ErrorMessage error={error} />;
    }

    const destinationCity =
      get(data, 'pnrTrips[0].tripDestinationAirport.airportCity.name', '') ||
      get(data.enquiry, 'enquiryTrips[0].destination.airportCity.name', '');

    return (
      <React.Fragment>
        <BookingConfirmation bookingData={data} />
        <Suggestions destinationCity={destinationCity} />
      </React.Fragment>
    );
  }
}

export default compose(
  withLayout({ Aside }),
  withCallTrackingUrl(),
  connect(
    ({ payment }) => ({
      data: payment.receipt.data,
      loading: payment.receipt.loading,
      error: payment.receipt.error,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          fetchReceipt,
          updatePhoneTFN,
          setBookingPhone,
        },
        dispatch,
      ),
  ),
  withDomain(),
  withScrollRestoration,
)(SuccessfulPaymentConfirmation);
