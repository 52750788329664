import { CARD_TYPES_FROM_SERVER, DATA_ABSENCE_STRING } from '../constants/global';

export const getCardLabel = cardType => {
  if (!CARD_TYPES_FROM_SERVER[cardType]) {
    return DATA_ABSENCE_STRING;
  }

  return CARD_TYPES_FROM_SERVER[cardType].name;
};

export const formatAirport = ({ name, code }) => {
  return `${name} (${code})`;
};

export const formatDuration = num => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;

  if (num < 60) return `${minutes}m`;

  return `${hours}h ${minutes}m`;
};

export const getDateSubstring = date => date.substring(0, date.indexOf(' '));

export const parseFieldsName = fields =>
  Object.values(fields)
    .map(el => Object.keys(el))
    .reduce((prev, cur) => prev + (prev.length && cur.length ? ',' : '') + cur);
