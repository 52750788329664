import { get } from 'lodash';
import React from 'react';
import Indent from '../../../components/Indent';
import { Button, Grid } from 'travelopod-components';
import FlightIcon from '../../../assets/icons/flight_details.svg';
import ItineraryDetails from '../../../components/ItineraryDetails';
import { paymentIsManualyImported } from '../../../helpers/lib/common';
import { formItinerary, formPassengerDetails, tripIsNotOneWay } from '../../../helpers/trips';

const BookedItineraryDetails = (data) => {
  const itineraryDetailsArr = JSON.parse(localStorage.getItem('itinerary-details')) || [];
  const itineraryToken = get(
    itineraryDetailsArr.find((detail) => {
      return detail.id === get(data, 'bookingData.billingDetail.id');
    }),
    'token',
    '',
  );
  return (
    <div className="booking-information">
      <h3>Just Booked</h3>
      {!paymentIsManualyImported(data.bookingData.importType) && (
        <React.Fragment>
          <Indent as="p" padding="none" margin="marginB">
            <h4>
              {data.bookingData.pnrTrips && get(data.bookingData.pnrTrips[0], 'tripOriginAirport.airportCity.name', '')}
              &nbsp; -&nbsp;
              {data.bookingData.pnrTrips &&
                get(data.bookingData.pnrTrips[0], 'tripDestinationAirport.airportCity.name', '')}
            </h4>
          </Indent>
          <Grid.Row className="booking-itinerary" gutter={40} md={[6, 10]} sm={[6, 10]} xs={[8, 8]}>
            <div>
              <Indent as="p" padding="none" margin="marginB">
                <img alt="flight icon" src={FlightIcon} className="flight-icon custom-icon" />
                {data.bookingData.pnrTrips && formItinerary(0, data)}
              </Indent>
              {tripIsNotOneWay(data) && (
                <p>
                  <img alt="flight icon" src={FlightIcon} className="flight-icon custom-icon back-flight-icon" />
                  {data.bookingData.pnrTrips && formItinerary(data.bookingData.pnrTrips.length - 1, data)}
                </p>
              )}
            </div>
            <div>{data.bookingData.passengerDetail && formPassengerDetails(data.bookingData.passengerDetail)}</div>
          </Grid.Row>

          <Button
            secondary
            content="View Itinerary"
            href={`./itinerary-details?token=${itineraryToken}`}
            target="_blank"
          />
        </React.Fragment>
      )}

      {paymentIsManualyImported(data.bookingData.importType) && (
        <ItineraryDetails rawItinerary itineraryDetail={data.bookingData.itineraryDetail} />
      )}
    </div>
  );
};

export default BookedItineraryDetails;
