// @flow
import React from 'react';
import { compose } from 'recompose';
import {
  Button,
  FlexBox,
  FormHandler,
  FormWrapper,
  ReviewPolicy,
  Text,
  ReferralCodeBlock,
} from 'travelopod-components';

import Loader from '../../components/Loader/Loader';
import {
  MARKETING_PRIVACY_POLICY,
  MARKETING_TERMS_AND_CONDITIONS,
  MARKETING_URL_BOOKINGRULES,
} from '../../constants/global';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import AdditionalServicesForm from './AdditionalServicesForm';
import PaymentInfoForm from './PaymentInfoForm';
import PriceSummary from './PriceSummary/PriceSummary';

const PaymentConfirmationForm = ({
  manualImport,
  paymentConfirmation,
  confirmPaymentPending,
  billingCard,
  countries,
  usaStates,
  passengers,
  onSubmit,
  buildMarketingUrl,
  onValidationError,
  handleUpgradeSupportStateChange,
  upgradeSupportPackage,
  isUpgradeSupportReadOnly,
  billingDetailCustomer,
  paymentCopyPage,
}) => {
  return (
    <FormHandler onSubmit={onSubmit} onError={onValidationError}>
      <FormWrapper groupName="PaymentInfo">
        <PaymentInfoForm countries={countries} billingCard={billingCard} />
      </FormWrapper>
      {!manualImport && (
        <FormWrapper withOtherFormValues groupName="AdditionalService">
          <AdditionalServicesForm
            hasInsurance={Boolean(paymentConfirmation.bookingInsurance)}
            paymentCopyPage={paymentCopyPage}
            paymentConfirmation={paymentConfirmation}
            countries={countries}
            states={usaStates}
            passengers={passengers}
            upgradeSupportPackage={upgradeSupportPackage}
            handleUpgradeSupportStateChange={handleUpgradeSupportStateChange}
            readOnly={isUpgradeSupportReadOnly}
          />
        </FormWrapper>
      )}

      <FormWrapper groupName="referralInfo">
        <ReferralCodeBlock />
      </FormWrapper>

      <ReviewPolicy
        links={{
          bookingRules: buildMarketingUrl(MARKETING_URL_BOOKINGRULES),
          termsConditions: buildMarketingUrl(MARKETING_TERMS_AND_CONDITIONS),
          privacyPolicy: buildMarketingUrl(MARKETING_PRIVACY_POLICY),
        }}
      />
      <PriceSummary
        data={paymentConfirmation}
        enquiry={paymentConfirmation.enquiry}
        upgradeSupportPackage={!isUpgradeSupportReadOnly ? upgradeSupportPackage : null}
      />

      {paymentCopyPage ? (
        <FlexBox ma="v-big" justifyContent="center">
          <Text color="red" style={{ fontSize: '16px' }}>
            Authorized from {billingDetailCustomer.ipAddress}
          </Text>
        </FlexBox>
      ) : (
        <FlexBox ma="v-big" justifyContent="center">
          {confirmPaymentPending ? (
            <Loader size="small" inline />
          ) : (
            <Button type="submit" size="large" content="Authorize and Complete Booking" />
          )}
        </FlexBox>
      )}
    </FormHandler>
  );
};

export default compose(withCallTrackingUrl())(PaymentConfirmationForm);
