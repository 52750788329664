const EXIT_INTENT_FIELD = 'exit_intent';

export function markAsOpened() {
  localStorage.setItem(EXIT_INTENT_FIELD, true);
}

export function wasOpened() {
  const item = localStorage.getItem(EXIT_INTENT_FIELD);

  return item && JSON.parse(item);
}
