import './Countdownfinal.css';

import React from 'react';
import { Modal } from 'semantic-ui-react';

import flightWarning from '../../assets/images/flightWarning.svg';

function Countdownwarning({ isModalShow, setIsModalShow }) {
  function OverflowRemover() {
    document.body.style.overflow = 'auto';
    setIsModalShow(false);
  }

  return (
    <Modal onClose={() => setIsModalShow(false)} onOpen={() => setIsModalShow(true)} open={isModalShow}>
      <div className="countdownwarning">
        <div id="insideCOuntdownWarmning" style={{ position: 'relative' }}>
          <img style={{ marginTop: '10px' }} src={flightWarning} alt="" />
          <h1 style={{ marginTop: '15px' }}>Hurry, Act Fast!</h1>
          <p>The search will be refreshed and fare may change!</p>
          <div className="btn">
            <button onClick={OverflowRemover}>Okay!</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Countdownwarning;
