// @flow
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import {
  AccreditedBusinessBanner,
  DesktopLarge,
  Error,
  FlexBox,
  Indent,
  Mobile,
  ReviewFlight,
} from 'travelopod-components';
import withRouter from '../../Hocs/wihRouter.js';
import { connect } from 'react-redux';
import PnrRemarks from './PnrRemarks';
import uuid from '../../helpers/uuid';
import identity from 'lodash/identity';
import queryString from 'query-string';
import Config from '../../constants/config';
import withDomain from '../../Hocs/withDomain';
import Aside from '../../components/Aside/Aside';
import withRefetch from '../../Hocs/withRefetch';
import { confirmPayment } from '../../api/payment';
import Loader from '../../components/Loader/Loader';
import { pnrTripsToFlight } from '../../helpers/flights';
import PassengerInfo from './PassengerInfo/PassengerInfo';
import { setupUpgradeSupportPackage } from '../../helpers';
import { parseFieldsName } from '../../helpers/formatHelper';
import { Events } from '../../constants/googleAnalyticsEvents';
import ItineraryDetails from '../../components/ItineraryDetails';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import AirlineLogo from '../../components/AirlineLogo/AirlineLogo';
import PaymentConfirmationForm from './PaymentConfirmationForm.js';
import { paymentIsManualyImported } from '../../helpers/lib/common';
import HelpBanner from '../../components/Banners/HelpBanner/HelpBanner';
import { trigger, triggerPageView } from '../../helpers/GoogleAnalytics';
import CountDataCollector from '../../components/Count/CountDataCollector';
import { DEFAULT_PAYMENT_ERR_MSG, PAYMENT_ERRORS } from '../../constants/payment';
import { setBookingPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';
import GuaranteedSafeAndSecureBanner from '../../components/Banners/GuaranteedSafeAndSecureBanner';
import { branch, compose, lifecycle, renderComponent, withHandlers, withProps, withStateHandlers } from 'recompose';

const BillingConfirmation = ({
  kountSessionId,
  paymentConfirmation,
  confirmPaymentPending,
  confirmPaymentError,
  manualImport,
  passengers,
  countries,
  usaStates,
  billingCard,
  onSubmit,
  handleValidationError,
  handleUpgradeSupportStateChange,
  upgradeSupportPackage,
  isUpgradeSupportReadOnly,
  fetchPaymentConfirmation,
  billingDetailCustomer,
  location,
}) => {
  const paymentCopyPage = location.search.includes('sendDataForCopyAuthorizationPage');
  return (
    <div className={!paymentCopyPage ? `book-flight-page` : 'payment-copy-page'}>
      <div className="book-flight-page-item">
        {!manualImport && (
          <ReviewFlight
            bookingNumber={paymentConfirmation.enquiryId}
            flight={pnrTripsToFlight(paymentConfirmation)}
            airlineLogoComponent={AirlineLogo}
            formatTrip={identity}
          >
            <PnrRemarks pnrRemarks={paymentConfirmation.pnrRemarks} />
          </ReviewFlight>
        )}
        {manualImport && <ItineraryDetails itineraryDetail={paymentConfirmation.itineraryDetail} />}
        {!manualImport && <PassengerInfo passengers={passengers} />}
        <Indent ma="b-double" />
        <PaymentConfirmationForm
          manualImport={manualImport}
          paymentConfirmation={paymentConfirmation}
          confirmPaymentPending={confirmPaymentPending}
          onValidationError={handleValidationError}
          billingCard={billingCard}
          countries={countries}
          usaStates={usaStates}
          passengers={passengers}
          onSubmit={onSubmit}
          upgradeSupportPackage={upgradeSupportPackage}
          travelMarket={fetchPaymentConfirmation.value.data.travelMarket}
          handleUpgradeSupportStateChange={handleUpgradeSupportStateChange}
          isUpgradeSupportReadOnly={isUpgradeSupportReadOnly}
          billingDetailCustomer={billingDetailCustomer}
          paymentCopyPage={paymentCopyPage}
        />
        {confirmPaymentError && (
          <Error align="center" message="Failed to confirm payment. Please contact customer support for assistance." />
        )}
        <Mobile>
          <HelpBanner />
          <Indent ma="t-large">
            <AccreditedBusinessBanner />
          </Indent>
        </Mobile>
      </div>
      <DesktopLarge>
        <div className="book-flight-page-aside">
          <Aside asideRoot={React.Fragment}>
            <GuaranteedSafeAndSecureBanner />
          </Aside>
        </div>
      </DesktopLarge>
      <CountDataCollector sessionId={kountSessionId} />
    </div>
  );
};

const loaderPredicate = ({ fetchPaymentConfirmation, fetchCountries }) =>
  fetchPaymentConfirmation.pending || fetchCountries.pending;
const errorPredicate = ({ fetchPaymentConfirmation, fetchCountries }) =>
  fetchPaymentConfirmation.rejected || fetchCountries.rejected;

export default compose(
  withRouter,
  withProps(({ location }) => {
    return {
      token: queryString.parse(location.search).token,
    };
  }),
  withCallTrackingUrl(),
  withRefetch(({ token, location, ...rest }) => {
    const getCopyPage = location.search.includes('sendDataForCopyAuthorizationPage');

    return {
      fetchPaymentConfirmation: {
        url: getCopyPage
          ? `${Config.baseURL}/api/copy-payment-authorization-page?token=${token}&sendDataForCopyAuthorizationPage=true`
          : `${Config.baseURL}/api/payments-confirmation?token=${token}`,
      },
      fetchCountries: {
        url: `${Config.baseURL}/api/billing-countries`,
      },
    };
  }),
  branch(loaderPredicate, renderComponent(Loader)),
  branch(
    errorPredicate,
    renderComponent(({ fetchPaymentConfirmation }) => (
      <FlexBox justifyContent="center" pa="b-big">
        <Error
          size="huge"
          message={PAYMENT_ERRORS[get(fetchPaymentConfirmation, 'reason.cause.error')] || DEFAULT_PAYMENT_ERR_MSG}
        />
      </FlexBox>
    )),
  ),
  withProps(({ fetchPaymentConfirmation, fetchCountries }) => {
    const paymentConfirmation = fetchPaymentConfirmation.value.data;
    const countries = fetchCountries.value.data;
    const usaStates = get(
      countries.find(({ code }) => code === 'US'),
      'billingStates',
      [],
    );
    const passengers = get(paymentConfirmation, 'passengerDetail', []);

    return {
      paymentConfirmation,
      countries,
      usaStates,
      passengers: passengers.map((passenger) => ({
        ...passenger,
        birthday: passenger.dateOfBirth ? moment.unix(passenger.dateOfBirth).utc() : null,
      })),
      manualImport: paymentIsManualyImported(paymentConfirmation.importType),
      billingCard: get(paymentConfirmation, 'billingDetail.billingCard'),
      billingDetailCustomer: get(paymentConfirmation, 'billingDetail.billingDetailCustomer'),
      kountSessionId: uuid(),
    };
  }),
  connect(null, { updatePhoneTFN, setBookingPhone }),
  lifecycle({
    componentDidMount() {
      triggerPageView();
      if (!localStorage.getItem('call_tracking_initial_params')) {
        this.props.setBookingPhone();
      }
    },
  }),
  withStateHandlers(
    ({ fetchPaymentConfirmation }) => {
      const serverResponse = fetchPaymentConfirmation.value.data;

      let isUpgradeSupportReadOnly = false;
      let upgradeSupportPackage = setupUpgradeSupportPackage(serverResponse.travelMarket);

      if (serverResponse.additionService && serverResponse.additionService.type) {
        upgradeSupportPackage = serverResponse.additionService.type;
        isUpgradeSupportReadOnly = true;
      }

      return {
        confirmPaymentPending: false,
        confirmPaymentError: false,
        upgradeSupportPackage,
        isUpgradeSupportReadOnly,
      };
    },
    {
      setConfirmPaymentPending: () => (value) => ({
        confirmPaymentPending: value,
      }),
      setConfirmPaymentError: () => (value) => ({
        confirmPaymentError: value,
      }),
      handleUpgradeSupportStateChange: () => (value) => ({
        upgradeSupportPackage: value,
      }),
    },
  ),
  withDomain(),
  withHandlers({
    handleValidationError:
      ({ getDomainName }) =>
      (errors) =>
        trigger(getDomainName(), Events.validation.payment(parseFieldsName(errors))),
    onSubmit: (props) => async (values) => {
      const {
        navigate,
        token,
        kountSessionId,
        paymentConfirmation,
        setConfirmPaymentPending,
        setConfirmPaymentError,
        billingCard,
        upgradeSupportPackage,
        fetchPaymentConfirmation,
      } = props;
      const isFirstUpgradeSupportPackage = !fetchPaymentConfirmation.value.data.additionService;
      const [paymentInfo] = values;

      const finalPaymentInfo = values.find((item) => item.address !== undefined);
      const finalAdditionalServices = values.find((item) => item.quote !== undefined);
      const finalReferralCode = values.find((item) => item.referralCode !== undefined);

      if (billingCard) {
        finalAdditionalServices = paymentInfo;
        finalPaymentInfo = null;
      }

      setConfirmPaymentError(false);
      setConfirmPaymentPending(true);

      try {
        const payment = await confirmPayment({
          token,
          kountSessionId,
          paymentConfirmation,
          paymentInfo: finalPaymentInfo,
          additionalServices: finalAdditionalServices,
          referralInfo: finalReferralCode,
          ...(isFirstUpgradeSupportPackage && { upgradeSupportPackage }),
        });

        if (payment.status === 'PAYMENT_SUCCESSFUL') {
          navigate({
            pathname: '/successful-confirmation',
            search: `?${queryString.stringify({
              token,
            })}`,
          });
        } else {
          setConfirmPaymentError(true);
        }
      } catch (e) {
        setConfirmPaymentError(true);
      } finally {
        setConfirmPaymentPending(false);
      }
    },
  }),
)(BillingConfirmation);
