import queryString from 'query-string';

import {
  serializeCalendarFilters,
  serializeSearchFlightFilters,
  serializeSearchReportsFilters,
} from '../../helpers/formCalendarParams';
import { catchServerError } from '../../Sentry';
import Config, { IBE_BASE_URL } from '../../constants/config';

export function getAirportsFrom(search) {
  return {
    airportsFromResponse: {
      url: `${Config.baseURL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
}

export function getAirportsTo(search) {
  return {
    airportsToResponse: {
      url: `${Config.baseURL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
}

export function getAirportsReturnFrom(search) {
  return {
    airportsReturnFromResponse: {
      url: `${Config.baseURL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
}

export function getAirportsReturnTo(search) {
  return {
    airportsReturnToResponse: {
      url: `${Config.baseURL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
}

export function getCalendar(filters, domain) {
  const body = JSON.stringify({ ...serializeCalendarFilters(filters), domain });
  return {
    calendarResponse: {
      url: `${IBE_BASE_URL}/flights/ibe-calendar`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: { body },
        });
      },
    },
  };
}

export function searchFlights(filters, domain, transactionId, { invalidateCache } = {}) {
  return {
    searchFlightsResponse: {
      url: `${IBE_BASE_URL}/ibe-search?${queryString.stringify(
        {
          ...serializeSearchFlightFilters(filters),
          domain,
          transactionSearchId: transactionId,
          invalidateCache: invalidateCache || false,
          dateSearch: 'ALTERNATE',
          nearbySearch: 'ALTERNATE',
          optionsSearch: 'BROAD',
        },
        {
          arrayFormat: 'bracket',
        },
      )}`,
      catch: catchServerError,
      force: true,
    },
  };
}

export async function loadMoreSearchFlights(filters, domain) {
  const url = `${IBE_BASE_URL}/ibe-search?${queryString.stringify(
    {
      ...serializeSearchFlightFilters(filters),
      domain,
      dateSearch: 'ALTERNATE',
      nearbySearch: 'ALTERNATE',
      optionsSearch: 'BROAD',
    },
    {
      arrayFormat: 'bracket',
    },
  )}`;
  const response = await fetch(new Request(url));
  catchServerError(null, {
    response,
    request: { url },
  });
  return response.json();
}

export const writeSearchToReports = (filters, domain) => {
  const body = JSON.stringify(
    serializeSearchReportsFilters({
      ...filters,
      domain,
    }),
  );

  const { userEmail } = filters;
  sessionStorage.setItem(
    'O&D-report-hit',
    JSON.stringify({
      hit: true,
      ...(userEmail && { userEmail }),
    }),
  );

  return {
    ElasticsearchResponse: {
      url: `${Config.baseURL}/api/origin-destination/reports`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: { body },
        });
      },
    },
  };
};

export function saveFlightKey(flight, filters) {
  const body = JSON.stringify({
    flight,
    searchParams: serializeSearchFlightFilters(filters),
  });
  return {
    saveFlightKeyResponse: {
      url: `${IBE_BASE_URL}/flight`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: {
            body,
          },
        });
      },
    },
  };
}

export async function verifyBooking(flight) {
  let response = null;
  const url = `${IBE_BASE_URL}/flight/check-itinerary`;
  const body = JSON.stringify({ flight });
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body,
    });

    catchServerError(null, {
      response,
      request: { url },
    });

    const { code, summary } = await response.json();

    const verifyBookingError = new Error(code);
    if (code === 'PRICE_HAS_CHANGED') {
      verifyBookingError.changedPrice = summary.total.total;
    }

    return code ? { code, summary } : response;
  } catch (error) {
    catchServerError(error, {
      response,
      request: { url, body },
    });
    throw error;
  }
}

export async function getInitialAirports(from, to, returnFrom, returnTo) {
  const fromUrl = getAirportsFrom(from).airportsFromResponse.url;
  const toUrl = getAirportsTo(to).airportsToResponse.url;
  const returnFromUrl = getAirportsReturnFrom(returnFrom).airportsReturnFromResponse.url;
  const returnToUrl = getAirportsReturnTo(returnTo).airportsReturnToResponse.url;

  try {
    const [fromResult = [], toResult = [], returnFromResult = [], returnToResult = []] = await Promise.all([
      fetch(fromUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: fromUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
      fetch(toUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: toUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
      fetch(returnFromUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: returnFromUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
      fetch(returnToUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: returnToUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
    ]);

    const first = fromResult.find((i) => i.code === from);
    const second = toResult.find((i) => i.code === to);
    const third = returnFromResult.find((i) => i.code === returnFrom);
    const forth = returnToResult.find((i) => i.code === returnTo);
    return [
      {
        ...(first && { ...first }),
      },
      {
        ...(second && { ...second }),
      },
      {
        ...(third && { ...third }),
      },
      {
        ...(forth && { ...forth }),
      },
    ];
  } catch (e) {}
}

export async function refreshSearch(filters) {
  const { calendarResponse } = getCalendar(filters);
  const { searchFlightsResponse } = searchFlights(filters);

  try {
    const [calendarResponseData, flightsResponseData] = await Promise.all([
      fetch(calendarResponse.url, {
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        method: calendarResponse.method,
        body: calendarResponse.body,
      }),
      fetch(searchFlightsResponse.url),
    ]);

    if (!(calendarResponseData.ok && flightsResponseData.ok)) {
      return {};
    }

    const calendar = await calendarResponseData.json();
    const flights = await flightsResponseData.json();

    return {
      calendar,
      flights,
    };
  } catch (e) {
    return {};
  }
}
