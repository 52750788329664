import React from 'react';

import { DEFAULT_PAYMENT_ERR_MSG, PAYMENT_ERRORS } from '../../constants/payment';
import FlexBox from '../FlexBox/FlexBox';

const ErrorMessage = error => (
  <FlexBox justifyContent="center" className="payment-error">
    {PAYMENT_ERRORS[error.error] || DEFAULT_PAYMENT_ERR_MSG}
  </FlexBox>
);
export default ErrorMessage;
