import './Suggestions.css';

import React from 'react';
import { compose } from 'recompose';

import CarsImage from '../../assets/images/cars.png';
import HotelsImage from '../../assets/images/hotels.png';
import IdeasImage from '../../assets/images/ideas.png';
import { MARKETING_URL_CARS } from '../../constants/global';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import SuggestionBanner from './SuggestionBanner';

type Props = {
  destinationCity?: string,
};

const Suggestions = ({ destinationCity, buildMarketingUrl }: Props) => (
  <ul className="suggestion-banners">
    <li>
      <SuggestionBanner content="Our popular vacation ideas" btnContent="Read the article" src={IdeasImage} />
    </li>
    <li>
      <SuggestionBanner
        content={`Need a hotel in ${destinationCity}?`}
        btnContent="See our deals"
        src={HotelsImage}
        link="https://www.booking.com/?aid=870169"
      />
    </li>
    <li>
      <SuggestionBanner
        content={`Car rental in ${destinationCity} starting from $34`}
        btnContent="Book now"
        src={CarsImage}
        link={buildMarketingUrl(MARKETING_URL_CARS)}
      />
    </li>
  </ul>
);

export default compose(withCallTrackingUrl())(Suggestions);
