const getSessionStorageItem = (key) => {
  const data = sessionStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

const setSessionStorageItem = (key, value) => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

const removeSessionStorageItem = (key) => {
  sessionStorage.removeItem(key);
};

export { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem };
