// @flow
import queryString from 'query-string';

import config from '../../constants/config';
import { catchServerError } from '../../Sentry';
import API from '../base/api';
import type { ReqTypeT } from '../index';

class UserApi extends API {
  async getPhoneNumber(tfnParams) {
    // make call-tracking API call
    const url = `${config.callTrackingUrl}/api/call-tracking/tfn?${queryString.stringify({ ...tfnParams })}`;

    return this.r({
      method: 'GET',
      url,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url },
      });
    });
  }
}

export default function userApi(request: ReqTypeT) {
  return new UserApi(request);
}
