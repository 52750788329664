import './PriceSummary.css';

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { get, isEmpty, startCase } from 'lodash';
import { Text, Popup, Tablet, Mobile, FlexBox, InfoIcon, Container, DesktopLarge } from 'travelopod-components';

import Currency from '../../../components/Currency';
import Header from '../../../components/Typography/Header';
import { SupportUpgradeMap } from '../../../constants/global';
import SecondaryContainer from '../../../components/SecondaryContainer';
import { calculateAdditionalServices } from '../../../helpers/payments';

const cancellationText =
  'Book now and if your plans change, you can cancel for no charge by calling us within 3 hours or for fee within 12 hours of booking.';
const cancellationLabel = 'Free Cancellation';

class PriceSummary extends Component {
  static defaultProps = {
    className: '',
    data: {},
    isOnline: false,
    enquiry: {},
    flight: {},
    isCancellationShown: false,
    quote: {},
    upgradeSupportPackage: null,
  };

  totalFareCalc = () => {
    const { data } = this.props;
    const amount = get(data, 'billingDetail.amount');
    let result = amount;
    if (data && data.bookingInsurance && data.billingDetail.id === data.bookingInsurance.billingDetailId) {
      result -= Number(data.bookingInsurance.planCost);
    }
    if (data && data.additionService && data.billingDetail.id === data.additionService.billingDetailId) {
      result -= Number(data.additionService.price);
    }

    return result.toFixed(2);
  };

  getTotalChargeable() {
    const { enquiry, data, quote } = this.props;

    const amount = get(data, 'billingDetail.amount');
    let result = amount;
    let currency = enquiry.currency;

    // region Calculating from back-end response
    if (!data.additionService) {
      result += this.getUpgradeSupportPackage();
    }
    // endregion

    // region Calculating from client-side data (user's manual inputs)
    if (!isEmpty(quote)) {
      result += quote.insurancePlanCost;
      currency = 'USD';
    }
    // endregion

    return {
      amount: result.toFixed(2),
      currency,
    };
  }

  isInsuranceIncludedInCurrentPayment() {
    const {
      data: { bookingInsurance, billingDetail },
    } = this.props;

    return bookingInsurance && billingDetail.id && get(bookingInsurance, 'billingDetailId') === billingDetail.id;
  }

  shouldChargeTravelInsurance() {
    const { quote } = this.props;

    return this.isInsuranceIncludedInCurrentPayment() || !isEmpty(quote);
  }

  getTravelInsuranceAmount() {
    const {
      quote,
      data: { bookingInsurance },
    } = this.props;

    return (quote ? quote.insurancePlanCost : bookingInsurance.planCost).toFixed(2);
  }

  getUpgradeSupportPackage = () => {
    if (this.canShowAdditionalPrice()) {
      const { upgradeSupportPackage, flight, data } = this.props;
      const trips = flight.trips ? flight.trips : data.pnrTrips;
      const packName =
        (data && (data.upgradeSupportPackage || (data.additionService && data.additionService.type))) ||
        upgradeSupportPackage;
      const passengers = {
        ADULT: flight.summary && flight.summary.adults ? flight.summary.adults.count : 0,
        CHILD: flight.summary && flight.summary.children ? flight.summary.children.count : 0,
        INFANT: 0,
      };
      data && data.passengerDetail && data.passengerDetail.forEach(({ type }) => (passengers[type] += 1));

      return calculateAdditionalServices({ upgradeSupportPackage: packName }, passengers, trips.length > 1);
    } else {
      return 0;
    }
  };

  renderPackName = () => {
    const { upgradeSupportPackage, data } = this.props;
    const packName =
      (data && (data.upgradeSupportPackage || (data.additionService && data.additionService.type))) ||
      upgradeSupportPackage;
    const pack = SupportUpgradeMap[packName];
    return pack ? `${startCase(pack.value.split('_')[0].toLowerCase())} ${pack.title} Package` : '';
  };

  canShowAdditionalPrice = () => {
    const { data, upgradeSupportPackage } = this.props;
    if (data?.additionService) {
      return data.billingDetail.id === data.additionService.billingDetailId;
    } else {
      return !!upgradeSupportPackage;
    }
  };

  render() {
    const { data, className, isCancellationShown } = this.props;

    if (!data.billingDetail) {
      return null;
    }

    const totalChargeable = this.getTotalChargeable();
    // const upgradeSupportPackageCost = this.getUpgradeSupportPackage();
    const packName = this.renderPackName();

    return (
      <Container className={className}>
        {/* HEADING - PRICE SUMMARY */}
        <div className="price-summary-block">
          <Header as="h2">Price Summary</Header>
        </div>

        {/* TOTAL FARE */}
        {(this.shouldChargeTravelInsurance() || (packName && this.canShowAdditionalPrice())) && (
          <SecondaryContainer className="dim middle justify price-summary-block-label">
            <Header as="h4">Total Fare</Header>
            <Header className="e2e-total-fare" as="h4">
              ${this.totalFareCalc()}
            </Header>
          </SecondaryContainer>
        )}

        {/* TRAVEL INSURANCE */}
        {this.shouldChargeTravelInsurance() && (
          <SecondaryContainer className="dim middle justify price-summary-block-label price-summary-insurance-price">
            <Header as="h4">Travel Insurance</Header>
            <Header className="e2e-insurance-fare" as="h4">
              ${this.getTravelInsuranceAmount()}
            </Header>
          </SecondaryContainer>
        )}

        {/* SUPPORT PACKAGE */}
        {/* {packName && this.canShowAdditionalPrice() ? (
          <SecondaryContainer className="dim middle justify price-summary-block-label price-summary-insurance-price">
            <Header as="h4">{packName}</Header>
            <Header className="e2e-insurance-fare" as="h4">
              ${upgradeSupportPackageCost.toFixed(2)}
            </Header>
          </SecondaryContainer>
        ) : null} */}

        {/* TOTAL CHARGEABLE */}
        <SecondaryContainer className="dim middle justify price-summary-block-label">
          <Header className="e2e-overall-total" as="h4" weight="bold">
            Total Chargeable
          </Header>
          <Header as="h4" weight="bold">
            <Currency as={'span'} currency={totalChargeable.currency} amount={totalChargeable.amount} />
          </Header>
        </SecondaryContainer>

        <SecondaryContainer className="transparent cancellation-line">
          {isCancellationShown && (
            <>
              <DesktopLarge>
                <Popup
                  position="top center"
                  contentStyle={{
                    borderRadius: '5px',
                    backgroundColor: '#000',
                    border: 'none',
                    padding: '10px 9px 10px 16px',
                    color: '#fff',
                    fontSize: '14px',
                    lineHeight: '17px',
                    width: '97%',
                    boxSizing: 'border-box',
                    maxWidth: '300px',
                  }}
                  arrowStyle={{
                    backgroundColor: '#000',
                  }}
                  trigger={
                    <FlexBox justifyContent="flex-start" alignItems="center" className="cancellation-block" ma="b t">
                      <InfoIcon color="success" />
                      <Text as="span" color="success">
                        &nbsp;{cancellationLabel}
                      </Text>
                    </FlexBox>
                  }
                >
                  <span className="cancellation-text-block">{cancellationText}</span>
                </Popup>
              </DesktopLarge>
              <Tablet>
                <FlexBox justifyContent="flex-start" alignItems="center" className="flight-card-cancellation-block">
                  <Text as="span">
                    {cancellationLabel}: {cancellationText}
                  </Text>
                </FlexBox>
              </Tablet>
              <Mobile>
                <FlexBox justifyContent="flex-start" alignItems="center" className="flight-card-cancellation-block">
                  <Text as="span">
                    {cancellationLabel}: {cancellationText}
                  </Text>
                </FlexBox>
              </Mobile>
            </>
          )}
          <Text size="small" primary>
            All prices are in US Dollars (USD) and include all taxes and fees. Some airlines may charge baggage & seat
            assignment.
          </Text>
          <Text size="small" secondary>
            Your credit card may be billed in multiple charges totaling above amount.
          </Text>
          {this.shouldChargeTravelInsurance() && (
            <Text size="small" secondary>
              Travel Insurance cost of ${this.getTravelInsuranceAmount()} will be charged separately to the customer's
              credit card.
            </Text>
          )}
        </SecondaryContainer>
      </Container>
    );
  }
}

const mapStateToProps = ({ payment, quote }, ownProps) => {
  return {
    quote: ownProps.quote || quote.quoteValue,
    data: ownProps.data || payment.data,
    enquiry: ownProps.enquiry || payment.data.enquiry,
  };
};

export default connect(mapStateToProps)(PriceSummary);
