import classNames from 'classnames';
import type { Element } from 'react';
import React from 'react';

type Color = 'red' | 'blue' | 'orange' | 'green' | 'green-light';

type Size = 'small' | 'big' | 'huge';

type Weight = 'semi-bold' | 'bold';

type Align = 'justify';

export type Props = {
  as?: Element<any>,
  secondary?: boolean,
  color?: Color,
  size?: Size,
  weight?: Weight,
  align?: Align,
};

const Text = ({ as: As, className, secondary, color, size, weight, align, ...props }: Props) => {
  const classes = classNames(className, 'text', {
    [size]: size,
    [color]: color,
    [weight]: weight,
    [align]: align,
    secondary,
  });

  return <As className={classes} {...props} />;
};

Text.defaultProps = {
  as: 'p',
};

export default Text;
