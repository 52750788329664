import './Footer.css';

import React from 'react';
import { compose } from 'recompose';
import {
  Container,
  Desktop,
  Footer as BaseFooter,
  Header,
  Mobile,
  TrustPilotFooterMobile,
} from 'travelopod-components';

import {
  MARKETING_PRIVACY_POLICY,
  MARKETING_TERMS_AND_CONDITIONS,
  MARKETING_URL_ABOUT_US,
  MARKETING_URL_BOOKINGRULES,
} from '../../constants/global';
import { MARKETING_BASE_URL } from '../../constants/config';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import TrustpilotCarousel from '../Trustpilot/TrustpilotCarousel';

const LINKS = {
  home: MARKETING_BASE_URL,
  aboutUs: MARKETING_URL_ABOUT_US,
  termsConditions: MARKETING_TERMS_AND_CONDITIONS,
  privacyPolicy: MARKETING_PRIVACY_POLICY,
  bookingRules: MARKETING_URL_BOOKINGRULES,
};

const Footer = ({ buildMarketingUrl }) => (
  <React.Fragment>
    <Desktop>
      <Container noShadow fluid className="trustpilot-footer">
        <Header className="trustpilot-footer-title" as="h1" align="center" transform="capitalize">
          We care about our customers
        </Header>
        <TrustpilotCarousel />
      </Container>
    </Desktop>

    <Mobile>
      <TrustPilotFooterMobile />
    </Mobile>

    <BaseFooter
      links={{
        home: buildMarketingUrl(LINKS['home']),
        aboutUs: buildMarketingUrl(LINKS['aboutUs']),
        termsConditions: buildMarketingUrl(LINKS['termsConditions']),
        privacyPolicy: buildMarketingUrl(LINKS['privacyPolicy']),
        bookingRules: buildMarketingUrl(LINKS['bookingRules']),
      }}
    />
  </React.Fragment>
);
export default compose(withCallTrackingUrl())(Footer);
