import './PassengerInfo.css';

import React from 'react';
import PassengerList from './PassengerList';
import i18n from '../../../locales/i18next';
import Indent from '../../../components/Indent';
import { Container } from 'travelopod-components';
import Text from '../../../components/Typography/Text';
import InfoBlueIcon from '../../../assets/icons/info_blue.svg';
import AttentionIcon from '../../../assets/icons/attention.svg';
import PassengersIcon from '../../../assets/icons/passengers.svg';
import ContainerHeader from '../../../components/ContainerHeader';
import SecondaryContainer from '../../../components/SecondaryContainer';

const PassengerInfo = ({ passengers }) => (
  <Container>
    <ContainerHeader title="Passenger Info" icon={PassengersIcon} />
    <SecondaryContainer align="center middle">
      <img alt="blue icon" src={InfoBlueIcon} className="icon" />
      <Text>
        Passenger names must match passport for international travel or government issued photo ID for US domestic
        travel.
      </Text>
    </SecondaryContainer>
    <PassengerList passengers={passengers} />
    <SecondaryContainer className="dim">
      <img alt="attention" src={AttentionIcon} className="icon" />
      <Text as="span" color="orange" size="big" weight="bold">
        Found a mistake?
      </Text>
      <Indent margin="marginT" padding="none">
        <Text>
          Call us immediately at&nbsp;
          <strong>{i18n.t('components.header.phone')}</strong> for the corrections. Airline may deny boarding with
          incorrect passenger details.
        </Text>
      </Indent>
    </SecondaryContainer>
  </Container>
);

export default PassengerInfo;
