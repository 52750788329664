import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import { connect as reduxConnect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  mapProps,
  renderComponent,
  withHandlers,
  withProps,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';

import uuid from '../../helpers/uuid';
import {
  getAirportsTo,
  getCalendar,
  getInitialAirports,
  refreshSearch,
  saveFlightKey,
  searchFlights,
  writeSearchToReports,
} from '../../api/modules/searchFlights';
import {
  get10MinuteModalStatus,
  get25SecModalStatus,
  get25SecModalTriggeredStatus,
  get25SecShowedStatus,
  getCallUsData,
  getExitIntentModalStatus,
  getNoSeatsModalStatus,
  getPriceChangedModalStatus,
  modal25SecDelay,
  REFRESH_TIMEOUT,
  saveCallUsData,
  setPopupConfigData,
  toggle10MinuteModal,
  toggle25SecModal,
  toggleIsBannerShown,
  trigger25SecModalCountdown,
} from '../../store/modules/modalGeneral';
import Config from '../../constants/config';
import withModal from '../../Hocs/withModal';
import FlightsPage from './FlightsPage.view';
import withDomain from '../../Hocs/withDomain';
import withRouter from '../../Hocs/wihRouter';
// import pubnub from '../../api/pubnub/index';
import withRefetch from '../../Hocs/withRefetch';
import withFilters from '../../Hocs/withFilters';
import parseFlightsPath from '../../helpers/path';
import { bookFlightUrl } from '../../constants/routes';
import Redirect from '../../components/Router/Redirect';
import { PAGINATION_ITEMS_PER_PAGE } from '../../constants/global';
import { updatePhoneTFN } from '../../store/modules/responseTap/phoneData';
import withCallTrackingUrl, { fromValueToUrl } from '../../Hocs/withCallTrackingUrl';
import withMarketingMessages from '../../components/MarketingMessages/withMarketingMessages';
import { cheapestInitLoad, cheapestLoadRecommendations } from '../../api/modules/priceCalendar';

const DEBOUNCE_DELAY = 500;
const transactionId = uuid();

const shouldRefetchPage = (filters, oldFilters) => {
  if (!filters.to || !filters.from || !filters.startDate) {
    return null;
  }

  if (filters.tripType === 'ROUND_TRIP' && !filters.endDate) {
    return null;
  }

  if (filters.tripType === 'MULTI_CITY' && !filters.returnFrom && !filters.returnTo) {
    return null;
  }

  return (
    filters.to !== oldFilters.to ||
    filters.from !== oldFilters.from ||
    filters.returnTo !== oldFilters.returnTo ||
    filters.returnFrom !== oldFilters.returnFrom ||
    filters.endDate !== oldFilters.endDate ||
    filters.startDate !== oldFilters.startDate ||
    filters.adults !== oldFilters.adults ||
    filters.children !== oldFilters.children ||
    filters.infants !== oldFilters.infants
  );
};

export default compose(
  withRouter,
  withFilters(),
  withModal,
  withDomain(),
  withCallTrackingUrl(),
  reduxConnect(
    (state) => ({
      modal10MinuteStatus: get10MinuteModalStatus(state),
      modal25SecTriggeredStatus: get25SecModalTriggeredStatus(state),
      priceChangedModalStatus: getPriceChangedModalStatus(state),
      noSeatsModalStatus: getNoSeatsModalStatus(state),
      modal25SecStatus: get25SecModalStatus(state),
      exitIntentStatus: getExitIntentModalStatus(state),
      callUs25SecShowedStatus: get25SecShowedStatus(state),
      callUsData: getCallUsData(state),
      userData: state.authUser.user,
    }),
    {
      updatePhoneTFN,
      saveCallUsData,
      toggle10MinuteModal,
      toggle25SecModal,
      trigger25SecModalCountdown,
      toggleIsBannerShown,
      setPopupConfigData,
    },
  ),
  withRefetch(() => ({
    getCalendar,
    searchFlights,
    writeSearchToReports,
    saveFlightKey,
    getAirportsTo,
    cheapestLoadRecommendations,
    cheapestInitLoad,
  })),
  branch(
    (props) => props.location.pathname && parseFlightsPath(props.location.pathname, false),
    renderComponent((props) => {
      const parsedPath = props.parseValuesToValues(parseFlightsPath(props.location.pathname, false));
      const searchUrl = queryString.stringify(parsedPath, { arrayFormat: 'bracket' });

      return <Redirect push to={`/search?${searchUrl}`} />;
    }),
  ),
  branch(
    (props) => props.saveFlightKeyResponse && props.saveFlightKeyResponse.fulfilled,
    renderComponent(({ saveFlightKeyResponse, queryFilters, parseValuesToValues, ElasticsearchResponse, navigate }) => {
      const marketingParams = fromValueToUrl(parseValuesToValues());
      const searchHash = ElasticsearchResponse?.value?.data?.hash;

      const bookingUrl = decodeURIComponent(
        bookFlightUrl({
          flightKey: saveFlightKeyResponse.value.flightKey,
          searchHash: searchHash || 'noSet',
        }),
      );

      return navigate(`${bookingUrl}?${marketingParams}`, {
        state: { queryFilters },
      });
    }),
  ),
  withProps(({ upsertFilters, queryFilters }) => ({
    resetFilters: () => {
      upsertFilters({
        adults: queryFilters.adults,
        children: queryFilters.children,
        infants: queryFilters.infants,
        serviceClass: queryFilters.serviceClass,
        to: queryFilters.to,
        from: queryFilters.from,
        returnTo: queryFilters.returnTo,
        returnFrom: queryFilters.returnFrom,
        tripType: queryFilters.tripType,
        startDate: queryFilters.startDate,
        endDate: queryFilters.endDate,
        limit: PAGINATION_ITEMS_PER_PAGE,
        offset: 0,
      });
    },
    removeFilters: (params = {}) => {
      const {
        adults,
        children,
        infants,
        serviceClass,
        to,
        from,
        tripType,
        returnTo,
        returnFrom,
        startDate,
        endDate,
        limit,
        offset,
      } = {
        ...queryFilters,
        ...params,
      };
      upsertFilters({
        adults,
        children,
        infants,
        serviceClass,
        to,
        from,
        returnTo,
        returnFrom,
        tripType,
        startDate,
        endDate,
        limit,
        offset,
      });
    },
    transactionId,
  })),
  withPropsOnChange(['searchFlights'], ({ searchFlights }) => ({
    debouncedSearchFlights: debounce(searchFlights, DEBOUNCE_DELAY),
  })),
  withStateHandlers(
    {
      startDate: null,
      endDate: null,
      isFirstFetch: true,
      showRefreshResultsModal: false,
      refreshSearchData: null,
      calendar: null,
      flights: null,
      progress: null,
      pricesData: [],
      datePickerStart: null,
      datePickerEnd: null,
      from: '',
      to: '',
      returnTo: '',
      returnFrom: '',
      pricesPending: false,
    },
    {
      setPricesPendingActive: () => () => ({
        pricesPending: true,
      }),
      setFrom: () => (IATA) => ({
        from: IATA,
      }),
      setTo: () => (IATA) => ({
        to: IATA,
      }),
      setReturnFrom: () => (IATA) => ({
        returnFrom: IATA,
      }),
      setReturnTo: () => (IATA) => ({
        returnTo: IATA,
      }),
      updatePriceCalendarData:
        () =>
        ({ data }) => ({
          pricesData: data.map(({ amount, date }) => ({ amount, date })),
          pricesPending: false,
        }),
      onDatePickerChange:
        () =>
        ({ startDate, endDate }) => ({
          datePickerStart: startDate.unix(),
          datePickerEnd: endDate ? endDate.unix() : null,
        }),
      updateProgress:
        ({ progress }) =>
        (value) => ({
          progress: value !== null ? Math.min(Math.max(progress, value), 100) : null,
        }),
      onDatesChanges:
        (_, { queryFilters, removeFilters }) =>
        ({ startDate, endDate }) => {
          const newStartDate = startDate;
          const newEndDate = queryFilters.tripType === 'ONE_WAY' ? startDate : endDate;
          removeFilters({
            startDate: newStartDate,
            endDate: newEndDate,
          });
          return {
            startDate: newStartDate,
            endDate: newEndDate,
          };
        },
      clearCalendar: () => () => ({
        startDate: null,
        endDate: null,
        isFirstFetch: true,
      }),
      setFirstFetch: () => (isFirstFetch) => ({ isFirstFetch }),
      storeRefreshSearchData:
        () =>
        ({ calendar, flights }) => ({
          refreshSearchData: { calendar, flights },
        }),
      clearRefreshSearchData: () => () => ({
        calendar: null,
        flights: null,
      }),
      displayRefreshResultsModal: () => () => ({
        showRefreshResultsModal: true,
      }),
      closeRefreshResultsModal:
        ({ refreshSearchData }) =>
        () => {
          const data = {};

          if (refreshSearchData) {
            data.calendar = refreshSearchData.calendar;
            data.flights = refreshSearchData.flights;
          }

          window.scrollTo(0, 0);

          return {
            showRefreshResultsModal: false,
            refreshSearchData: null,
            ...data,
          };
        },
    },
  ),
  withHandlers({
    onDatesChange:
      ({
        queryFilters,
        onDatePickerChange,
        cheapestLoadRecommendations,
        domain,
        cheapestInitLoad,
        datePickerStart,
        datePickerEnd,
        updatePriceCalendarData,
        from,
        to,
        setPricesPendingActive,
      }) =>
      (dates) => {
        const newStart = dates.startDate.unix();
        const oldStart = datePickerStart ? datePickerStart : Number(queryFilters.startDate);
        const newEnd = dates.endDate && dates.endDate.unix();
        const oldEnd = datePickerEnd ? datePickerEnd : Number(queryFilters.endDate);
        const origin = from;
        const destination = to;

        if (!origin || !destination) {
          updatePriceCalendarData({ data: [] });
        } else if (newEnd && newEnd !== oldEnd) {
          if (Object.values(queryFilters).length) {
            const { serviceClass, optionsSearch, tripType, adults, children, infants } = queryFilters;
            cheapestInitLoad(
              {
                serviceClass,
                optionsSearch,
                tripType,
                from: origin,
                to: destination,
                adults,
                children,
                infants,
              },
              domain,
            );
            setPricesPendingActive();
          }
        } else if (newStart && newStart !== oldStart) {
          cheapestLoadRecommendations(
            {
              ...queryFilters,
              startDate: dates.startDate.unix(),
              from: origin,
              to: destination,
            },
            domain,
          );
          setPricesPendingActive();
        }
        onDatePickerChange(dates);
      },
    handleToSelect:
      ({ queryFilters, domain, cheapestInitLoad, updatePriceCalendarData, setPricesPendingActive, setTo, from }) =>
      (IATA) => {
        if (Object.values(queryFilters).length) {
          const { serviceClass, optionsSearch, tripType, adults, children, infants } = queryFilters;
          const origin = from || queryFilters.from;
          const destination = IATA;
          setTo(destination);

          if (origin && destination) {
            cheapestInitLoad(
              {
                serviceClass,
                optionsSearch,
                tripType,
                to: destination,
                from: origin,
                adults,
                children,
                infants,
              },
              domain,
            );
            setPricesPendingActive();
          } else {
            updatePriceCalendarData({ data: [] });
          }
        }
      },
    handleFromSelect:
      ({ queryFilters, domain, cheapestInitLoad, updatePriceCalendarData, setPricesPendingActive, setFrom, to }) =>
      (IATA) => {
        if (Object.values(queryFilters).length) {
          const { serviceClass, optionsSearch, tripType, adults, children, infants } = queryFilters;

          const origin = IATA;
          const destination = to || queryFilters.to;
          setFrom(origin);

          if (origin && destination) {
            cheapestInitLoad(
              {
                serviceClass,
                optionsSearch,
                tripType,
                from: origin,
                to: destination,
                adults,
                children,
                infants,
              },
              domain,
            );
            setPricesPendingActive();
          } else {
            updatePriceCalendarData({ data: [] });
          }
        }
      },

    handleReturnToSelect:
      ({
        queryFilters,
        domain,
        cheapestInitLoad,
        updatePriceCalendarData,
        setPricesPendingActive,
        setReturnTo,
        returnFrom,
      }) =>
      (IATA) => {
        if (Object.values(queryFilters).length) {
          const { serviceClass, optionsSearch, tripType, adults, children, infants } = queryFilters;
          const origin = returnFrom || queryFilters.returnFrom;
          const destination = IATA;
          setReturnTo(destination);

          if (origin && destination) {
            cheapestInitLoad(
              {
                serviceClass,
                optionsSearch,
                tripType,
                returnTo: destination,
                returnFrom: origin,
                adults,
                children,
                infants,
              },
              domain,
            );
            setPricesPendingActive();
          } else {
            updatePriceCalendarData({ data: [] });
          }
        }
      },
    handleReturnFromSelect:
      ({
        queryFilters,
        domain,
        cheapestInitLoad,
        updatePriceCalendarData,
        setPricesPendingActive,
        setReturnFrom,
        returnTo,
      }) =>
      (IATA) => {
        if (Object.values(queryFilters).length) {
          const { serviceClass, optionsSearch, tripType, adults, children, infants } = queryFilters;

          const origin = IATA;
          const destination = returnTo || queryFilters.returnTo;
          setReturnFrom(origin);

          if (origin && destination) {
            cheapestInitLoad(
              {
                serviceClass,
                optionsSearch,
                tripType,
                returnFrom: origin,
                returnTo: destination,
                adults,
                children,
                infants,
              },
              domain,
            );
            setPricesPendingActive();
          } else {
            updatePriceCalendarData({ data: [] });
          }
        }
      },
  }),
  withMarketingMessages(),
  withRefetch(({ setPopupConfigData }) => {
    return {
      bannerStatusFetch: `${Config.callTrackingUrl}/api/call-tracking/banner`,
      writeSearchToReports,
      fetchIBEBannerConfig: () => ({
        ibeBannerConfigResponse: {
          url: `${Config.baseURL}/api/banners/ibepage`,
          method: 'GET',
          then: ({ data }) => {
            setPopupConfigData(data);
          },
        },
      }),
    };
  }),
  lifecycle({
    async componentDidMount() {
      const {
        queryFilters,
        getAirportsTo,
        loadMessages,
        location,
        navigate,
        updateProgress,
        getCalendar,
        searchFlights,
        updatePhoneTFN,
        writeSearchToReports,
        transactionId,
        cheapestInitLoad,
        updatePriceCalendarData,
        setPricesPendingActive,
        setTo,
        setFrom,
        setReturnTo,
        setReturnFrom,
        getDomainName,
        domain,
        fetchIBEBannerConfig,
        userData,
      } = this.props;

      // get banner configurations for IBE page
      fetchIBEBannerConfig();

      // check if O&D report is called on page load - to prevent duplicate calls
      const originDestinationReportHitData = JSON.parse(sessionStorage.getItem('O&D-report-hit')) || {};

      if (!originDestinationReportHitData?.hit && queryFilters.to && queryFilters.from) {
        writeSearchToReports(
          { ...queryFilters, ...(userData?.email && { userEmail: userData.email }) },
          getDomainName(true),
        );
      }

      //handler for popstate
      const handlePopState = () => {
        if (sessionStorage.getItem('O&D-report-hit')) {
          sessionStorage.removeItem('O&D-report-hit');
        }
        window.removeEventListener('popstate', handlePopState);
      };

      //destroying key when back button is clicked
      window.addEventListener('popstate', handlePopState);

      if (Object.values(queryFilters).length) {
        const { serviceClass, optionsSearch, tripType, from, to, returnFrom, returnTo, adults, children, infants } =
          queryFilters;

        if (returnTo && returnFrom && to && from) {
          cheapestInitLoad(
            {
              serviceClass,
              optionsSearch,
              tripType,
              from,
              to,
              adults,
              children,
              infants,
            },
            domain,
          );
          setTo(to);
          setFrom(from);
          setReturnTo(returnTo);
          setReturnFrom(returnFrom);
          setPricesPendingActive();
        } else if (from && to) {
          cheapestInitLoad(
            {
              serviceClass,
              optionsSearch,
              tripType,
              from,
              to,
              adults,
              children,
              infants,
            },
            domain,
          );
          setTo(to);
          setFrom(from);
          setPricesPendingActive();
        } else {
          updatePriceCalendarData({ data: [] });
        }
      }

      const shouldRefetch = shouldRefetchPage(queryFilters, {});
      const invalidateCache = get(location, 'state.invalidateCache');
      const searchProgressChannel = `ch:${transactionId}:progress`;

      // pubnub.subscribe({
      //   channels: [searchProgressChannel],
      // });
      // pubnub.getMessage(searchProgressChannel, ({ message: { payload } }) => {
      //   updateProgress(payload.percentage === 0 ? 5 : payload.percentage);
      //   if (payload.percentage >= 100) {
      //     setTimeout(updateProgress(null), 500);
      //   }
      // });

      getAirportsTo(queryFilters.to);

      if (shouldRefetch) {
        getCalendar(queryFilters, getDomainName());
        searchFlights(queryFilters, getDomainName(), transactionId, {
          invalidateCache: invalidateCache ? true : false,
        });

        loadMessages(queryFilters);
      }

      const result = await getInitialAirports(queryFilters.from, queryFilters.to);

      if (result && result[1] && Object.keys(result[1]) && Object.keys(result[1]).length) {
        updatePhoneTFN({ destinationIATA: result[1].code });
      }

      invalidateCache &&
        navigate({
          pathname: location.pathname,
          search: location.search,
          state: {},
        });
    },
    componentWillUnmount() {
      this.props.modal25SecTriggeredStatus && this.props.trigger25SecModalCountdown();
      // pubnub.unsubscribe({
      //   channels: [`ch:${this.props.transactionId}:progress`],
      // });
    },
    componentDidUpdate(prevProps) {
      const {
        queryFilters,
        startDate,
        endDate,
        searchFlightsResponse,
        calendarResponse,
        getAirportsTo,
        storeRefreshSearchData,
        calendar,
        flights,
        clearRefreshSearchData,
        loadMessages,
        loadMessagesDebounced,
        callUsData,
        saveCallUsData,
        airportsToResponse,
        location,
        removeFilters,
        clearCalendar,
        getCalendar,
        searchFlights,
        setFirstFetch,
        debouncedSearchFlights,
        modal10MinuteStatus,
        priceChangedModalStatus,
        noSeatsModalStatus,
        exitIntentStatus,
        modal25SecTriggeredStatus,
        toggle10MinuteModal,
        toggle25SecModal,
        trigger25SecModalCountdown,
        callUs25SecShowedStatus,
        cheapestResponse,
        updatePriceCalendarData,
        getDomainName,
        toggleIsBannerShown,
        bannerStatusFetch,
        userData,
        writeSearchToReports,
      } = this.props;

      // check if O&D report needs to be re-written with user-login email
      // this duplicates current search to O&D by updating with user-login email
      const originDestinationReportHitData = JSON.parse(sessionStorage.getItem('O&D-report-hit')) || {};

      if (
        originDestinationReportHitData.hit &&
        userData?.email &&
        !prevProps.userData?.email &&
        // TODO: check if below condition is necessary (maintain prevProps check)
        !originDestinationReportHitData?.userEmail
      ) {
        writeSearchToReports(
          { ...queryFilters, ...(userData?.email && { userEmail: userData.email }) },
          getDomainName(true),
        );
      }

      const invalidateCache = get(location, 'state.invalidateCache');
      getAirportsTo(queryFilters.to);

      if (!bannerStatusFetch.pending && prevProps.bannerStatusFetch.pending !== bannerStatusFetch.pending) {
        toggleIsBannerShown(bannerStatusFetch.value && bannerStatusFetch.value.data.show);
      }

      if (
        cheapestResponse &&
        !cheapestResponse.pending &&
        cheapestResponse.pending !== prevProps.cheapestResponse.pending
      ) {
        if (cheapestResponse.value) {
          updatePriceCalendarData(cheapestResponse.value);
        } else if (cheapestResponse.reason) {
          updatePriceCalendarData({ data: [] });
        } else {
          updatePriceCalendarData({ data: [] });
        }
      }

      const shouldRefetch = !startDate && !endDate && shouldRefetchPage(queryFilters, prevProps.queryFilters);

      if (
        searchFlightsResponse &&
        searchFlightsResponse.value &&
        !isEqual(prevProps.searchFlightsResponse.value, searchFlightsResponse.value)
      ) {
        const { groups } = searchFlightsResponse.value;
        if (groups && !!groups.length) {
          loadMessages(
            queryFilters,
            (groups[0].price && groups[0].price.total) ||
              (groups[0][0] && groups[0][0].price && groups[0][0].price.total),
          );
        }
      }

      if (searchFlightsResponse && searchFlightsResponse.pending && calendar && flights) {
        clearRefreshSearchData();
      }

      if ((searchFlightsResponse && searchFlightsResponse.pending) || (calendarResponse && calendarResponse.pending)) {
        return;
      }

      if (shouldRefetch === null) {
        return;
      }

      if (
        airportsToResponse &&
        airportsToResponse.value &&
        callUsData.destination !== airportsToResponse.value.data[0].airportCity.name
      ) {
        saveCallUsData({
          airportsToResponse: airportsToResponse.value.data,
          searchFlightsResponse: searchFlightsResponse.value,
        });
      }

      let finalQueryFilters = null;
      if (shouldRefetch) {
        finalQueryFilters = queryFilters;
        removeFilters(queryFilters);

        clearCalendar();
        getCalendar(queryFilters, getDomainName());
        searchFlights(queryFilters, getDomainName(), transactionId, {
          invalidateCache: !!invalidateCache,
        });

        loadMessages(queryFilters);
      } else if (!isEqual(queryFilters, prevProps.queryFilters)) {
        finalQueryFilters = {
          ...queryFilters,
          startDate: startDate || queryFilters.startDate,
          endDate: endDate || queryFilters.endDate,
        };

        setFirstFetch(false);
        debouncedSearchFlights(finalQueryFilters, getDomainName(), transactionId, {
          invalidateCache: !!invalidateCache,
        });

        loadMessagesDebounced(finalQueryFilters);
      }

      if (!modal10MinuteStatus && searchFlightsResponse && !!searchFlightsResponse.fulfilled) {
        clearTimeout(this.refreshSearchTimeId);
        if ((priceChangedModalStatus || noSeatsModalStatus || exitIntentStatus) && modal25SecTriggeredStatus) {
          clearTimeout(this.toggle25SecTimeout);
          trigger25SecModalCountdown();
        }

        if (
          !modal25SecTriggeredStatus &&
          !priceChangedModalStatus &&
          !noSeatsModalStatus &&
          !exitIntentStatus &&
          !callUs25SecShowedStatus &&
          !prevProps.priceChangedModalStatus &&
          !prevProps.noSeatsModalStatus
        ) {
          this.toggle25SecTimeout = setTimeout(() => {
            toggle25SecModal();
          }, modal25SecDelay);
          trigger25SecModalCountdown();
        }
        this.refreshSearchTimeId = setTimeout(async () => {
          const { calendar, flights } = await refreshSearch(queryFilters);

          storeRefreshSearchData({ calendar, flights });
          if (!priceChangedModalStatus && !noSeatsModalStatus) {
            toggle10MinuteModal();
          }
        }, REFRESH_TIMEOUT);
      } else {
        clearTimeout(this.refreshSearchTimeId);
      }
    },
  }),
  mapProps(({ calendar, flights, searchFlightsResponse, calendarResponse, ...props }) => {
    if (searchFlightsResponse && calendarResponse && calendar && flights) {
      return {
        ...props,
        searchFlightsResponse: {
          ...searchFlightsResponse,
          value: flights,
        },
        calendarResponse: {
          ...calendarResponse,
          value: calendar,
        },
      };
    }

    return {
      ...props,
      searchFlightsResponse,
      calendarResponse,
    };
  }),
)(FlightsPage);
