import './Container.css';

import classNames from 'classnames';
import type { Element } from 'react';
import React from 'react';

export type Props = {
  as?: Element<any>,
  stretch?: boolean,
};

const Container = ({ as: As, className, stretch, forwardRef, ...props }: Props) => {
  const classes = classNames(className, 'component-container', {
    stretch,
  });

  return <As className={classes} {...props} />;
};

Container.defaultProps = {
  as: 'div',
};

export default Container;
