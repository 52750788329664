import './HelpBanner.css';

import React from 'react';
import { CallUsIcon, FlexBox, HelpBanner as BaseHelpBanner, Text, withTheme } from 'travelopod-components';

import i18n from '../../../locales/i18next';
import ResponseTapPhone from '../../ResponseTap/ResponseTapPhone';

const HelpBanner = ({ theme, props }) => (
  <BaseHelpBanner {...props}>
    <FlexBox alignItems="center">
      <CallUsIcon className="call-us-icon" />
      <Text
        as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberHelpBanner" />}
        title={i18n.t('components.header.phone')}
        color={theme.mode === 'flykart' ? 'link' : 'orange'}
        size="huge"
        weight="bold"
        decoration={theme.mode === 'flykart' ? 'none' : ''}
        className="e2e-call-us-help-banner"
      />
    </FlexBox>
  </BaseHelpBanner>
);

export default withTheme(HelpBanner);
