// @flow

import React from 'react';
import { AnimatedWrapper, PriceCalendarFilters } from 'travelopod-components';

type PropsT = {
  airportsToResponse: Object,
  airportsFromResponse: Object,
  airportsReturnToResponse: Object,
  airportsReturnFromResponse: Object,

  handleFromChange: (e, query: { value: string }) => void,
  handleToChange: (e, query: { value: string }) => void,
  handleReturnFromChange: (e, query: { value: string }) => void,
  handleReturnToChange: (e, query: { value: string }) => void,

  handleSearch: () => void,
};

const DELAY = 500;

const PriceFilters = (props) => {
  const {
    queryFilters,
    handleFromChange,
    handleToChange,
    handleReturnFromChange,
    handleReturnToChange,
    handleSearch,
    airportsToResponse,
    airportsFromResponse,
    airportsReturnToResponse,
    airportsReturnFromResponse,
    initialValues,
    calendarResponse,
    onInit,
    redirectToGroupPage,
    pricesData,
    onDatesChange,
    handleToSelect,
    handleFromSelect,
    handleReturnToSelect,
    handleReturnFromSelect,
    pricesPending,
  } = props;
  const to = airportsToResponse?.value ? airportsToResponse.value.data : [];
  const from = airportsFromResponse && airportsFromResponse.value ? airportsFromResponse.value.data : [];
  const returnTo = airportsReturnToResponse?.value ? airportsReturnToResponse.value.data : [];
  const returnFrom = airportsReturnFromResponse?.value ? airportsReturnFromResponse.value.data : [];

  return (
    <AnimatedWrapper delay={DELAY} addOverlay>
      <PriceCalendarFilters
        initialDirection={initialValues}
        airportsFrom={from}
        airportsTo={to}
        airportsReturnFrom={returnFrom}
        airportsReturnTo={returnTo}
        filters={queryFilters}
        onFromChange={handleFromChange}
        onToChange={handleToChange}
        onReturnFromChange={handleReturnFromChange}
        onReturnToChange={handleReturnToChange}
        onSearch={handleSearch}
        calendarResponse={calendarResponse}
        onInit={onInit}
        redirectToGroupPage={redirectToGroupPage}
        showCheapestDay
        pricesData={pricesData}
        pricesPending={pricesPending}
        onDatesChange={onDatesChange}
        handleToSelect={handleToSelect}
        handleFromSelect={handleFromSelect}
        handleReturnToSelect={handleReturnToSelect}
        handleReturnFromSelect={handleReturnFromSelect}
      />
    </AnimatedWrapper>
  );
};

export default PriceFilters;
