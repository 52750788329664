// @flow
import './FixedWidthContainer.css';

import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.Node,
  className?: string,
};

const FixedWidthContainer = ({ children, className }: Props) => (
  <div className={classNames('container-fixed-width', className)}>{children}</div>
);

export default FixedWidthContainer;
