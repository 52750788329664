import './ContainerHeader.css';

import classNames from 'classnames';
import type { Element } from 'react';
import React from 'react';

import Header from '../Typography/Header';

export type Props = {
  icon?: Element<any>,
  title: string,
  children?: Element<any>,
};

const ContainerHeader = ({ className, icon, title, children, ...props }: Props) => {
  const classes = classNames(className, 'container-header');

  return (
    <div className={classes} {...props}>
      <div className="stretch-container">
        <img alt="" src={icon} />
        <Header as="h1">{title}</Header>
      </div>
      {children}
    </div>
  );
};

export default ContainerHeader;
