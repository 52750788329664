export const SAVE_AUTH_USER = 'authUser/SAVE_AUTH_USER';

const initialState = {
  user: null,
};

const saveAuthUserReducer = (state = initialState, action) => {
  if (action.type === SAVE_AUTH_USER) {
    return { user: action.value };
  }

  return state;
};

export const saveAuthUser = (data) => {
  console.log('saveAuthUser data:', data);
  return {
    type: SAVE_AUTH_USER,
    value: data,
  };
};

export default saveAuthUserReducer;
