import './index.css';

import React, { useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { connect } from 'react-redux';
import { FlexBox } from 'travelopod-components';

import stopwatch from '../../assets/icons/stopwatch.svg';
import Container from '../Container';
import Countdownfinal from './Countdownfinal';
import Countdownwarning from './Countdownwarning';
const BookingCountdown = ({ isAside }) => {
  const [isModalShow, setIsModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);

  function showCountdownWarningPopoUp() {
    document.body.style.overflow = 'hidden';
    setIsModalShow(true);
  }

  function hideCountdownWarningPopoUp() {
    document.body.style.overflow = 'auto';
    setIsModalShow(false);
  }

  function showCountdownFinalPopup() {
    document.body.style.overflow = 'hidden';
    setOpen(true);
  }

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        //do something
        document.body.style.overflow = 'auto';
      }
    });
  }, [isModalShow, open]);

  return (
    <>
      <Container className={isAside ? 'small' : 'sticky'} ma="b">
        <FlexBox className={!isAside ? 'countdown-container-mobile' : ''}>
          <FlexBox className={`countdown-content-holder${!isAside ? '-mobile' : ''}`}>
            <span className="countdown-text-content">Book now to save the deal</span>
            <span className="countdown-container">
              <Timer
                checkpoints={[
                  {
                    time: 300000,
                    callback: () => showCountdownWarningPopoUp(),
                  },

                  {
                    time: 0,
                    callback: () => hideCountdownWarningPopoUp(),
                  },

                  {
                    time: 0,
                    callback: () => showCountdownFinalPopup(),
                  },
                ]}
                initialTime={900000}
                direction="backward"
              >
                {({ stop }) => (
                  <React.Fragment>
                    <span className="countdown-minutes">
                      <Timer.Minutes />
                    </span>
                    min
                    <span className="countdown-seconds">
                      <Timer.Seconds />
                    </span>
                    sec
                  </React.Fragment>
                )}
              </Timer>
              <span className="countdown-left">left</span>
            </span>
          </FlexBox>
          <img src={stopwatch} alt="arrow-down" />
        </FlexBox>
      </Container>
      <Countdownwarning isModalShow={isModalShow} setIsModalShow={setIsModalShow} />
      <Countdownfinal setOpen={setOpen} open={open} />
    </>
  );
};

export default connect(null)(BookingCountdown);
