import React from 'react';
import { Container, Divider, FlexBox, Header, Icon, Indent, Text } from 'travelopod-components';

import PolicyIcon from '../../../assets/icons/policies.svg';
import { getCardLabel } from '../../../helpers/formatHelper';
import CardImage from '../CardImage';

const BillingInformation = ({ cardType, last4 }) => (
  <Container>
    <FlexBox pa="v-double h-big" alignItems="center">
      <Icon src={PolicyIcon} alt="policy icon" ma="r" />
      <Header as="h1">Billing Information</Header>
    </FlexBox>
    <Divider />
    <Indent pa="double">
      <FlexBox alignItems="center">
        <Text as="span" size="big">
          Credit card:&nbsp;
        </Text>
        <CardImage imageName={cardType.toLowerCase()} />
        <Text as="span" size="big" weight="bold">
          &nbsp;
          {getCardLabel(cardType)} - {last4}
        </Text>
      </FlexBox>
    </Indent>
  </Container>
);

export default BillingInformation;
