// @flow

import PriceCalendarT from '../../../api/modules/priceCalendar';
import { GET_LIST_OF_AIRPORTS_SUCCESS } from './searchAirports';
import type { SearchPriceCalendarSuccess } from './searchCalendar';
import { SEARCH_PRICE_CALENDAR_SUCCESS } from './searchCalendar';

type ActionTypeT = SearchPriceCalendarSuccess;

const initialState: Object = {
  calendar: PriceCalendarT,
};

const AIRPORT_CODE_LENGTH = 3;

const filterAirports = (airports: Array, code: string) => {
  if (code.length === AIRPORT_CODE_LENGTH && airports.length > 1) {
    const codeUpperCase = code.toUpperCase();
    const airportByCode = airports.find(airport => airport.code === codeUpperCase);

    if (airportByCode) {
      return [airportByCode];
    }
  }

  return airports;
};

export default function priceCalendarDataReducer(
  state: PriceCalendarT = initialState,
  action: ActionTypeT,
): PriceCalendarT {
  switch (action.type) {
    case SEARCH_PRICE_CALENDAR_SUCCESS:
      return {
        calendar: action.result,
      };

    case GET_LIST_OF_AIRPORTS_SUCCESS:
      return {
        airports: filterAirports(action.result, action.search),
      };

    default:
      return state;
  }
}
