import queryString from 'query-string';

import Config from '../../constants/config';
import { catchServerError } from '../../Sentry';

const createMessageIdGenerator = (startFrom = 1, maxValue = 100000) => {
  let nextId = startFrom;

  return () => {
    if (nextId > maxValue) {
      nextId = startFrom;
    }

    // eslint-disable-next-line no-plusplus
    return nextId++;
  };
};

const getMessageId = createMessageIdGenerator();

export const getMessages = async (query, domain, minFare) => {
  const url = `${Config.baseURL}/api/marketing/messages?${queryString.stringify({
    ...query,
    domain,
    minFare,
  })}`;
  let response = null;
  try {
    response = await fetch(url);
    catchServerError(null, {
      response,
      request: { url },
    });
    const messages = await response.json();
    return messages.map(message => ({
      id: getMessageId(),
      ...message,
    }));
  } catch (error) {
    catchServerError(error, {
      response,
      request: { url },
    });
  }

  return [];
};
