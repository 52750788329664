import './ItineraryDetails.css';

import React from 'react';
import { Container } from 'travelopod-components';

import FlightIcon from '../../assets/icons/flight.svg';
import ContainerHeader from '../ContainerHeader';
import Indent from '../Indent';
import Header from '../Typography/Header';

const ItineraryDetails = ({ itineraryDetail, rawItinerary }) => {
  return rawItinerary ? (
    <React.Fragment>
      <Header as="h3">Itinerary Detail</Header>
      <p className="itinerary-details itinerary-details-break-words">{itineraryDetail}</p>
    </React.Fragment>
  ) : (
    <Container className="itinerary-details-wrapper">
      <ContainerHeader title="Itinerary Details" icon={FlightIcon} />
      <Indent>
        <pre className="itinerary-details">{itineraryDetail}</pre>
      </Indent>
    </Container>
  );
};

export default ItineraryDetails;
