import 'rc-slider/assets/index.css';
import './FlightFilters.css';

import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Airlines,
  Airports,
  Amenities,
  AppliedFilters,
  Button,
  DesktopLarge,
  Divider,
  Duration,
  FieldsWithHeader,
  FlexBox,
  Indent,
  Mobile,
  Range,
  Slider,
  Stops,
  Tablet,
  Text,
  Time,
} from 'travelopod-components';

import { formatTime } from '../../helpers/dateHelper';
import { formatDuration } from '../../helpers/formatHelper';
import Sorting from '../Sorting/Sorting';

const AMENITIES = {
  baggageOnly: 'With baggage only',
};

const TIME_INTERVAL = 15;

const categoriesByFilterName = {
  stops: 'stops',
  maxFlightDuration: 'duration',
  layover: 'duration',
  outbound: 'time',
  return: 'time',
  airlines: 'airlines',
  arriving: 'airports',
  departing: 'airports',
  amenities: 'amenities',
};

const mapToCheckboxFields = (dictionary, checkboxLabelDictionary) =>
  Object.keys(dictionary).map(field => ({
    name: field,
    label: dictionary[field].name ? dictionary[field].name : checkboxLabelDictionary[field],
    price: dictionary[field].price,
  }));

const shouldShowClear = (filterName, appliedFilters) => appliedFilters.includes(filterName);

const countAppliedFilterCategories = appliedFilters => {
  let categories = new Set();

  appliedFilters.forEach(appliedFilter => {
    categories.add(categoriesByFilterName[appliedFilter]);
  });

  return categories.size;
};

const FlightListFilters = ({
  isOpenedFiltersBlock,
  filterValues: {
    stops,
    maxFlightDuration,
    layover,
    outbound,
    return: inbound,
    airlines,
    departingAirports,
    arrivingAirports,
    amenities,
  },
  closeFiltersModal,
  onChange,
  onRangeChange,
  queryFilters,
  onCheckboxChange,
  searchFlightsResponse,
  appliedFilters,
  updateFilters,
  filterValues,
  onResetFlightsFilters,
  isMKTSource,
}) => {
  const currencySource = isMKTSource();
  const [maxFlightDurationTime, setmaxFlightDurationTime] = useState(parseInt(queryFilters.maxFlightDuration, 10));
  const [maxlayoverTime, setmaxlayoverTime] = useState(queryFilters.layover.map(el => parseInt(el, 10)));
  const [maxOutboundTime, setmaxOutboundTime] = useState(queryFilters.outbound.map(el => parseInt(el, 10)));
  const [maxReturnTime, setmaxReturnTime] = useState(queryFilters.return.map(el => parseInt(el, 10)));
  return (
    <React.Fragment>
      {/* ONLY FOR MOBILE VIEW */}
      <div className={`title-filters ${isOpenedFiltersBlock ? 'show' : 'hidden'}`}>
        <h3>Refine results</h3>
      </div>
      {/* ONLY FOR DEKSTOP VIEW */}
      <Tablet>
        <div className="filter-by">
          <h3>Filter by</h3>
        </div>
      </Tablet>
      <div className="flight-list-filters">
        <Indent>
          {/* ONLY FOR DEKSTOP VIEW */}
          <DesktopLarge>
            <div className="filter-by">
              <h3>Filter by</h3>
            </div>
          </DesktopLarge>

          {!!appliedFilters.length && (
            <Indent ma="b-large" className="filters-clear-all">
              <AppliedFilters
                countOfAppliedFilters={countAppliedFilterCategories(appliedFilters)}
                showClear
                onClear={() => onResetFlightsFilters()}
                clearText="Clear all"
              />
            </Indent>
          )}

          <Mobile>
            <div className="flight-list-sorting-mobile">
              <Text ma="v-large" weight="bold" className="sorting-label">
                Sort
              </Text>
              <Sorting fields={searchFlightsResponse} currencySource={currencySource} />
            </div>
          </Mobile>

          <Stops
            showClear={shouldShowClear('stops', appliedFilters)}
            fields={{
              directOnly: {
                name: 'directOnly',
                price: stops.directOnly.price,
              },
              upToOneStop: {
                name: 'upToOneStop',
                price: stops.upToOneStop.price,
              },
              any: {
                name: 'anyStopsCount',
                price: stops.anyStopsCount.price,
              },
            }}
            value={queryFilters.stops}
            onChange={onChange('stops')}
            onClear={() => updateFilters({ stops: 'anyStopsCount' })}
            currencySource={currencySource}
          />

          <Duration
            showClear={
              shouldShowClear('maxFlightDuration', appliedFilters) || shouldShowClear('layover', appliedFilters)
            }
            onClear={() => {
              updateFilters({
                maxFlightDuration: filterValues.maxFlightDuration.max,
                layover: [filterValues.layover.min, filterValues.layover.max],
              });
            }}
          >
            <Slider
              min={
                maxFlightDuration.min
                  ? maxFlightDuration.min - (maxFlightDuration.min % TIME_INTERVAL) - TIME_INTERVAL
                  : 0
              }
              max={
                maxFlightDuration.max
                  ? maxFlightDuration.max - (maxFlightDuration.max % TIME_INTERVAL) + TIME_INTERVAL
                  : 0
              }
              value={maxFlightDurationTime}
              step={TIME_INTERVAL}
              formatter={value => formatDuration(value)}
              onChange={value => setmaxFlightDurationTime(value)}
              onAfterChange={onRangeChange('maxFlightDuration')}
            />
            <Range
              min={layover.min - (layover.min % TIME_INTERVAL) - TIME_INTERVAL}
              max={layover.max - (layover.max % TIME_INTERVAL) + TIME_INTERVAL}
              value={maxlayoverTime}
              step={TIME_INTERVAL}
              onChange={value => setmaxlayoverTime(value)}
              onAfterChange={onRangeChange('layover')}
              formatter={range => `${formatDuration(range[0])} - ${formatDuration(range[1])}`}
              disabled={queryFilters.stops === 'directOnly'}
            />
          </Duration>

          <Time
            showClear={shouldShowClear('outbound', appliedFilters) || shouldShowClear('return', appliedFilters)}
            onClear={() => {
              updateFilters({
                outbound: [filterValues.outbound.min, filterValues.outbound.max],
                return: [filterValues.return.min, filterValues.return.max],
              });
            }}
          >
            <Range
              min={outbound.min - (outbound.min % TIME_INTERVAL) - TIME_INTERVAL}
              max={outbound.max - (outbound.max % TIME_INTERVAL) + TIME_INTERVAL}
              value={maxOutboundTime}
              step={TIME_INTERVAL}
              onChange={value => setmaxOutboundTime(value)}
              onAfterChange={onRangeChange('outbound')}
              formatter={range => `${formatTime(range[0])} - ${formatTime(range[1])}`}
            />
            <Range
              min={inbound.min - (inbound.min % TIME_INTERVAL) - TIME_INTERVAL}
              max={inbound.max - (inbound.max % TIME_INTERVAL) + TIME_INTERVAL}
              value={maxReturnTime}
              step={TIME_INTERVAL}
              onChange={value => setmaxReturnTime(value)}
              onAfterChange={onRangeChange('return')}
              formatter={range => `${formatTime(range[0])} - ${formatTime(range[1])}`}
            />
          </Time>

          <Airlines
            showClear={shouldShowClear('airlines', appliedFilters)}
            fields={mapToCheckboxFields(airlines || [])}
            values={queryFilters.airlines}
            onChange={onCheckboxChange('airlines')}
            onClear={() => updateFilters({ airlines: [] })}
            currencySource={currencySource}
          />

          <Airports
            showClear={shouldShowClear('departing', appliedFilters) || shouldShowClear('arriving', appliedFilters)}
            onClear={() => {
              updateFilters({
                departing: [],
                arriving: [],
              });
            }}
          >
            <FieldsWithHeader
              className="airportsSubTitlePaddingBottom"
              subtitle="Departing From"
              fields={mapToCheckboxFields(departingAirports || [])}
              values={queryFilters.departing}
              onChange={onCheckboxChange('departing')}
              currencySource={currencySource}
            />
            <FieldsWithHeader
              className="airportsSubTitlePaddingTop airportsSubTitlePaddingBottom"
              subtitle="Travelling To"
              fields={mapToCheckboxFields(arrivingAirports || [])}
              values={queryFilters.arriving}
              onChange={onCheckboxChange('arriving')}
              currencySource={currencySource}
            />
          </Airports>

          <Amenities
            showClear={shouldShowClear('amenities', appliedFilters)}
            onClear={() => {
              updateFilters({
                amenities: [],
              });
            }}
            fields={mapToCheckboxFields(amenities || [], AMENITIES)}
            values={queryFilters.amenities}
            onChange={onCheckboxChange('amenities')}
            currencySource={currencySource}
          />
        </Indent>
      </div>

      <Indent ma="v-large">
        <Divider />
        <FlexBox alignItems="center" justifyContent="center" id="see-filtered-results">
          <Button
            className={classNames('btn-close', {
              show: isOpenedFiltersBlock,
              hidden: !isOpenedFiltersBlock,
            })}
            size="large"
            onClick={closeFiltersModal}
          >
            See {searchFlightsResponse && searchFlightsResponse.value ? searchFlightsResponse.value.total : ''} results
          </Button>
        </FlexBox>
      </Indent>
    </React.Fragment>
  );
};

export default FlightListFilters;
