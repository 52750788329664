// @flow
import { combineReducers } from 'redux';

import data from './data';
import searchAirports from './searchAirports';
import search from './searchCalendar';

export default combineReducers({
  data,
  search,
  searchAirports,
});
