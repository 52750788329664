import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { FlexBox, Icon, Text } from 'travelopod-components';

import Direction from '../../constants/direction';
import { SHORT_DATE_FORMAT } from '../../constants/global';
import FlightDetailsIcon from '../../assets/icons/flight_details.svg';

const getItinerary = (flight, tripDirection) => {
  if (tripDirection === Direction.OUTBOUND) {
    return `${flight.origin.airportCode} - ${flight.destination.airportCode}`;
  }

  return `${flight.destination.airportCode} - ${flight.origin.airportCode}`;
};

const TripsInfo = ({ flight }) => {
  const trips = flight.trips;

  if (isEmpty(trips)) {
    return null;
  }

  return trips.map((trip, index) => {
    const date = moment
      .utc(`${trip.departureDate}${trip.departureTime}`, 'DDMMYYHHmm')
      .format(`${SHORT_DATE_FORMAT} YYYY - HH:mm`);

    return (
      <FlexBox key={index} alignItems="center" ma="b-half">
        <Icon
          secondary
          src={FlightDetailsIcon}
          ma="r-half"
          size="small"
          rotate={trip.direction === Direction.OUTBOUND ? '180' : false}
        />
        <Text as="span">
          {date}, {getItinerary(flight, trip.direction)}
        </Text>
      </FlexBox>
    );
  });
};

export default TripsInfo;
