import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { Error } from 'travelopod-components';
import withDomain from '../../Hocs/withDomain';
import withLayout from '../../Hocs/withLayout';
import Aside from '../../components/Aside/Aside';
import ItineraryDetails from './ItineraryDetails';
import { getParameterByName } from '../../helpers';
import { branch, compose, lifecycle } from 'recompose';
import { triggerPageView } from '../../helpers/GoogleAnalytics';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import { fetchReceipt } from '../../store/modules/payment/receipt';
import { setBookingPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';

const mapStateToProps = ({ payment }) => ({
  receipt: payment.receipt.data,
  error: payment.receipt.error,
  loading: payment.receipt.loading,
});

const mapDispatchToProps = {
  fetchReceipt,
  updatePhoneTFN,
  setBookingPhone,
};

export default compose(
  withCallTrackingUrl(),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchReceipt } = this.props;

      fetchReceipt(getParameterByName('token'));
    },
  }),
  branch(
    ({ loading, error }) => loading && !error,
    () => Loader,
  ),
  branch(
    ({ error }) => error,
    () =>
      ({ error }) => <Error message={error} />,
  ),
  withLayout({ Aside }),
  withDomain(),
  lifecycle({
    componentDidMount() {
      triggerPageView();
      if (!localStorage.getItem('call_tracking_initial_params')) {
        this.props.setBookingPhone();
      }
    },
  }),
)(ItineraryDetails);
