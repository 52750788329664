import './FlightFilters.css';

import { isEqual, pick, reduce } from 'lodash';
import React from 'react';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  renderNothing,
  withHandlers,
  withProps,
  withPropsOnChange,
} from 'recompose';
import { Modal } from 'travelopod-components';

import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import withFilters from '../../Hocs/withFilters';
import withResolution from '../../Hocs/withResolution';
import Skeleton from './FlightListFilters.skeleton';
import Filters from './FlightListFilters.view';

function MobileFilter({ children, closeFiltersModal, isOpenedFiltersBlock }) {
  return (
    <Modal
      className="modal-filters"
      contentClassName="modal-filter-content"
      btnCloseClassName="modal-filters-close-btn"
      closeIconClassName="modal-filters-close-icon"
      isOpen={isOpenedFiltersBlock}
      noBorder
      pushUp
      onClose={closeFiltersModal}
    >
      {children}
    </Modal>
  );
}

const filterKeys = [
  'airlines',
  'amenities',
  'arriving',
  'departing',
  'layover',
  'maxFlightDuration',
  'outbound',
  'return',
  'stops',
];

const defaultFilters = (filters) => {
  if (!filters) {
    return null;
  }

  return {
    airlines: [],
    amenities: [],
    arriving: [],
    departing: [],
    layover: [`${filters.layover.min}`, `${filters.layover.max}`],
    maxFlightDuration: `${filters.maxFlightDuration.max}`,
    outbound: [`${filters.outbound.min}`, `${filters.outbound.max}`],
    return: [`${filters.return.min}`, `${filters.return.max}`],
    stops: 'anyStopsCount',
  };
};

export default compose(
  withCallTrackingUrl(),
  branch(
    ({ loading, searchFlightsResponse }) =>
      loading === null ||
      (searchFlightsResponse && searchFlightsResponse.value && !searchFlightsResponse.value.filters),
    renderComponent(() => <div style={{ flex: 1 }} />),
  ),
  withPropsOnChange(
    (props, nextProps) => !!nextProps.serverFilters,
    ({ serverFilters }) => ({ filterValues: serverFilters }),
  ),
  branch((props) => !props.filterValues, renderNothing),
  withFilters((props) => defaultFilters(props.filterValues)),
  withHandlers({
    // TO_DO - unify mechanism of filters works (value -> onChange(value))
    onCheckboxChange: (props) => (name) => (f, value) => {
      const { checked, field } = value;
      const list = props.queryFilters[name] || [];

      props.updateFilters({ [name]: checked ? [...list, field] : list.filter((i) => i !== field) });
      checked && window.scrollTo(0, 0);
    },
    onRangeChange: (props) => (name) => (field) => {
      props.updateFilters({ [name]: field });
    },
    onChange: (props) => (name) => (f, value) => {
      props.updateFilters({ [name]: value.field });
      window.scrollTo(0, 0);
    },
    onResetFlightsFilters: (props) => () => {
      props.updateFilters({ ...defaultFilters(props.filterValues) });
    },
  }),
  withProps((props) => {
    const currentFilters = pick(props.queryFilters, filterKeys);
    const appliedFilters = reduce(
      defaultFilters(props.filterValues),
      (result, value, key) => (isEqual(value, currentFilters[key]) ? result : result.concat(key)),
      [],
    );

    return {
      appliedFilters,
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateFilters({});
    },
  }),
  withResolution(
    ({ isOpened, isOpenedFiltersBlock, ...props }) => <Filters {...props}></Filters>,
    MobileFilter,
    MobileFilter,
  ),
  branch((props) => props.loading, renderComponent(Skeleton)),
)(Filters);
