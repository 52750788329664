// @flow
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { branch, renderNothing } from 'recompose';
import { Divider, Header, Indent, List, Text } from 'travelopod-components';

type Props = {
  pnrRemarks: Array,
};

const RIR_SUBSTRING = 'RIR - ';

const transformPnrRemarks = pnrRemarks =>
  pnrRemarks.reduce(
    (remarks, item) =>
      item.message.includes(RIR_SUBSTRING) ? [...remarks, item.message.replace(RIR_SUBSTRING, '')] : remarks,
    [],
  );

const PnrRemarks = ({ pnrRemarks }: Props) => (
  <React.Fragment>
    <Divider />
    <Indent pa="big">
      <Header as="h3">Important Information</Header>
      <List ma="t-double">
        {transformPnrRemarks(pnrRemarks).map((remark, i) => (
          <Text key={i} as="span">
            {remark}
          </Text>
        ))}
      </List>
    </Indent>
  </React.Fragment>
);

export default branch(({ pnrRemarks }) => isEmpty(pnrRemarks), renderNothing)(PnrRemarks);
