import React, { Component } from 'react';

import { PAGINATION_ITEMS_PER_PAGE } from '../constants/global';

const INITIAL_PAGE = 1;

const withPagination = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { page: INITIAL_PAGE };
    }

    resetPage = () => {
      this.setState({
        page: INITIAL_PAGE,
      });
    };

    loadMore = async (callback, page) => {
      const params = {
        ...this.props.queryFilters,
        limit: `${PAGINATION_ITEMS_PER_PAGE}`,
        offset: `${PAGINATION_ITEMS_PER_PAGE * this.state.page}`,
      };

      await callback(params);

      this.setState({
        page: this.state.page + 1,
      });
    };

    render() {
      return <WrappedComponent loadMore={this.loadMore} resetPage={this.resetPage} {...this.props} />;
    }
  };
};

export default withPagination;
