// @flow
import { combineReducers } from 'redux';

import confirm from './confirm';
import countries from './countries';
import create from './create';
import data from './data';
import receipt from './receipt';

export default combineReducers({
  data,
  create,
  confirm,
  countries,
  receipt,
});

export const paymentDataSelector = state => state.payment.data;
