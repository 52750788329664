import './Countdownfinal.css';

import React from 'react';
import { Modal } from 'semantic-ui-react';

import flightWarning from '../../assets/images/flightExpired.svg';

function Countdownfinal({ open, setOpen }) {
  function refreshCurrentPage() {
    window.location.reload();
  }

  function revertToSearchPage() {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
      <div className="countdownfinal">
        <div id="insideCOuntdownWarmning" style={{ position: 'relative' }}>
          <img style={{ marginTop: '10px', marginBottom: '8px' }} src={flightWarning} alt="" />

          <h1> Oops! Session expired </h1>
          <p>We need to recheck your flight availability as current fare may have expired.</p>
          <div className="continuePageReload">
            <button id="continuePage" onClick={refreshCurrentPage}>
              Check Latest Fares
            </button>

            <a style={{ color: 'black' }} onClick={revertToSearchPage}>
              Back to Search Results
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Countdownfinal;
