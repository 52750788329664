import {
  branch,
  compose,
  lifecycle,
  withProps,
  withHandlers,
  renderNothing,
  renderComponent,
  withStateHandlers,
} from 'recompose';
import React from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import pubnub from '../../../../api/pubnub';
import withRouter from '../../../../Hocs/wihRouter';
import {
  openOneDayModal,
  openSameDayModal,
  getIsBannersShown,
  closeDefaultModals,
  openSuperDealModal,
} from '../../../../store/modules/modalGeneral';
import Skeleton from './FlightCardList.skeleton';
import { NoFlights } from 'travelopod-components';
import FlightCardList from './FlightCardList.view';
import withDomain from '../../../../Hocs/withDomain';
import { connect as reduxConnect } from 'react-redux';
import withPagination from '../../../../Hocs/withPagination';
import { stringToUtc } from '../../../../helpers/dateHelper';
import { getDateSubstring } from '../../../../helpers/formatHelper';
import withCallTrackingUrl from '../../../../Hocs/withCallTrackingUrl';
import { loadMoreSearchFlights } from '../../../../api/modules/searchFlights';
import withVerifyFlightBooking from '../../../../Hocs/withVerifyFlightBooking';
import { getPhoneDataSelector } from '../../../../store/modules/responseTap/phoneData';

const CARD_HEIGHT = 254;

const getChipperCards = (stages, groups, position, top) => {
  const upperCardCount = Math.min(Math.floor((position - top) / CARD_HEIGHT), stages ? stages.length - 1 : 9);
  if (!upperCardCount || !stages || !stages[upperCardCount]) {
    return [];
  }

  const currentStage = stages[upperCardCount] || {};
  const stagePrice = stages[upperCardCount].price ? stages[upperCardCount].price.total : 0;
  const indexes = [];

  for (let i = 0; i < upperCardCount; i++) {
    const cardPrice = groups[i] && groups[i].price ? groups[i].price.total : 0;

    if (
      cardPrice < stagePrice &&
      !stages.find((stage) => stage.price.total === cardPrice && currentStage.gdsType !== groups[i].gdsType)
    ) {
      indexes.push(i);
    }
  }
  return indexes;
};

const skeletonPredicate = (props) => {
  if (props.flight && props.flight.value && props.flight.value.groups) {
    return false;
  }
  if (props.pending === true) {
    return !props.stage;
  }
};

const noFlightPredicate = (props) => {
  if (props.stage && !!props.stage.length) {
    return false;
  }
  return (
    !props.flight ||
    (!props.flight.pending && !props.flight.value) ||
    (!props.flight.pending && !props.flight.value.groups.length)
  );
};

const dateFromNow = (departureDate) => {
  const now = moment.utc().startOf('day');

  return moment.duration(stringToUtc(departureDate, 'DDMMYY').startOf('day').diff(now)).as('days');
};

const minimumNights = (flight) => {
  const departureDate = getDateSubstring(flight.trips[0].departureDate);
  const returnDate = getDateSubstring(flight.trips[1].departureDate);

  if (dateFromNow(departureDate) <= 3) {
    return departureDate === returnDate;
  }

  return false;
};

const oneDayFlight = (flight, tripType) => {
  const departureDate = getDateSubstring(flight.trips[0].departureDate);

  if (tripType !== 'ONE_WAY' && dateFromNow(departureDate) < 3) {
    const returnDate = getDateSubstring(flight.trips[1].departureDate);

    return departureDate !== returnDate;
  }

  if (tripType === 'ONE_WAY') {
    return dateFromNow(departureDate) < 3;
  }

  return false;
};

const mapStateToProps = (state) => ({
  phoneData: getPhoneDataSelector(state),
  isBannersShown: getIsBannersShown(state),
  isUserLoggedIn: state.authUser.user,
});

export default compose(
  withRouter,
  reduxConnect(mapStateToProps, { closeDefaultModals, openSuperDealModal, openOneDayModal, openSameDayModal }),
  withDomain(),
  withCallTrackingUrl(),
  withStateHandlers(
    (props) => {
      return {
        superDeal: false,
        sameDay: false,
        oneDay: false,
        groups: [...(props.flight?.value ? props.flight.value.groups : props.stage || [])],
        container: null,
        stage: null,
        chipperIndexes: [],
        isChipperShows: false,
      };
    },
    {
      updateStages: () => (stage) => ({
        stage,
      }),

      setChipperIndexes: () => (indexes) => ({
        chipperIndexes: indexes,
        isChipperShows: !!indexes.length,
      }),
      hideIsChipper: () => () => ({
        isChipperShows: false,
      }),
      updateGroups: (state) => (groups, clear) => ({ groups: [...(clear ? groups : [...state.groups, ...groups])] }),
      setContainer: () => (container) => ({ container }),
      saveGroupsAfterRefresh: () => (groups) => ({ groups }),
    },
  ),
  withHandlers({
    updateFlightGroups: (props) => async (filters) => {
      const data = await loadMoreSearchFlights(filters, props.getDomainName());

      props.updateGroups(data.groups);
    },
  }),
  withVerifyFlightBooking({
    onBookFlight:
      ({ handleBooking }) =>
      (flight) =>
        handleBooking(flight),
    onContinueSearch:
      ({ queryFilters, searchFlights, getDomainName }) =>
      () => {
        window.scrollTo(0, 0);

        searchFlights(queryFilters, getDomainName(), null, { invalidateCache: true });
      },
  }),
  withProps((props) => {
    return {
      flight: props.flight,
      handleBooking: (e, { flight, flightGroup }) => {
        // if (flightGroup.isOpaque) {
        //   trigger(props.getDomainName(), Events.click.bookSuperDealBtn);
        //   return props.openSuperDealModal();
        // }

        if (props.tripType !== 'ONE_WAY' && minimumNights(flight)) {
          return props.openSameDayModal();
        }

        if (oneDayFlight(flight, props.tripType)) {
          return props.openOneDayModal();
        }

        return props.verifyBooking({
          ...flight,
          ...omit(flightGroup, 'flights'),
        });
      },
      serviceClass: props.queryFilters.serviceClass,
    };
  }),
  withPagination,
  lifecycle({
    componentDidMount() {
      const { updateStages } = this.props;

      pubnub.subscribe({
        channels: [`ch:${this.props.transactionId}`],
      });

      const pubNublistener = {
        message: (messageEvent) => {
          if (messageEvent?.channel === `ch:${this.props.transactionId}`) {
            updateStages(messageEvent?.message?.payload);
          }
        },
      };

      pubnub.addListener(pubNublistener);
    },
    componentDidUpdate(prevProps) {
      if (this.props.flight && prevProps.flight && this.props.flight.pending && !prevProps.flight.pending) {
        this.props.updateStages(null);
        this.props.updateGroups([], true);
      }

      if (!this.props.flight) {
        return;
      }

      if ((!this.props.groups || !this.props.groups.length) && this.props.flight?.value?.groups.length) {
        this.props.updateGroups(this.props.flight.value.groups, !!this.props.stage);
      }
      if (this.props.stage && this.props.flight.value && (!prevProps.flight || !prevProps.flight.value)) {
        let containerTop;
        const scrollPosition = document.documentElement.scrollTop;
        try {
          containerTop = this.props.container.offsetTop;
        } catch (e) {
          containerTop = 0;
        }

        const indexes = getChipperCards(this.props.stage, this.props.flight.value.groups, scrollPosition, containerTop);
        this.props.setChipperIndexes(indexes);
        this.props.updateGroups(this.props.flight.value.groups, !!this.props.stage);
        this.props.updateStages(null);
      }
    },

    componentWillUnmount() {
      pubnub.unsubscribe({
        channels: [`ch:${this.props.transactionId}`],
      });
    },
  }),
  branch((props) => props.pending === null, renderNothing),
  branch(skeletonPredicate, renderComponent(Skeleton)),
  branch(
    noFlightPredicate,
    renderComponent(() => <NoFlights />),
  ),
)(FlightCardList);
