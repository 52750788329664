// @flow
import { fromRenderProps } from 'recompose';

import { MarketingMessagesContext } from './MarketingMessages';

export default () =>
  fromRenderProps(MarketingMessagesContext.Consumer, ({ loadMessages, loadMessagesDebounced }) => ({
    loadMessages,
    loadMessagesDebounced,
  }));
