import './BookFlight.css';

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { get, round } from 'lodash';
import { connect } from 'react-redux';

import withRouter from '../../Hocs/wihRouter';
import withDomain from '../../Hocs/withDomain';
import withRefetch from '../../Hocs/withRefetch';
import AirlineLogo from '../../components/AirlineLogo';
import { setupUpgradeSupportPackage } from '../../helpers';
import { PassengerType } from '../../constants/passengers';
import Config, { IBE_BASE_URL } from '../../constants/config';
import { getDateSubstring } from '../../helpers/formatHelper';
import BookingCountdown from '../../components/BookingCountdown';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import { getPassengersFromPriceSummary } from '../../helpers/passengers';
import { toggleCallUsFooterModal } from '../../store/modules/modalGeneral';
import PriceSummary from '../PaymentConfirmation/PriceSummary/PriceSummary';
import { saveInsuranceQuote } from '../../store/modules/insuranceQuote/save';
import {
  AdditionalServicesForm,
  Button,
  ContactDetailsForm,
  FlexBox,
  FormHandler,
  FormWrapper,
  Indent,
  Mobile,
  PassengerInfoForm,
  PaymentInfoForm,
  ReferralCodeBlock,
  ReviewFlight,
  ReviewPolicy,
  Reviews,
} from 'travelopod-components';

import {
  MARKETING_PRIVACY_POLICY,
  MARKETING_TERMS_AND_CONDITIONS,
  MARKETING_URL_BOOKINGRULES,
} from '../../constants/global';
import SubscriptionForm from '../../components/SubscriptionForm';

const COUNTRY_CODE_US = 'US';
const serializeQuote = (flight, billingData, { passengers }) => {
  const { summary, trips } = flight;
  const { adults, children, infants } = summary;
  const departDate = moment.utc(getDateSubstring(trips[0].departureDate), 'DDMMYY').format('YYYY-MM-DD');
  const arrivalDate = trips.length > 1 ? trips[1].arrivalDate : trips[0].arrivalDate;
  const returnDate = moment.utc(getDateSubstring(arrivalDate), 'DDMMYY').format('YYYY-MM-DD');

  return {
    passengers: passengers.map(({ birthday, ...passenger }) => ({
      ...passenger,
      dob: {
        year: birthday.format('YYYY'),
        month: birthday.format('MM'),
        day: birthday.format('DD'),
      },
    })),
    countryCode: COUNTRY_CODE_US,
    stateCode: billingData.billingState.code,
    zip: billingData.billingZipCode,
    address: billingData.address,
    city: billingData.city,
    departDate,
    returnDate,
    priceSummary: {
      [PassengerType.ADULT.name]: round(get(adults, 'total', 0) / get(adults, 'count', 1), 2),
      [PassengerType.CHILD.name]: round(get(children, 'total', 0) / get(children, 'count', 1), 2),
      [PassengerType.INFANT.name]: round(get(infants, 'total', 0) / get(infants, 'count', 1), 2),
    },
  };
};

const getPassengersValues = (values) => values && values[2];
const getPaymentInfoValues = (values) => values && values[3];

class BookFlightForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: null,
      isOpenedCallUsModal: false,
      upgradeSupportPackage: setupUpgradeSupportPackage(props.travelMarket),
    };
  }

  handleQuoteReady = (billingData, passengers) => {
    const { flight, getQuote } = this.props;
    getQuote(serializeQuote(flight, billingData, passengers));
  };

  handleQuoteInvalid = () => {
    const { saveInsuranceQuote } = this.props;

    saveInsuranceQuote(null);

    this.setState({
      quote: null,
      quoteError: false,
    });
  };

  unloadCallback = (event) => {
    event.preventDefault();
    this.state = {
      UserDetails: {
        ContactDetails: JSON.parse(sessionStorage.getItem('ContactDetails')),
        PaymentDetails: JSON.parse(sessionStorage.getItem('PaymentDetails')),
        PassengerDetails: JSON.parse(sessionStorage.getItem('PassengerDetails')),
        Trips: this.props.flight.trips,
        Token: this.props.flight.flightKey,
      },
    };
    this.submitFormData(this.state.UserDetails);
    event.returnValue = '';
    return '';
  };

  submitFormData = (value) => {
    fetch(`${IBE_BASE_URL}/visitor`, {
      method: 'POST',
      body: JSON.stringify({
        ...value,
        domain: 'travelopodb',
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => console.log(json));
  };
  componentDidMount() {
    window.addEventListener('beforeunload', this.unloadCallback);
  }
  componentDidUpdate(prevProps) {
    const { getQuoteResponse, saveInsuranceQuote } = this.props;

    if (prevProps.getQuoteResponse && prevProps.getQuoteResponse.pending && getQuoteResponse.fulfilled) {
      saveInsuranceQuote(getQuoteResponse.value);

      this.setState({
        quote: getQuoteResponse.value,
      });
    }

    if (prevProps.getQuoteResponse && prevProps.getQuoteResponse.pending && getQuoteResponse.rejected) {
      saveInsuranceQuote(null);

      this.setState({
        quoteError: true,
      });
    }

    window.addEventListener('beforeunload', this.unloadCallback);
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.unloadCallback);
  }

  handleUpgradeSupportStateChange = (value) => {
    this.setState({
      upgradeSupportPackage: value,
    });
  };

  render() {
    const {
      flight,
      countries,
      submitting,
      onSubmit,
      onValidationError,
      getQuoteResponse,
      serverErrors,
      buildMarketingUrl,
      location,
      isOnline,
    } = this.props;
    const { quote, quoteError, upgradeSupportPackage } = this.state;
    const loading = getQuoteResponse && getQuoteResponse.pending;
    const passengers = getPassengersFromPriceSummary(flight.summary);
    const states = countries.find(({ code }) => code === COUNTRY_CODE_US).billingStates;
    const queryFilterFromSessionStorage = sessionStorage.getItem('queryFilters');

    return (
      <React.Fragment>
        {/* BOOKING COUNTDOWN */}
        <Mobile>
          <BookingCountdown />
        </Mobile>

        <FormHandler
          onSubmit={(values) => onSubmit([...values, { upgradeSupportPackage, quote }])}
          onError={onValidationError}
        >
          {/* FLIGHT REVIEWS */}
          <ReviewFlight
            flight={flight}
            airlineLogoComponent={AirlineLogo}
            serviceClass={location.state.queryFilters.serviceClass || queryFilterFromSessionStorage?.serviceClass}
          />

          {/* CUSTOMER INFO */}
          <FormWrapper groupName="Reviews">
            <Reviews />
          </FormWrapper>

          {/* CONTACT INFO */}
          <FormWrapper groupName="ContactDetails">
            <ContactDetailsForm />
          </FormWrapper>

          {/* PASSENGER INFO */}
          <FormWrapper groupName="PassengerInfo">
            <PassengerInfoForm
              airlineLogoComponent={AirlineLogo}
              flight={flight}
              passengers={passengers}
              serverErrors={serverErrors}
            />
          </FormWrapper>

          {/* PAYMENT INFORMATION - CARD DETAILS AND BILLING DETAILS */}
          <FormWrapper groupName="PaymentInfo">
            <PaymentInfoForm countries={countries} serverErrors={serverErrors} />
          </FormWrapper>

          {/* REFERRAL INFO BLOCK */}
          <FormWrapper groupName="referralInfo">
            <ReferralCodeBlock />
          </FormWrapper>

          {/* INSURANCE BLOCK */}
          <FormWrapper withOtherFormValues groupName="AdditionalService">
            <AdditionalServicesForm
              loading={loading}
              quote={quote}
              quoteError={quoteError}
              states={states}
              getPassengersValues={getPassengersValues}
              getPaymentInfoValues={getPaymentInfoValues}
              onQuoteInvalid={this.handleQuoteInvalid}
              onQuoteReady={this.handleQuoteReady}
              handleUpgradeSupportStateChange={this.handleUpgradeSupportStateChange}
              upgradeSupportPackage={upgradeSupportPackage}
            />
          </FormWrapper>

          {/* REVIEW POLICY BLOCK */}
          <ReviewPolicy
            links={{
              bookingRules: buildMarketingUrl(MARKETING_URL_BOOKINGRULES),
              termsConditions: buildMarketingUrl(MARKETING_TERMS_AND_CONDITIONS),
              privacyPolicy: buildMarketingUrl(MARKETING_PRIVACY_POLICY),
            }}
          />

          {/* PRICE SUMMARY BLOCK */}
          <Indent ma="t-double">
            <PriceSummary
              className={'book-flight-form-price-summary'}
              isCancellationShown
              flight={flight}
              isOnline={isOnline()}
              upgradeSupportPackage={upgradeSupportPackage}
            />
          </Indent>

          {/* SUBSCRIPTION STATUS CHECKBOX */}
          <FormWrapper groupName="subscriptionStatus">
            <SubscriptionForm />
          </FormWrapper>

          {/* SUBMIT BUTTON */}
          <FlexBox justifyContent="center" ma="t-double">
            <Button type="submit" size="large" className="book-flight-form-btn" disabled={submitting}>
              Authorize &amp; Complete Booking
            </Button>
          </FlexBox>
        </FormHandler>

        {/* CUSTOMER REVIEW SLIDER */}
        <FormWrapper groupName="Reviews">
          <Reviews />
        </FormWrapper>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  saveInsuranceQuote,
  toggleCallUsFooterModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withDomain(),
  withRefetch(() => ({
    getQuote: (data) => ({
      getQuoteResponse: {
        force: true,
        url: `${Config.oldVersionURL}/insurance/quote`,
        method: 'POST',
        body: JSON.stringify(data),
      },
    }),
  })),
  withCallTrackingUrl(),
)(BookFlightForm);
