export const CATEGORY = {
  travelopod: 'TravelopodA',
  travelopodb: 'TravelopodB',
  flykart: 'Flykart',
};
export const ACTIONS = {
  MARKETING: 'Marketing',
  BOOKING: 'Booking',
  PAYMENT: 'Payment',
  CHAT: 'Chat',
  PHONE_CALLS: 'PhoneCalls',
  EXIT_INTENT: 'ExitIntentPopUp',
  PRICE_CHANGED: 'PriceChangeModal',
  NO_SEATS: 'SeatsChangeModal',
  ADD_TO_CART: 'add_to_cart',
  CALL_NOW: 'call_now',
  COMPLETE_BOOKING: 'checkout_progress',
};

export const LABELS = {
  CLK_NUMBER_DEFAULT: 'clickOnNumberDefault',
  CLK_NUMBER_CALL_US_BEST_DEALS: 'clickOnNumberCallUsForBestDeals',
  CLK_NUMBER_HEADER: 'clickOnNumberHeader',
  CLK_NUMBER_SEARCH_P_1: 'clickOnNumberSearchPage1',
  CLK_NUMBER_SEARCH_P_2: 'clickOnNumberSearchPage2',
  CLK_NUMBER_HELP_BANNER: 'clickOnNumberHelpBanner',
  CLK_NUMBER_SUPER_DEAL: 'clickOnNumberSuperDeal',
  DEFAULT_POPUP_CLOSED: 'DefaultPopUpClosed',
  DEFAULT_POPUP_OPENED: 'DefaultPopUpOpened',
  START_CHAT: 'startChatting',
  AUTH_BOOK_VALIDATION: 'BookingClickAuthorize_ValidationError',
  AUTH_PAYMENT_VALIDATION: 'PaymentClickAuthorize_ValidationError',
  CLK_SUPER_DEAL_BTN: 'ClickOnSuperDealPopup',
  EXIT_INTENT_SHOW: 'ExitPopOpened',
  EXIT_INTENT_HIDE: 'clickOnExitPopUp',
  EXIT_INTENT_SUBMIT: 'clickOnSubmit',
  PRICE_CHANGE_MODAL_OPEN: 'PriceChangeOpened',
  PRICE_CHANGE_MODAL_CONTINUE_BOOKING: 'PriceChangeContinueBooking',
  PRICE_CHANGE_MODAL_NEW_SEARCH: 'PriceChangeNewSearch',
  PRICE_CHANGE_MODAL_CLOSE: 'PriceChangeClosed',
  PRICE_CHANGE_MODAL_PHONE_CALL: 'clickOnNumberPriceChange',
  NO_SEATS_MODAL_OPEN: 'SeatsChangeOpened',
  NO_SEATS_MODAL_NEW_SEARCH: 'SeatChangeNewSearch',
  NO_SEATS_MODAL_CLOSE: 'PriceChangeClosed',
  NO_SEATS_MODAL_PHONE_CALL: 'clickOnNumberSeatsChange',
  BOOK_FLIGHT: 'Book Flight',
  COMPLETE_BOOKING: 'Authorize & Complete Booking',
};

export const Events = {
  click: {
    phoneNumberDefault: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_DEFAULT,
    },
    phoneNumberCallUsForBestDeals: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_CALL_US_BEST_DEALS,
    },
    phoneNumberHeader: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_HEADER,
    },
    phoneNumberSearchPage1: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_SEARCH_P_1,
    },
    phoneNumberSearchPage2: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_SEARCH_P_2,
    },
    phoneNumberHelpBanner: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_HELP_BANNER,
    },
    phoneNumberSuperDeal: {
      action: ACTIONS.PHONE_CALLS,
      label: LABELS.CLK_NUMBER_SUPER_DEAL,
    },
    bookSuperDealBtn: {
      action: ACTIONS.CALL_NOW,
      label: LABELS.CLK_SUPER_DEAL_BTN,
    },
    completeBooking: {
      action: ACTIONS.COMPLETE_BOOKING,
      label: LABELS.COMPLETE_BOOKING,
    },
  },
  modal: {
    default: {
      open: {
        action: ACTIONS.MARKETING,
        label: LABELS.DEFAULT_POPUP_OPENED,
      },
      close: { action: ACTIONS.MARKETING, label: LABELS.DEFAULT_POPUP_CLOSED },
    },
    footer: {
      open: {
        action: ACTIONS.MARKETING,
        label: LABELS.DEFAULT_POPUP_OPENED,
      },
      close: { action: ACTIONS.MARKETING, label: LABELS.DEFAULT_POPUP_CLOSED },
    },
    priceChange: {
      open: { action: ACTIONS.PRICE_CHANGED, label: LABELS.PRICE_CHANGE_MODAL_OPEN },
      continueBooking: { action: ACTIONS.PRICE_CHANGED, label: LABELS.PRICE_CHANGE_MODAL_CONTINUE_BOOKING },
      newSearch: { action: ACTIONS.PRICE_CHANGED, label: LABELS.PRICE_CHANGE_MODAL_NEW_SEARCH },
      close: { action: ACTIONS.PRICE_CHANGED, label: LABELS.PRICE_CHANGE_MODAL_CLOSE },
      phoneCall: { action: ACTIONS.PHONE_CALLS, label: LABELS.PRICE_CHANGE_MODAL_PHONE_CALL },
    },
    noSeats: {
      open: { action: ACTIONS.NO_SEATS, label: LABELS.NO_SEATS_MODAL_OPEN },
      newSearch: { action: ACTIONS.NO_SEATS, label: LABELS.NO_SEATS_MODAL_NEW_SEARCH },
      close: { action: ACTIONS.NO_SEATS, label: LABELS.NO_SEATS_MODAL_CLOSE },
      phoneCall: { action: ACTIONS.PHONE_CALLS, label: LABELS.NO_SEATS_MODAL_PHONE_CALL },
    },
    bookFlight: {
      book: { action: ACTIONS.ADD_TO_CART, label: LABELS.BOOK_FLIGHT },
    },
  },
  validation: {
    booking: details => ({
      action: ACTIONS.BOOKING,
      label: `${LABELS.AUTH_BOOK_VALIDATION}: ${details}`,
    }),
    payment: details => ({
      action: ACTIONS.PAYMENT,
      label: `${LABELS.AUTH_PAYMENT_VALIDATION}: ${details}`,
    }),
  },
  zopimChat: {
    start: {
      action: ACTIONS.CHAT,
      label: LABELS.START_CHAT,
    },
  },
};
