import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { NoSeatsModal, PriceChangedModal } from 'travelopod-components';

import { verifyBooking } from '../api/modules/searchFlights';
import { Events } from '../constants/googleAnalyticsEvents';
import { trigger } from '../helpers/GoogleAnalytics';
import { formatPhone } from '../helpers/phone';
import {
  getNoSeatsModalStatus,
  getPhoneData,
  getPriceChangedModalStatus,
  savePhoneData,
  saveVerifyBookingData,
  toggleNoSeatsModal,
  togglePriceChangedModal,
} from '../store/modules/modalGeneral';
const priceChangedCodeName = 'PRICE_HAS_CHANGED';
const noSeatsCodeName = 'SEATS_NOT_AVAILABLE';
const withVerifyFlightBooking = ({ onBookFlight, onContinueSearch, bookPage }) => WrappedComponent => {
  class WithVerifyFlightBooking extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showNoSeatsModal: false,
        showPriceChangedModal: false,
        verifyingFlight: null,
        isSubmitButtonClicked: false,
      };
    }

    componentDidMount() {
      savePhoneData(this.props.phoneData);
    }

    componentDidUpdate() {
      if (this.props.phoneDataStore.phone !== this.props.phoneData.phone) {
        savePhoneData(this.props.phoneData);
      }
    }

    openNoSeatsModal = () => {
      this.props.toggleNoSeatsModal();
      trigger(this.props.getDomainName(), Events.modal.noSeats.open);
      this.setState({ showNoSeatsModal: true });
    };

    openPriceChangedModal = () => {
      this.props.togglePriceChangedModal();
      trigger(this.props.getDomainName(), Events.modal.priceChange.open);
      this.setState({ showPriceChangedModal: true });
    };

    closePriceChangedModal = () => {
      trigger(this.props.getDomainName(), Events.modal.priceChange.close);
      this.props.togglePriceChangedModal();

      this.setState({
        showPriceChangedModal: false,
      });
    };

    closeNoSeatsModal = () => {
      trigger(this.props.getDomainName(), Events.modal.noSeats.close);
      this.props.toggleNoSeatsModal();
      this.setState({
        showNoSeatsModal: false,
      });
    };

    handleSubmitButtonClickToggle = () => {
      this.setState({
        isSubmitButtonClicked: !this.state.isSubmitButtonClicked,
      });
    };

    /**
     * Handle the verification of flight booking.
     * @param {Object} flight - The flight to verify.
     * @param  {...any} args - Additional arguments.
     */
    handleVerifyBooking = async (flight, ...args) => {
      this.handleSubmitButtonClickToggle();
      trigger(this.props.getDomainName(), Events.modal.bookFlight.book);
      this.flightToBook = flight;
      this.args = args;

      try {
        this.setState({ verifyingFlight: flight });
        // Verify the flight booking
        const verifyFlight = await verifyBooking(flight);
        // Save the verify booking data
        this.props.saveVerifyBookingData(verifyFlight);
        // Set the verify flight
        this.verifyFlight = verifyFlight;
        // Handle price changed scenario
        if (verifyFlight.code === priceChangedCodeName) {
          if (!this.props.domainName || bookPage) {
            // If no domain name is present or if on the book page, open the price changed modal
            this.openPriceChangedModal();
            return;
          }
          // Otherwise, complete the verification booking process
          this.completeVerifyBooking(onBookFlight);
          return;
        }
        // Handle the scenario where there are no available seats
        if (verifyFlight.code === noSeatsCodeName) {
          this.openNoSeatsModal();
          return;
        }
      } finally {
        // Ensure that the verification booking process is completed
        if (!this.state.showPriceChangedModal && !this.state.showNoSeatsModal) {
          // If neither the price changed modal nor the no seats modal is shown
          // Set the verified flight accordingly
          this.verifyFlight = !this.props.domainName ? flight : this.verifyFlight;
          // Complete the verification booking process
          this.completeVerifyBooking(onBookFlight);
        }
        return;
      }
    };

    handleClose = () => {
      this.state.showPriceChangedModal && trigger(this.props.getDomainName(), Events.modal.priceChange.newSearch);
      this.state.showNoSeatsModal && trigger(this.props.getDomainName(), Events.modal.noSeats.newSearch);
      this.setState({ verifyingFlight: null });
      this.completeVerifyBooking(onContinueSearch);
    };

    handleContinueBooking = () => {
      this.state.showPriceChangedModal && trigger(this.props.getDomainName(), Events.modal.priceChange.continueBooking);
      this.state.showNoSeatsModal && trigger(this.props.getDomainName(), Events.modal.noSeats.continueBooking);
      this.completeVerifyBooking(onBookFlight);
    };

    /**
     * This function completes the verification of a booking by executing necessary actions
     * and invoking a callback function with the updated flight details.
     * @param {Function} callback - The callback function to be executed after completing the verification.
     */
    completeVerifyBooking(callback) {
      this.state.showPriceChangedModal && this.closePriceChangedModal();
      this.state.showNoSeatsModal && this.closeNoSeatsModal();
      // Get the summary of any changed flight details
      const changedSummary = get(this, 'verifyFlight.summary');
      // Prepare the final flight details to be passed to the callback function
      const finalFlight = changedSummary
        ? {
          // If there are changes in summary, update the flight details accordingly
          ...this.flightToBook,
          price: {
            ...this.flightToBook.price,
            previousTotal: this.flightToBook.price.total, // Store the previous total amount
            total: changedSummary.total.total, // Update the total amount with the changed value
          },
          summary: changedSummary, // Update the flight summary
        }
        : this.flightToBook; // If there are no changes in summary, use the original flight details

      // Invoke the callback function with the updated flight details and any additional arguments
      callback(this.props)(finalFlight, ...this.args);
    }

    /**
     * Handle phone call event.
     * @param {string} label - Label for the phone call event.
     */
    onPhoneCall(label) {
      if (label === Events.modal.noSeats.phoneCall.label) {
        trigger(this.props.getDomainName(), Events.modal.noSeats.phoneCall);
        return;
      }
      trigger(this.props.getDomainName(), Events.modal.priceChange.phoneCall);
    }

    render() {
      const { ...props } = this.props;
      const { showNoSeatsModal, showPriceChangedModal, verifyingFlight, isSubmitButtonClicked } = this.state;
      const previousPrice = this.flightToBook && this.flightToBook.price.total;
      const { phone } = this.props.phoneData;
      return (
        <React.Fragment>
          <WrappedComponent
            isSubmitButtonClicked={isSubmitButtonClicked}
            verifyBooking={this.handleVerifyBooking}
            verifyingFlight={verifyingFlight}
            {...props}
          />
          <NoSeatsModal
            isOpen={showNoSeatsModal && this.props.noSeatsModalStatus}
            onClose={this.handleClose}
            onPhoneCall={() => this.onPhoneCall(Events.modal.noSeats.phoneCall.label)}
            tel={formatPhone(phone, 'RFC3966')}
            phoneNumber={phone}
          />
          <PriceChangedModal
            isOpen={showPriceChangedModal && this.props.priceChangedModalStatus}
            price={get(this, 'verifyFlight.summary.total.total')}
            previousPrice={previousPrice}
            onContinueBooking={this.handleContinueBooking}
            onPhoneCall={() => this.onPhoneCall(Events.modal.priceChange.phoneCall.label)}
            onClose={this.handleClose}
            tel={formatPhone(phone, 'RFC3966')}
            phoneNumber={phone}
          />
        </React.Fragment>
      );
    }
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'WithVerifyFlightBooking';

  WithVerifyFlightBooking.displayName = `withVerifyFlightBooking(${wrappedComponentName})`;

  return connect(
    state => ({
      phoneDataStore: getPhoneData(state),
      priceChangedModalStatus: getPriceChangedModalStatus(state),
      noSeatsModalStatus: getNoSeatsModalStatus(state),
    }),
    { saveVerifyBookingData, toggleNoSeatsModal, togglePriceChangedModal, savePhoneData },
  )(WithVerifyFlightBooking);
};

export default withVerifyFlightBooking;
