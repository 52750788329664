// @flow
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import moment from 'moment';
import React from 'react';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { DesktopLarge } from 'travelopod-components';

import { getMessages } from '../../api/modules/marketingMessages';
import { CAMPAIGN } from '../../constants/global';
import MessageList from './MarketingMessagesList';

type Props = {
  children: React.Node,
  showedMessages?: Array,
  addMessages?: Function,
  hideMessage?: Function,
  clearMessages?: Function,
};

const MESSAGE_TIMEOUT_APPEAR = 4000;
const WAIT = 500;

export const MarketingMessagesContext = React.createContext({
  loadMessages: noop,
  loadMessagesDebounced: noop,
});

const timestampToDate = timestamp => moment.unix(timestamp).format('YYYYMMDD');

class MarketingMessages extends React.Component<Props> {
  get campaign() {
    return CAMPAIGN.TRAVELOPOD_B;
  }

  handleLoadMessages = (filters, minFare) => this.doLoadMessages(filters, minFare);

  handleLoadMessagesDebounced = debounce(this.handleLoadMessages, WAIT);

  async doLoadMessages(filters, minFare) {
    const { clearMessages, addMessages } = this.props;

    clearMessages();

    const query = {
      origin: filters.from,
      destination: filters.to,
      departDate: timestampToDate(filters.startDate),
    };

    if (filters.endDate) {
      query.returnDate = timestampToDate(filters.endDate);
    }

    const messages = await getMessages(query, this.campaign, minFare);

    addMessages(messages);
  }

  render() {
    const { children, showedMessages, hideMessage } = this.props;

    return (
      <React.Fragment>
        <DesktopLarge>
          <MessageList messages={showedMessages} hideMessage={hideMessage} />
        </DesktopLarge>
        <MarketingMessagesContext.Provider
          value={{ loadMessages: this.handleLoadMessages, loadMessagesDebounced: this.handleLoadMessagesDebounced }}
        >
          {children}
        </MarketingMessagesContext.Provider>
      </React.Fragment>
    );
  }
}

export default compose(
  withStateHandlers(
    () => ({
      messages: [],
      showedMessages: [],
    }),
    {
      hideMessage: ({ showedMessages }) => (e, { message }) => ({
        showedMessages: showedMessages.filter(({ id }) => message.id !== id),
      }),
      showNextMessage: ({ messages, showedMessages }) => () => {
        if (isEmpty(messages)) {
          return;
        }

        const nextMessages = [...messages];
        const nextMessage = nextMessages.shift();

        return {
          messages: nextMessages,
          showedMessages: [...showedMessages, nextMessage],
        };
      },
      addMessages: () => messages => ({
        messages,
      }),
      clearMessages: () => () => ({
        messages: [],
        showedMessages: [],
      }),
    },
  ),
  lifecycle({
    componentDidUpdate() {
      const { messages, showedMessages, showNextMessage } = this.props;

      // show messages
      if (!isEmpty(messages)) {
        // eslint-disable-next-line no-unused-expressions
        isEmpty(showedMessages) ? showNextMessage() : setTimeout(showNextMessage, MESSAGE_TIMEOUT_APPEAR);
      }
    },
  }),
)(MarketingMessages);
