// @flow
import './Layout.css';

import React from 'react';
import {
  AccreditedBusinessBanner,
  AirlinesLoader,
  DesktopLarge,
  FlexBox,
  Indent,
  Mobile,
  Modal,
  Tablet,
} from 'travelopod-components';

import SearchPanelMobile from '../SearchPanelMobile';
import Aside from '../../../../components/Aside/Aside';
import HelpBanner from '../../../../components/Banners/HelpBanner';
import FixedWidthContainer from '../../../../components/FixedWidthContainer/FixedWidthContainer';

const FlightLoyout = ({
  children,
  onClick,
  isOpen,
  onClose,
  onOpen,
  calendarResponse,
  calendarStashResponse,
  searchFlightsResponse,
  isMKTSource,
}) => {
  const { mkt_unknown } = isMKTSource();
  const [filters, calendarFilters, content, filtersButton] = children;
  const CalendarFilters = React.cloneElement(calendarFilters, {
    ...calendarFilters.props,
    handleSearch: () => {
      onClose();
    },
  });

  const loadingText =
    (calendarStashResponse && calendarStashResponse.pending) ||
    (calendarResponse && calendarResponse.pending) ||
    (searchFlightsResponse && searchFlightsResponse.pending);

  return (
    <React.Fragment>
      {/* DESKTOP */}
      <DesktopLarge>
        {CalendarFilters}

        {mkt_unknown && (
          <FlexBox
            className="flights-currency-warning"
            backgroundColor="link"
            pa="l r b t"
            alignItems="center"
            justifyContent="center"
          >
            All prices are indicated in United States Dollar
          </FlexBox>
        )}

        <FlexBox>
          <Indent className="flights-content-side-block" />

          <FixedWidthContainer className="flights-content-container">
            <FlexBox justifyContent="center" className="content-with-asides">
              {/* LEFT FILTER */}
              <FlexBox className="aside-left" justifyContent="flex-end">
                {filters}
              </FlexBox>

              {/* FLIGHT LIST */}
              <Indent className="flights-content" pa="h-double">
                {content}
              </Indent>

              {/* RIGHT SIDE COMP */}
              <div className="aside-right">
                <Aside asideRoot={React.Fragment} isSmallAsideWidth={true} />
              </div>
            </FlexBox>
          </FixedWidthContainer>

          <Indent className="flights-content-side-block" />
        </FlexBox>
      </DesktopLarge>

      {/* TABLET */}
      <Tablet>
        {filters}

        <SearchPanelMobile
          responses={[searchFlightsResponse, calendarResponse]}
          onClick={onClick}
          filtersButton={filtersButton}
          onOpen={onOpen}
        />

        {mkt_unknown && (
          <FlexBox
            className="flights-currency-warning"
            backgroundColor="link"
            pa="l r b t"
            alignItems="center"
            justifyContent="center"
          >
            All prices are indicated in United States Dollar
          </FlexBox>
        )}

        <Indent ma="t-double">{content}</Indent>

        <HelpBanner />

        <Indent ma="t-large">
          <AccreditedBusinessBanner />
        </Indent>

        <Modal
          overlayClassName="modal-search-overlay"
          isOpen={isOpen}
          fluid
          noPadding
          noBorder
          pushUp
          showCloseIcon={false}
          scrollable={false}
          onClose={onClose}
        >
          {CalendarFilters}
        </Modal>
      </Tablet>

      {/* MOBILE */}
      <Mobile>
        {filters}

        <SearchPanelMobile
          responses={[searchFlightsResponse, calendarResponse]}
          onClick={onClick}
          filtersButton={filtersButton}
          onOpen={onOpen}
        />

        {mkt_unknown && (
          <FlexBox
            className="flights-currency-warning"
            backgroundColor="link"
            pa="l r b t"
            alignItems="center"
            justifyContent="center"
          >
            All prices are indicated in United States Dollar
          </FlexBox>
        )}

        {loadingText && <AirlinesLoader />}

        <Indent className="flights-content-mobile" ma="t-double">
          {content}
        </Indent>

        <HelpBanner />

        <Modal
          overlayClassName="modal-search-overlay"
          isOpen={isOpen}
          fluid
          noPadding
          noBorder
          pushUp
          showCloseIcon={false}
          scrollable={false}
          onClose={onClose}
        >
          {CalendarFilters}
        </Modal>
      </Mobile>
    </React.Fragment>
  );
};

export default FlightLoyout;
