import Config from '../../constants/config';
import { catchServerError } from '../../Sentry';

export const getBannerInfo = async () => {
  const url = `${Config.baseURL}/api/banner/list`;
  let response = null;
  try {
    response = await fetch(url);
    const messages = await response.json();

    catchServerError(null, {
      response,
      request: { url },
    });

    return messages;
  } catch (error) {
    catchServerError(error, {
      response,
      request: { url },
    });
  }

  return [];
};
