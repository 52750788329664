export const GET_LIST_OF_AIRPORTS: 'priceCalendar/GET_LIST_OF_AIRPORTS' = 'priceCalendar/GET_LIST_OF_AIRPORTS';
export const GET_LIST_OF_AIRPORTS_SUCCESS: 'payment/GET_LIST_OF_AIRPORTS_SUCCESS' =
  'priceCalendar/GET_LIST_OF_AIRPORTS_SUCCESS';
export const GET_LIST_OF_AIRPORTS_FAILURE: 'payment/GET_LIST_OF_AIRPORTS_FAILURE' =
  'priceCalendar/GET_LIST_OF_AIRPORTS_FAILURE';

type GetListOfAirports = {
  type: typeof GET_LIST_OF_AIRPORTS,
};

export type GetListOfAirportsSuccess = {
  type: typeof GET_LIST_OF_AIRPORTS_SUCCESS,
};
type GetListOfAirportsFailure = {
  type: typeof GET_LIST_OF_AIRPORTS_FAILURE,
  error: string,
};

type ActionType = GetListOfAirports | GetListOfAirportsSuccess | GetListOfAirportsFailure;

export type State = {
  loading: boolean,
  error: ?string,
  loaded: boolean,
};

const initialState: State = {
  loading: false,
  error: null,
  loaded: false,
};

export default function getListOfAirportsReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case GET_LIST_OF_AIRPORTS:
      return {
        ...initialState,
        loading: true,
      };

    case GET_LIST_OF_AIRPORTS_SUCCESS:
      return {
        ...initialState,
        loaded: true,
        data: action.result,
      };

    case GET_LIST_OF_AIRPORTS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function getListOfAirports(data: any) {
  return {
    type: GET_LIST_OF_AIRPORTS,
    data,
  };
}
