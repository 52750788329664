// @flow
import axios from 'axios';

import { AUTH_TOKEN } from '../constants/config';
import payment from './modules/payment';
import phoneNumber from './modules/phoneNumber';
import priceCalendar from './modules/priceCalendar';

export type ReqTypeT = (config: Object) => Promise<*>;

class Client {
  token: ?string;
  req: ReqTypeT;
  // modules
  payment: *;
  priceCalendar: *;
  phoneNumber: *;

  constructor(baseURL: ?string = '') {
    this.req = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      baseURL,
    });

    this.req.interceptors.request.use(config => {
      if (!this.token) {
        const token = localStorage.getItem(AUTH_TOKEN);
        if (token) {
          config.headers = Object.assign({}, config.headers, {
            'Auth-Token': token,
          });
        }
        return config;
      }

      config.headers = Object.assign({}, config.headers, {
        'Access-Token': this.token,
      });
      return config;
    });

    this.payment = payment(this.req);
    this.phoneNumber = phoneNumber(this.req);
    this.priceCalendar = priceCalendar(this.req);
  }

  setToken(token: string): void {
    this.token = token;
  }
}

let instance;
export default function api(baseUrl: ?string) {
  if (!instance) {
    instance = new Client(baseUrl);
  }

  return instance;
}
