import React from 'react';
import { Navigate } from 'react-router';
import withRouter from '../../Hocs/wihRouter';

const Redirect = (props) => {
  let { params, to } = props;
  const { compain } = params;

  if (to.pathname) {
    to.pathname = compain ? `/${compain}${props.to.pathname}` : props.to.pathname;
  } else {
    to = compain ? `/${compain}${props.to}` : props.to;
  }

  return <Navigate {...props} to={to} />;
};
export default withRouter(Redirect);
