// @flow
import { catchServerError } from '../../Sentry';
import config, { API_AURIC_URL } from '../../constants/config';
import API from '../base/api';
import type { ReqTypeT } from '../index';

export type PaymentT = {
  data: {
    amount: number,
    billingCard: {
      cardToken: string,
      cardType: string,
      cardholderName: string,
      expDate: number,
      id: number,
      last4: string,
    },
    enquiryId: number,
    id: number,
    paymentLink: string,
    pnr: {
      id: number,
      itineraryDetail: string,
    },
    status: string,
    tokenizationComponent: {
      id: number,
      sessionId: string,
    },
    type: string,
    receipt: Object,
  },
};

class UserApi extends API {
  async createPayment(data: PaymentT) {
    const url = `${config.baseURL}/api/payments-confirmation?token=${data}`;
    return this.r({
      method: 'GET',
      url,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url },
      });
    });
  }

  async confirmPayment(data) {
    const url = `${config.baseURL}/api/payments-confirmation`;
    return this.r({
      method: 'POST',
      url: `${config.baseURL}/api/payments-confirmation`,
      data,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url, body: data },
      });
    });
  }

  async getCardToken(data) {
    const url = API_AURIC_URL;
    return this.r({
      method: 'POST',
      url,
      data,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url, body: data },
      });
    });
  }

  // vault and tokenize card details with PCI Vault
  async vaultCardAndGetToken(data) {
    const url = `${config.baseURL}/api/pcivaulting`;
    return this.r({
      url,
      method: 'POST',
      data: JSON.stringify(data.card),
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url, body: data.card },
      });
    });
  }

  async getBillingCountries() {
    const url = `${config.baseURL}/api/billing-countries`;
    return this.r({
      method: 'GET',
      url,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url },
      });
    });
  }

  async getReceipt(data) {
    const url = `${config.baseURL}/api/payments-confirmation/receipt?token=${data}`;
    return this.r({
      method: 'GET',
      url,
    }).catch((err) => {
      catchServerError(err, {
        response: err.response,
        request: { url },
      });
    });
  }
}

export default function userApi(request: ReqTypeT) {
  return new UserApi(request);
}
