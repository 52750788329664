import range from 'lodash/range';
import queryString from 'query-string';

import { PassengerType } from '../constants/passengers';

const createPassengers = (passenger, passengerType) => {
  if (passenger) {
    return range(passenger.count).map(() => ({
      passengerType,
    }));
  }

  return [];
};

const passengerGender = ({ gender, passengerType }) => {
  if (gender && passengerType === PassengerType.ADULT.name) {
    return gender === 'MALE' ? 'Mr. ' : 'Ms. ';
  }

  return '';
};

export const getPassengersFromPriceSummary = priceSummary => {
  if (!priceSummary) {
    return [];
  }

  const { adults, children, infants } = priceSummary;

  const adultPassengers = createPassengers(adults, PassengerType.ADULT.name);
  const childPassengers = createPassengers(children, PassengerType.CHILD.name);
  const infantPassengers = createPassengers(infants, PassengerType.INFANT.name);

  return [...adultPassengers, ...childPassengers, ...infantPassengers];
};

export const getFullname = person => {
  const { gender, passengerType, type } = person;

  if (!person) {
    return '';
  }

  return `${passengerGender({ gender, passengerType: passengerType || type })}${person.firstName} ${person.middleName ||
    ''} ${person.lastName}`;
};

export const getBaggageFeeUrl = airlineCode =>
  `https://bags.amadeus.com/Display.aspx?${queryString.stringify({ a: airlineCode })}`;
