import './Trustpilot.css';

import React from 'react';
import { defaultProps } from 'recompose';

import Trustpilot from './Trustpilot';

const TrustpilotCarousel = props => (
  <div className="trustpilot-wrapper">
    <div className="trustpilot-carousel" />
    <Trustpilot {...props} />
  </div>
);

export default defaultProps({
  'data-template-id': '53aa8912dec7e10d38f59f36',
  'data-style-height': '130px',
})(TrustpilotCarousel);
