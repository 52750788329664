import './Logo.css';

import React from 'react';
import { compose } from 'recompose';
import { Logo as LogoImage } from 'travelopod-components';

import withCallTrackingUrl from '../../../Hocs/withCallTrackingUrl';

const Logo = ({ buildMarketingUrl }) => {
  const handleClick = () => {
    if (sessionStorage.getItem('O&D-report-hit')) {
      sessionStorage.removeItem('O&D-report-hit');
    }
  };
  return (
    <div className="logo-container" onClick={handleClick}>
      <a href={buildMarketingUrl()}>
        <LogoImage className="logo" />
      </a>
    </div>
  );
};

export default compose(withCallTrackingUrl())(Logo);
