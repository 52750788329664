import React from 'react';
import { DesktopLarge, Mobile, Tablet } from 'travelopod-components';

export default function withResolution(LargeDesktopComponent, TabletComponent, MobileComponent) {
  return Component => {
    return props => (
      <React.Fragment>
        <DesktopLarge>
          <LargeDesktopComponent {...props}>
            <Component {...props} />
          </LargeDesktopComponent>
        </DesktopLarge>
        <Tablet>
          <TabletComponent {...props}>
            <Component {...props} />
          </TabletComponent>
        </Tablet>
        <Mobile>
          <MobileComponent {...props}>
            <Component {...props} />
          </MobileComponent>
        </Mobile>
      </React.Fragment>
    );
  };
}
