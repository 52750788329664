export const TRIP_TYPES = [
  { value: 'RT', label: 'Round trip' },
  { value: 'OW', label: 'One Way' },
  { value: 'ML', label: 'Multi City' },
];

export const SERVICE_CLASSES = [
  { value: 'ECONOMY', label: 'Economy' },
  { value: 'PREMIUM_ECONOMY', label: 'Premium Economy' },
  { value: 'BUSINESS', label: 'Business' },
  { value: 'FIRST', label: 'First' },
];

export const PASSENGERS_OPTIONS = [
  {
    passengerType: 'Adults',
    note: '',
    minValue: 1,
    countField: 'adultsQuantity',
  },
  {
    passengerType: 'Children',
    note: 'Aged 2-12',
    minValue: 0,
    countField: 'childrenQuantity',
  },
  {
    passengerType: 'Infants',
    note: 'On lap',
    minValue: 0,
    countField: 'infantsQuantity',
  },
];

export const MAX_PASSENGER_AMOUNT_OF_EACH_TYPE = 9;

export const DEFAULT_PASSENGERS_AMOUNT = {
  adultsQuantity: 1,
  childrenQuantity: 0,
  infantsQuantity: 0,
};

export const DEFAULT_FROM_DIRECTION = 'New Delhi DEL';

export const RT_DAYS_DIFF = {
  startDateDiff: 3,
  lastDateDiff: 6,
  measureOfCalculus: 'd',
};

export const MAX_PRICE_CALENDAR_CLASS = 'most-expensive-price';
export const MIN_PRICE_CALENDAR_CLASS = 'cheapest-price';

export const FORMAT_FOR_CHANGING_THE_DATE = 'MMM Do YY';
