export const GET_BILLING_COUNTRIES: string = 'payment/GET_BILLING_COUNTRIES';
export const GET_BILLING_COUNTRIES_SUCCESS: string = 'payment/GET_BILLING_COUNTRIES_SUCCESS';
export const GET_BILLING_COUNTRIES_FAILURE: string = 'payment/GET_BILLING_COUNTRIES_FAILURE';

type GetBillingCountries = {
  type: typeof GET_BILLING_COUNTRIES,
};
export type GetBillingCountriesSuccess = {
  type: typeof GET_BILLING_COUNTRIES_SUCCESS,
};
type GetBillingCountriesFailure = {
  type: typeof GET_BILLING_COUNTRIES_FAILURE,
  error: string,
};
type States = {
  id: number,
  name: string,
  code: string,
};
type Country = {
  id: number,
  name: string,
  billingStates: States[],
};
type Countries = Country[];

type ActionType = GetBillingCountries | GetBillingCountriesSuccess | GetBillingCountriesFailure;

export type State = {
  isLoading: boolean,
  error: ?string,
  data: Countries[],
};

const initialState: State = {
  isLoading: false,
  error: null,
  data: [],
};

export default function GetBillingCountriesReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case GET_BILLING_COUNTRIES:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_BILLING_COUNTRIES_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        isLoading: false,
      };

    case GET_BILLING_COUNTRIES_FAILURE:
      return {
        ...initialState,
        error: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
}

export function getBillingCountries() {
  return {
    type: GET_BILLING_COUNTRIES,
  };
}
