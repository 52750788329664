// @flow
import './MarketingMessagesList.css';

import React from 'react';
import { MarketingMessage } from 'travelopod-components';

type Props = {
  messages: Array,
  hideMessage: Function,
};

const MarketingMessagesList = ({ messages, hideMessage }: Props) => (
  <div className="message-list">
    {messages.map(message => (
      <MarketingMessage key={message.id} message={message} onClose={hideMessage} />
    ))}
  </div>
);

export default MarketingMessagesList;
