import './LabeledInfo.css';

import React from 'react';

import Indent from '../Indent/Indent';

const LabeledInfo = ({ label, info, className }) => (
  <Indent as="div" padding="none" margin="marginB">
    <div className="info-row">
      <p className="label">{label}:</p>
      <p className={className}>{info}</p>
    </div>
  </Indent>
);

export default LabeledInfo;
