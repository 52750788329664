import classNames from 'classnames';
import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { compose } from 'recompose';

import { Events } from '../../constants/googleAnalyticsEvents';
import { markAsOpened } from '../../helpers/exitIntent';
import { trigger } from '../../helpers/GoogleAnalytics';
import { formatPhone } from '../../helpers/phone';
import withDomain from '../../Hocs/withDomain';
import { getPhoneDataSelector } from '../../store/modules/responseTap/phoneData';

type Props = {
  phoneData: Object,
};

class ResponseTapPhone extends React.PureComponent<Props> {
  componentDidUpdate(prevProps, prevState) {
    if (!this.rTapNumberRef) {
      return;
    }

    if (prevProps.phoneData === this.props.phoneData) {
      return;
    }

    const { phone } = this.props.phoneData;

    this.href.setAttribute('href', formatPhone(phone, 'RFC3966'));
    this.href.innerHTML = phone;
  }

  rTapRef = (node) => {
    if (!node) {
      return this.interval && clearInterval(this.interval);
    }

    const { className, phoneData } = this.props;
    const a = document.createElement('a');
    const href = formatPhone(phoneData.phone, 'RFC3966');
    const linkClassName = classNames(className, href, 'underline');
    a.classList.add(...linkClassName.split(' '));
    a.setAttribute('href', href);

    a.innerHTML = phoneData.phone;

    node.appendChild(a);

    this.href = a;
    this.rTapNumberRef = node;
  };

  onClick() {
    trigger(this.props.getDomainName(), Events.click[this.props.renderFrom]);
    markAsOpened();
  }

  render() {
    return (
      <span
        ref={this.rTapRef}
        onClick={() => {
          this.onClick();
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  phoneData: getPhoneDataSelector(state),
});

export default compose(withDomain(), reduxConnect(mapStateToProps))(ResponseTapPhone);
