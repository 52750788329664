export default {
  CONTACT_PHONE_MAP: {
    N_AMERICA: {
      phone: '18005146072',
      rTap: '328538',
    },
    EUROPE: {
      phone: '18005705601',
      rTap: '328532',
    },
    INDIA: {
      phone: '18002774517',
      rTap: '325581',
    },
    ASIA: {
      phone: '18005833093',
      rTap: '328531',
    },
    SE_ASIA: {
      phone: '18005833093',
      rTap: '328531',
    },
    S_AMERICA: {
      phone: '18004216410',
      rTap: '328535',
    },
    AUSTRALIA: {
      phone: '16469330913',
      rTap: '331241',
    },
  },
  DEFAULT_DESKTOP_PHONE: {
    phone: '18002774517',
    rTap: '325581',
  },
  DEFAULT_MOBILE_PHONE: {
    phone: '18002774517',
    rTap: '325581',
  },
  MARKETS: ['N_AMERICA', 'EUROPE', 'INDIA', 'ASIA', 'S_AMERICA', 'AUSTRALIA'],
  DEFAULT_MARKET: 'ASIA',
  CONTINENT_MAP: {
    IN: 'INDIA',
  },
  DEFAULT_PHONE: '18444555444',
  BOOKING_PHONE: '18777006921',
  BOOKING_FAILED_PHONE: '18005438038',
};
