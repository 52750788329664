import './GuaranteedSafeAndSecureBanner.css';

import React from 'react';

import i18n from '../../../locales/i18next';
import Container from '../../Container';
import images from './images';

const GuaranteedSafeAndSecureBanner = () => (
  <Container className="small">
    <h4>{i18n.t('components.aside.guaranteedSafeAndSecureBanner.header')}</h4>
    <img
      src={images.norton}
      className="secure-images"
      alt={i18n.t('components.aside.guaranteedSafeAndSecureBanner.nortonAlt')}
    />
    <img
      src={images.mcAfee}
      className="secure-images second-image"
      alt={i18n.t('components.aside.guaranteedSafeAndSecureBanner.mcAfeeAlt')}
    />
  </Container>
);

export default GuaranteedSafeAndSecureBanner;
