// @flow
import { put, takeEvery } from 'redux-saga/effects';

import api from '../../api';
import {
  GET_LIST_OF_AIRPORTS,
  GET_LIST_OF_AIRPORTS_FAILURE,
  GET_LIST_OF_AIRPORTS_SUCCESS,
} from '../modules/priceCalendar/searchAirports';
import {
  SEARCH_PRICE_CALENDAR,
  SEARCH_PRICE_CALENDAR_FAILURE,
  SEARCH_PRICE_CALENDAR_SUCCESS,
} from '../modules/priceCalendar/searchCalendar';

function* searchPriceCalendar({ data }) {
  try {
    const response = yield api().priceCalendar.searchCalendarPrices(data);
    const result = response.data.data;
    yield put({ type: SEARCH_PRICE_CALENDAR_SUCCESS, result });
  } catch (err) {
    const error = err.response.data.error;
    yield put({ type: SEARCH_PRICE_CALENDAR_FAILURE, error });
  }
}

function* getListOfAirports({ data }) {
  try {
    const response = yield api().priceCalendar.getListOfAirports(data);
    const result = response.data.data;
    yield put({ type: GET_LIST_OF_AIRPORTS_SUCCESS, result, search: data });
  } catch (err) {
    const error = err.response.data.error;
    yield put({ type: GET_LIST_OF_AIRPORTS_FAILURE, error });
  }
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(SEARCH_PRICE_CALENDAR, searchPriceCalendar);
  yield takeEvery(GET_LIST_OF_AIRPORTS, getListOfAirports);
}
