import queryString from 'query-string';
import withRouter from './wihRouter';
import { compose, withHandlers, withProps } from 'recompose';

import withCallTrackingUrl from './withCallTrackingUrl';

let serializedUrlParams = null;
const defaultFilter = ['utm_device', 'utm_medium', 'utm_source', 'message_id', 'mobile'];

export const getUrlParams = (data) => {
  const { filter } = data || {};
  let _filter = defaultFilter;
  if (filter) {
    if (filter.type === 'only') {
      _filter = filter.data;
    } else if (filter.type === 'all') {
      _filter = [...filter.data, ...defaultFilter];
    }
  }
  if (typeof window !== 'object') {
    return true;
  }

  serializedUrlParams = {};
  window.location.search
    .replace('?', '')
    .split('&')
    .forEach((el) => {
      const a = el.split('=');
      if (a.length === 2 && _filter.includes(a[0])) {
        serializedUrlParams[a[0]] = a[1];
      }
    });
  return serializedUrlParams;
};

const validatePassengers = (values) => {
  if (values.adults == 0) {
    values.adults = '1';
  }
  return values;
};

export const fromUrlToValue = (url, defaultFilters) => {
  return validatePassengers({ ...defaultFilters, ...queryString.parse(url, { arrayFormat: 'bracket' }) });
};

export const fromValueToUrl = (values, value, defaultFilters) => {
  return queryString.stringify({ ...defaultFilters, ...values, ...value }, { arrayFormat: 'bracket' });
};

export default (mapper) =>
  compose(
    withRouter,
    withCallTrackingUrl(),
    withProps((props) => ({
      queryFilters: fromUrlToValue(`${props.location.search}`, mapper ? mapper(props) : {}),
    })),
    withHandlers({
      updateFilters: (props) => (nextFilters) => {
        const defaultFilters = mapper ? mapper(props) : {};
        const currentQueryString = queryString.extract(props.location.search);
        const nextQueryString = fromValueToUrl(
          props.queryFilters,
          nextFilters,
          props.parseValuesToValues(defaultFilters),
        );

        if (currentQueryString !== nextQueryString) {
          props.navigate({
            pathname: props.location.url,
            search: nextQueryString,
          });
        }
      },
      upsertFilters: (props) => (nextFilters) => {
        const defaultFilters = mapper ? mapper(props) : {};
        const currentQueryString = queryString.extract(props.location.search);
        const nextQueryString = fromValueToUrl(nextFilters, {}, props.parseValuesToValues(defaultFilters));

        if (currentQueryString !== nextQueryString) {
          props.navigate({
            pathname: props.location.url,
            search: nextQueryString,
          });
        }
      },
    }),
  );
