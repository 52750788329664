import './CallUs.css';

import React from 'react';
import { Desktop, Mobile, PhoneOrangeIcon, Text, withTheme } from 'travelopod-components';

import i18n from '../../../locales/i18next';
import FlexBox from '../../FlexBox';
import ResponseTapPhone from '../../ResponseTap/ResponseTapPhone';

const CallUs = ({ theme }) => {
  if (theme.mode === 'flykart') {
    return <div className="placeholder" />;
  }

  return (
    <React.Fragment>
      <Desktop>
        <FlexBox alignItems="center" className="response-tap-header">
          <Text className="phone-text" secondary size="big">
            {i18n.t('components.header.callUs')}
          </Text>
          <Text
            className="phone"
            as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberHeader" />}
            title={i18n.t('components.header.phone')}
            color="orange"
            size="huge"
            weight="bold"
          />
        </FlexBox>
      </Desktop>
      <Mobile>
        <FlexBox justifyContent="flex-end">
          <Text ma="r" color="orange" weight="bold" transform="uppercase" align="right">
            Call us <br />
            24/7
          </Text>
          <Text
            className="phone"
            as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberHeader" />}
            title={i18n.t('components.header.phone')}
            color="orange"
            size="huge"
            weight="bold"
          />
          <PhoneOrangeIcon className="tada2d" />
        </FlexBox>
      </Mobile>
    </React.Fragment>
  );
};

export default withTheme(CallUs);
