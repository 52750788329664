// @flow
// import api from '../../../api';
// import { API_REQUEST } from '../../apiAction';
// import type { ApiRequestT } from '../../apiAction';
import UserT from '../../../api/modules/payment';

export const CREATE_PAYMENT: 'payment/CREATE_PAYMENT' = 'payment/CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS: 'payment/CREATE_PAYMENT_SUCCESS' = 'payment/CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE: 'payment/CREATE_PAYMENT_FAILURE' = 'payment/CREATE_PAYMENT_FAILURE';

type CreatePayment = {
  type: typeof CREATE_PAYMENT,
};
export type CreatePaymentSuccess = {
  type: typeof CREATE_PAYMENT_SUCCESS,
  result: {
    data: UserT,
  },
};
type CreatePaymentFailure = {
  type: typeof CREATE_PAYMENT_FAILURE,
  error: string,
};

type ActionType = CreatePayment | CreatePaymentSuccess | CreatePaymentFailure;

export type State = {
  loading: boolean,
  error: ?string,
  loaded: boolean,
};

const initialState: State = {
  loading: false,
  error: null,
  loaded: false,
};

export default function paymentCreateReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case CREATE_PAYMENT:
      return {
        ...initialState,
        loading: true,
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...initialState,
        loaded: true,
      };

    case CREATE_PAYMENT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function createPayment(data: CreatePaymentSuccess) {
  return {
    type: CREATE_PAYMENT,
    data,
  };
}
