// @flow
export default {
  translation: {
    pages: {
      homePage: {
        title: 'Home page',
      },
    },
    components: {
      header: {
        callUs: 'Call us 24/7',
        phone: '1-800-475-1072',
      },
      aside: {
        guaranteedSafeAndSecureBanner: {
          header: 'Guaranteed safe & secure checkout',
          nortonAlt: 'Norton',
          mcAfeeAlt: 'McAfee',
        },
      },
      paymentConfirmation: {
        itineraryDetails: 'Itinerary Details',
        contactDetails: 'Contact Details',
        emailPlaceholder: 'Email *',
        emailErrorValid: 'Please enter a valid email address',
        emailErrorRequired: 'Email is required',
        enterBillingInformation: 'Enter Billing Information',
        cardDetails: 'Card Details:',
        cardNumberPlaceholder: 'Card Number *',
        cardNumberErrorValid: 'Please enter a valid card number',
        cardNumberErrorRequired: 'Card number is required',
        cardHolderName: 'Card Holder Name',
        cardHolderNameErrorValid: 'Please enter a valid holder name',
        cardHolderNameErrorRequired: 'Holder name is required',
        cardMonthPlaceholder: 'Month *',
        cardMonthErrorRequired: 'Month is required',
        cardYearPlaceholder: 'Year *',
        cardYearErrorRequired: 'Year is required',
        cardCvvPlaceholder: 'CVV *',
        cardCvvErrorValid: 'Please enter a valid CVV',
        cardCvvErrorRequired: 'CVV is required',
        billingAddress: 'Billing Address:',
        addressPlaceholder: 'Address *',
        addressErrorValid: 'Please enter a valid address',
        addressErrorRequired: 'Address is required',
        phonePlaceholder: 'Phone *',
        phoneErrorValid: 'Please enter a valid phone',
        phoneErrorRequired: 'Phone is required',
        postalPlaceholder: 'Postal / Zip Code*',
        postalErrorValid: 'Please enter a valid postal',
        postalErrorRequired: 'Postal is required',
        cityPlaceholder: 'City *',
        cityErrorValid: 'Please enter a valid city',
        cityErrorRequired: 'City is required',
        countryPlaceholder: 'Country *',
        countryErrorValid: 'Please enter a valid country',
        countryErrorRequired: 'Country is required',
        provincePlaceholder: 'State / Province *',
        provinceErrorValid: 'Please enter a valid province',
        provinceErrorRequired: 'Province is required',
        suggestions: 'Please specify your suggestions or special requirements',
        suggestionsPlaceholder: 'Suggestions',
        suggestionsErrorValid: 'Please enter a valid suggestions',
        suggestionsErrorRequired: '',
        review: 'Review and book your trip',
        reviewLine1: 'Review your trip details to make sure the dates and times are correct.',
        reviewLine2: 'Check spellings. Flight passenger names must exactly match government-issued photo ID.',
        reviewLine3:
          'Name changes are not permitted once tickets are issued. Tickets are non-transferable and non-refundable.',
        reviewLine4:
          'Total fares include all taxes and fees, except additional airline fees such as baggage & seat assignment may apply.',
        reviewLine5: 'Your credit card may be billed in multiple charges totaling the above amount.',
        reviewLine6: 'All other booking Rules & Restrictions - ',
        readMore: 'Read More',
        agreeErrorRequired: 'Сonfirmation is required',
        book: 'Book',
        iAgreeToPay: 'I agree to Pay $',
        referenceNo: 'Reference No: ',
        dueAmount: 'Due Amount: ',
        additionalServicesHeader: 'Additional Services',
        quoteHeader: 'Protect your trip & avoid change fees',
        quoteHeaderRecommendation: 'Recommended',
        quoteReasonsHeader: "You have all the reasons to buy protection if your ticket is non-refundable and you're:",
        quoteReasons: ['Too sick to travel', "Get sick/Injured on vacation injured and can't take your flight home"],
        quoteYesRadio: 'Yes, add Travel Insurance',
        quoteYesRadioNotification:
          'Travel Insurance is available for the United States only. Please enter a valid address for quoting insurance.',
        quoteNoRadio: 'No, thanks. I will deal with the risks by myself',
        stateZipYesRadio: 'Use State and Zip from Billing Details',
        stateZipNoRadio: 'No, specify new',
        stateZipYesRadioNotification: 'Please fill Billing details information',
        viewTermsAndConditions: 'View terms and conditions',
        quoteInfo: 'Insurance will be sent to your email within 48 hours of booking.',
        cancellationPolicyHeader: 'Hassle free 24 Hours Cancellation Policy ',
        cancellationPolicyDescription:
          'We understand plan might change. Our 24 hours Cancellation Policy allows you to cancel your flight hassle free for any reason with in 24 hours of your booking.',
        cancellationPolicyQuoteYesRadio: {
          firstPart: "Yes, I'd like to take advantage of the 24 Hours Cancellation Policy for ",
          secondPart: ' per person',
        },
      },
      helpBook: {
        whyBookWithUs: 'Why book with us?',
        needBookingHelp: 'Need booking help? ',
        neverMissOutDeal: 'Never miss out a deal',
        callUsTollFree: 'CALL US TOLL FREE',
        enterYourEmail: 'Enter your email',
      },
      successfulConfirmation: {
        confirmationForBooking: 'Payment Authorization Confirmation for Booking ',
        authorizingPartOne: 'Thank you for authorizing a one-time payment for',
        authorizingPartTwo: 'for booking ',
        amount: 'Amount: ',
        dateAndTime: 'Date and Time of Authorization: ',
        nameOnCreditCard: 'Name on Credit Card: ',
        creditCard: 'Credit Card: ',
        email: 'Email: ',
        phone: 'Phone: ',
        acknowledgedFromIp: 'Acknowledged from IP: ',
        paymentReferenceNumber: 'Payment Reference Number: ',
        questions: 'Questions: please call us at 1833-235-5400 or email us at support@travelopod.com',
        thankYouFor: 'Thank you for ',
        sincerely: 'Sincerely',
        travelopodOperationsTeam: 'Travelopod Operations Team',
        bestFaresAlways: 'Best Fares Always',
        termsConditions: 'Terms & Conditions',
        privacyPolicy: 'Privacy Policy',
        itineraryDetails: 'Itinerary Details',
        print: 'Print',
      },
    },
  },
};
