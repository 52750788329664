const BANNER = 'banner';

export function markAsClosed() {
  localStorage.setItem(BANNER, true);
}

export function wasClosed() {
  const bannerState = localStorage.getItem(BANNER);

  return bannerState && JSON.parse(bannerState);
}
