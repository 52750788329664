// @flow
import './FlightFilters.css';

import React from 'react';
import { AnimatedWrapper, InputSkeleton, SliderSkeleton } from 'travelopod-components';

type PropsT = {
  delay: number,
};

const DELAY = 0;

function FiltersSkeleton(props: PropsT) {
  return (
    <AnimatedWrapper delay={props.delay} className="flight-list-filters skeleton">
      <InputSkeleton />
      <SliderSkeleton />
      <SliderSkeleton />
      <InputSkeleton />
      <InputSkeleton />
      <InputSkeleton />
    </AnimatedWrapper>
  );
}

FiltersSkeleton.defaultProps = {
  delay: DELAY,
};

export default FiltersSkeleton;
