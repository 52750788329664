import './Indent.css';

import classNames from 'classnames';
import type { Element } from 'react';
import React from 'react';

type Padding = 'topP' | 'bottomP' | 'rightP' | 'leftP';
type Margin = 'topM' | 'bottomM' | 'rightM' | 'leftM';

export type Props = {
  as?: Element<any>,
  padding?: Padding,
  margin?: Margin,
};

const Indent = ({ as: As, className, padding, margin, ...props }: Props) => {
  const classes = classNames(className, 'indent', {
    [padding]: padding,
    [margin]: margin,
  });

  return <As className={classes} {...props} />;
};

Indent.defaultProps = {
  as: 'div',
};

export default Indent;
