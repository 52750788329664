import './index.css';

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { withForm } from 'travelopod-components';

const SubscriptionForm = (props) => {
  const { values, setFieldValue } = props;

  const handleSubscriptionChange = (e, { name, checked }) => {
    setFieldValue(name, checked);
  };

  return (
    <div className="subscription-container">
      {/* WHATSAPP CONSENT CHECKBOX */}
      <Checkbox
        label="By Clicking here, you agree to receive your travel updates & future deals on WhatsApp"
        name="whatsappSubscription"
        checked={values.whatsappSubscription}
        onChange={handleSubscriptionChange}
        inline
        className="subscription-text"
      />

      {/* SMS CONSENT CHECKBOX */}
      <Checkbox
        label="By Clicking here, you agree to receive your travel updates & future deals via SMS"
        name="smsSubscription"
        checked={values.smsSubscription}
        onChange={handleSubscriptionChange}
        inline
        className="subscription-text"
      />
    </div>
  );
};

export default withForm({
  mapPropsToValues: () => ({
    whatsappSubscription: true,
    smsSubscription: true,
  }),
  handleSubmit: (values, { props }) => props.onSubmit(values),
})(SubscriptionForm);
