import React from 'react';
import classNames from 'classnames';
import FlightCardList from '../FlightCardList/FlightCardList';
import SwissAirlinesLogo from '../../../../assets/images/swissAirlinesLogo.png';
import AirCanadaAirlinesLogo from '../../../../assets/images/airCanadaLogo.png';
import CathayAirlinesLogo from '../../../../assets/images/cathayPacificLogo.png';
import UnitedAirlinesLogo from '../../../../assets/images/unitedAirlinesLogo.png';
import EmiratesAirlinesLogo from '../../../../assets/images/emiratesAirlinesLogo.png';
import { AirlinesLoader, Desktop, Indent, PriceCalendar } from 'travelopod-components';

const airlineItems = [
  UnitedAirlinesLogo,
  AirCanadaAirlinesLogo,
  CathayAirlinesLogo,
  EmiratesAirlinesLogo,
  SwissAirlinesLogo,
];

const FlightPageContent = (props) => {
  const {
    loading,
    cityName,
    queryFilters,
    calendarData,
    onBookFlight,
    searchFlights,
    transactionId,
    onDatesChange,
    calendarLoading,
    calendarResponse,
    calendarStashResponse,
    searchFlightsResponse,
    showRefreshResultsModal,
  } = props;

  const loadingText =
    (calendarStashResponse && calendarStashResponse.pending) ||
    (calendarResponse && calendarResponse.pending) ||
    (searchFlightsResponse && searchFlightsResponse.pending);

  return (
    <React.Fragment>
      {loadingText && (
        <Desktop>
          <AirlinesLoader airlineItems={airlineItems} />
        </Desktop>
      )}

      <Indent
        ma="b"
        className={classNames('calendar-holder-mobile', {
          'loading-state': loadingText,
        })}
      >
        <PriceCalendar
          tripType={queryFilters.tripType}
          data={calendarData}
          startDate={queryFilters.startDate}
          endDate={queryFilters.endDate}
          loading={calendarLoading}
          onDatesChange={onDatesChange}
        />
      </Indent>

      {/* FLIGHTS LIST */}
      <FlightCardList
        loading={loading}
        searchFlightsResponse={searchFlightsResponse}
        transactionId={transactionId}
        handleBooking={onBookFlight}
        tripType={queryFilters.tripType}
        pending={searchFlightsResponse && searchFlightsResponse.pending}
        flight={searchFlightsResponse}
        code={queryFilters.to ? queryFilters.to : null}
        cityName={cityName}
        queryFilters={queryFilters}
        searchFlights={searchFlights}
        showRefreshResultsModal={showRefreshResultsModal}
      />
    </React.Fragment>
  );
};

export default FlightPageContent;
