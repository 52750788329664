import './index.css';

import React from 'react';
import { Header } from 'semantic-ui-react';

const NotFound = () => {
  return (
    <React.Fragment>
      <div className="not-found">
        <Header as="h1">404 - Page Not Found</Header>
        <Header as="h3">This page doesn't exist.</Header>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
