import React from 'react';

import WhiteArrowIcon from '../../assets/icons/white_arrow.svg';

const SuggestionBanner = ({ content, link, src, btnContent }) => (
  <div style={{ backgroundImage: `url(${src})` }} className="suggestion-banner">
    <h2 className="suggestion-banner-title">{content}</h2>
    <a className="banner-btn" href={link} target="_blank" rel="noopener noreferrer">
      {btnContent}
      <img alt="white arrow" src={WhiteArrowIcon} />
    </a>
  </div>
);

export default SuggestionBanner;
