import './BookingInformation.css';
import '../../../components/css/common.css';

import get from 'lodash/get';
import React, { Component } from 'react';
import { Divider, Text } from 'travelopod-components';
import Container from '../../../components/Container';
import Currency from '../../../components/Currency/Currency';
import BookedItineraryDetails from '../BookedItineraryDetails';
import CommonLinks from '../../../components/CommonLinks/CommonLinks';
import { paymentIsManualyImported } from '../../../helpers/lib/common';
import PaymentSummary from '../../../components/PaymentSummary/PaymentSummary';
import ConfirmationNumber from '../../../components/ConfirmationNumber/ConfirmationNumber';

class BookingConfirmation extends Component<Props> {
  render() {
    const { bookingData } = this.props;
    return (
      <Container className="large">
        <div className="booking-confirmation-header">
          <header>
            <h1>Booking Confirmation</h1>
          </header>
          <Text className="information-content">
            Your booking is complete! We will send your tickets to
            {bookingData.billingDetail && (
              <strong>
                &nbsp;
                {get(bookingData.billingDetail, 'billingDetailCustomer.email', '')}
                &nbsp;
              </strong>
            )}
            within 24 hours. Thank you for using Travelopod.
          </Text>
          <ConfirmationNumber pnr={bookingData.controlNumber} />
        </div>

        <BookedItineraryDetails bookingData={bookingData} />
        <PaymentSummary
          amount={
            <Currency
              currency={get(bookingData, 'enquiry.currency', 'USD')}
              amount={get(bookingData, 'billingDetail.amount')}
              as="span"
            />
          }
          authorizationTime={get(bookingData, 'billingDetail.updatedAt')}
          cardHolderName={get(bookingData, 'billingDetail.billingCard.cardholderName')}
          cardType={get(bookingData, 'billingDetail.billingCard.cardType')}
          cardNumber={get(bookingData, 'billingDetail.billingCard.last4')}
          phoneNumber={get(bookingData, 'billingDetail.billingDetailCustomer.phone')}
        />
        <Divider />
        <CommonLinks
          airlineCode={get(bookingData, 'pnrTrips[0].tripAirline.code')}
          showBaggageFeesLink={!paymentIsManualyImported(bookingData.importType)}
        />
      </Container>
    );
  }
}

export default BookingConfirmation;
