// @flow
import { CONFIRM_PAYMENT_SUCCESS } from './confirm';
import type { CreatePaymentSuccess } from './create';
import { CREATE_PAYMENT_SUCCESS } from './create';

type ActionTypeT = CreatePaymentSuccess;

const UPDATE_BILLING_DETAIL = 'payment/UPDATE_BILLING_DETAIL';

const initialState: PaymentT = {
  data: {
    amount: 0,
    billingCard: {
      cardToken: '',
      cardType: '',
      cardholderName: '',
      expDate: 0,
      id: 0,
      last4: '',
    },
    enquiryId: 0,
    id: 0,
    paymentLink: '',
    pnr: {
      id: 0,
      itineraryDetail: '',
    },
    status: '',
    tokenizationComponent: {
      id: 0,
      sessionId: '',
    },
    type: '',
  },
};

export default function userDataReducer(state: PaymentT = initialState, action: ActionTypeT): PaymentT {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
      return action.result;

    case CONFIRM_PAYMENT_SUCCESS:
      return {
        ...state,
        status: action.result.data.status,
      };

    case UPDATE_BILLING_DETAIL:
      return {
        ...state,
        billingDetail: {
          ...state.billingDetail,
          amount: action.data.amount,
        },
        enquiry: {
          ...state.enquiry,
          currency: action.data.currency,
        },
      };

    default:
      return state;
  }
}

export function updateBillingDetail(data) {
  return {
    type: UPDATE_BILLING_DETAIL,
    data,
  };
}
