import moment from 'moment';

const TRIP_DATE_FORMAT = 'ddd, MMM D';
const TRIP_TIME_FORMAT = 'HHmm';

export const pnrTripsToFlight = ({ pnrTrips }) => {
  if (!(pnrTrips && pnrTrips.length)) {
    return null;
  }

  const origin = pnrTrips[0].tripOriginAirport;
  const destination = pnrTrips[pnrTrips.length - 1].tripDestinationAirport;

  return {
    origin: {
      airportCode: origin.code,
      airportName: origin.name,
      city: origin.airportCity.name,
      countryCode: origin.airportCountry.code,
    },
    destination: {
      airportCode: destination.code,
      airportName: destination.name,
      city: destination.airportCity.name,
      countryCode: destination.airportCountry.code,
    },
    baggageInfo: pnrTrips[0].flightSegment[0],
    trips: pnrTrips.map(trip => {
      const departureDate = moment.unix(trip.departureDate).utc();
      const arrivalDate = moment.unix(trip.arrivalDate).utc();

      return {
        ...trip,
        departureDate: departureDate.format(TRIP_DATE_FORMAT),
        departureTime: departureDate.format(TRIP_TIME_FORMAT),
        arrivalDate: arrivalDate.format(TRIP_DATE_FORMAT),
        arrivalTime: arrivalDate.format(TRIP_TIME_FORMAT),
        flightSegment: trip.flightSegment.map(flightSegment => ({
          ...flightSegment,
          arrivalTime: moment
            .unix(flightSegment.arrivalDate)
            .utc()
            .format(TRIP_TIME_FORMAT),
          departureTime: moment
            .unix(flightSegment.departureDate)
            .utc()
            .format(TRIP_TIME_FORMAT),
          serviceClass: flightSegment.serviceClass && flightSegment.serviceClass.replace(/_/g, ' '),
          duration: flightSegment.travelTime || flightSegment.duration / 1000,
        })),
      };
    }),
  };
};
