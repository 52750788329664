export const CARD_TYPES = {
  Visa: 'VISA',
  Mastercard: 'MASTER_CARD',
  Discover: 'DISCOVER',
  'American Express': 'AMERICAN_EXPRESS',
};

export const AVAILABLE_ZIP_CODES_REG_EXPS = {
  1: '/^(?!0+$)/d{5}$/',
  2: '/^(?!0+$)[0-9A-Za-z]{6}$/',
  3: '/^(?!0+$)/d{6}$/',
};

export const PAYMENT_ERRORS = {
  'Billing status error': 'Payment has already been received',
  'jwt expired': 'Payment has already been received',
  global: 'Something went wrong. Please refresh the page and try again.',
};

export const DEFAULT_PAYMENT_ERR_MSG = 'Invalid token';

export const PASSENGER_NOT_NECESSARY_INFO = [
  {
    name: 'Passport Number',
    field: 'passportNumber',
  },
  {
    name: 'Redress Number',
    field: 'redressNumber',
  },
  {
    name: 'Meal Preference',
    field: 'mealRequest',
  },
  {
    name: 'Seat Preference',
    field: 'seatRequest',
  },
];
