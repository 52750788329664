export const CONFIRM_PAYMENT: 'payment/CONFIRM_PAYMENT' = 'payment/CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_SUCCESS: 'payment/CONFIRM_PAYMENT_SUCCESS' = 'payment/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_FAILURE: 'payment/CONFIRM_PAYMENT_FAILURE' = 'payment/CONFIRM_PAYMENT_FAILURE';

type ConfirmPayment = {
  type: typeof CONFIRM_PAYMENT,
};
export type ConfirmPaymentSuccess = {
  type: typeof CONFIRM_PAYMENT_SUCCESS,
};
type ConfirmPaymentFailure = {
  type: typeof CONFIRM_PAYMENT_FAILURE,
  error: string,
};

type ActionType = ConfirmPayment | ConfirmPaymentSuccess | ConfirmPaymentFailure;

export type State = {
  loading: boolean,
  error: ?string,
  loaded: boolean,
};

const initialState: State = {
  loading: false,
  error: null,
  loaded: false,
};

export default function paymentConfirmReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case CONFIRM_PAYMENT:
      return {
        ...initialState,
        loading: true,
      };

    case CONFIRM_PAYMENT_SUCCESS:
      const itineraryData = JSON.parse(localStorage.getItem('itinerary-details')) || [];

      itineraryData.push({
        token: action.result.data.token,
        id: action.result.data.id,
      });

      localStorage.setItem('itinerary-details', JSON.stringify(itineraryData));

      return {
        ...initialState,
        loaded: true,
      };

    case CONFIRM_PAYMENT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
}

export function confirmPayment(data: ConfirmPaymentSuccess) {
  return {
    type: CONFIRM_PAYMENT,
    data,
  };
}
