import { useLocation, useNavigate, useParams } from 'react-router';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    return <Component {...props} location={useLocation()} params={useParams()} navigate={useNavigate()} />;
  };

  return ComponentWithRouterProp;
};

export default withRouter;
