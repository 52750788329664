// @flow
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import apiMiddleware from './middleware/api';
import reducer from './modules';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(apiMiddleware, sagaMiddleware)));

sagas.forEach((saga) => sagaMiddleware.run(saga));

export default store;
