import React from 'react';

import LayoutComponent from '../components/Layout/Layout';

const withLayout = ({ Layout = LayoutComponent, Aside = null } = {}) => WrappedComponent => {
  function WithLayout(props) {
    return (
      <Layout>
        <div className="content-holder">
          <WrappedComponent {...props} />
        </div>
        {Aside && <Aside {...props} />}
      </Layout>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'WithLayout';

  WithLayout.displayName = `withLayout(${wrappedComponentName})`;

  return WithLayout;
};

export default withLayout;
