import React from 'react';

import { KOUNT_DOMAIN, KOUNT_MERCHANT_ID } from '../../constants/config';

type Props = {
  sessionId: string,
};

const CountDataCollector = ({ sessionId }: Props) => (
  <React.Fragment>
    <iframe
      title="countDataCollector"
      width="1"
      height="1"
      frameBorder="0"
      scrolling="no"
      src={`https://${KOUNT_DOMAIN}/logo.htm?m=${KOUNT_MERCHANT_ID}&s=${sessionId}`}
    />
    <img
      width="1"
      height="1"
      alt="Count data collector"
      src={`https://${KOUNT_DOMAIN}/logo.gif?m=${KOUNT_MERCHANT_ID}&s=${sessionId}`}
    />
  </React.Fragment>
);

export default CountDataCollector;
