// @flow
import './FlightCardList.css';

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import AirlineLogo from '../../../../components/AirlineLogo';
import Sorting from '../../../../components/Sorting/Sorting';
import { FlightCardListSkeleton } from './FlightCardList.skeleton';
import { AnimatedWrapper, Desktop, FindMore, FlightCardList, Indent } from 'travelopod-components';

const FADE_TIMEOUT = 2000;

const calculateHeightOfSkeleton = (countOfFlights) => {
  const countOfFlightCardInGroup = 3,
    groupGutter = 15,
    heightOfOneSkeletonGroup = 644.188 + groupGutter;

  return heightOfOneSkeletonGroup * (countOfFlights / countOfFlightCardInGroup) - groupGutter;
};

const UP_TO_PERCENTAGE = 0.05;
const MAX_DISCOUNT = 50;
const getUpToPrice = (cheapestPrice, percentage = UP_TO_PERCENTAGE) => {
  const discount = Math.floor(cheapestPrice * percentage);

  return Math.min(discount ?? MAX_DISCOUNT, MAX_DISCOUNT);
};

const FlightCards = (props) => {
  const {
    loading,
    searchFlightsResponse,
    code,
    serviceClass,
    tripType,
    groups,
    handleBooking,
    flight,
    loadMore,
    updateFlightGroups,
    setContainer,
    container,
    isChipperShows,
    chipperIndexes,
    hideIsChipper,
    setChipperIndexes,
    verifyingFlight,
    stage,
    isMKTSource,
    isOffline,
    isBannersShown,
    isUserLoggedIn,
    location,
  } = props;
  const currencySource = isMKTSource();
  const isFlights = flight?.value?.total !== 0;
  const hasMore = isFlights ? groups.length < flight.value?.total : false;
  const countOfFlights = isFlights ? flight.value?.total - groups.length : 0;
  const flightGroups = groups && groups.length ? groups : stage;
  const isCheapestPrice = flight?.value?.sorting?.cheapest.price.toFixed(2);
  const maskFlightCardIndexes = flight?.value?.maskFlightCards.map((cardData) => cardData.index[0]) || [];
  localStorage.setItem('DiscountedPrice', getUpToPrice(isCheapestPrice));

  return (
    <>
      {code && isOffline() && isCheapestPrice && isBannersShown && (
        <Indent ma="b-double" className="search-page-banner-holder"></Indent>
      )}
      <div
        ref={(el) => {
          if (!el) {
            return null;
          }
          window.container = el;
          !container && setContainer(el);
        }}
        className="result-wrapper"
      >
        <FindMore
          wrappedContainer={window.container}
          isOpen={isChipperShows}
          flightNumber={chipperIndexes.length}
          hideButton={() => {
            setTimeout(() => {
              setChipperIndexes([]);
              hideIsChipper();
            }, FADE_TIMEOUT);
          }}
          onClick={() => {
            window.scrollTo({
              top: window.container.offsetTop,
              behavior: 'smooth',
            });
          }}
        />
        {!loading && (
          <Desktop>
            <Indent ma="b-double">
              <Sorting fields={searchFlightsResponse} currencySource={currencySource} />
            </Indent>
          </Desktop>
        )}
        <AnimatedWrapper>
          <InfiniteScroll
            loadMore={() => isFlights && loadMore(updateFlightGroups)}
            hasMore={hasMore}
            loader={
              isFlights ? (
                <div key={0}>
                  <FlightCardListSkeleton countOfFlights={countOfFlights} />
                </div>
              ) : null
            }
            threshold={calculateHeightOfSkeleton(countOfFlights)}
          >
            <FlightCardList
              tripType={tripType}
              flightGroups={flightGroups}
              chipperIndexes={chipperIndexes}
              airlineLogoComponent={AirlineLogo}
              onBookFlight={handleBooking}
              flightToBook={verifyingFlight}
              serviceClass={serviceClass}
              currencySource={currencySource}
              isUserLoggedIn={isUserLoggedIn ? true : false}
              location={location}
              maskFlightCardIndexes={maskFlightCardIndexes}
            />
          </InfiniteScroll>
        </AnimatedWrapper>
      </div>
    </>
  );
};

export default FlightCards;
