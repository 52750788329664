import React from 'react';
import { compose } from 'recompose';
import withRouter from '../Hocs/wihRouter';
import { connect as reduxConnect } from 'react-redux';

import i18n from '../locales/i18next';
import withModal from '../Hocs/withModal';
import withDomain from '../Hocs/withDomain';
import withFilters from '../Hocs/withFilters';
import ResponseTapPhone from '../components/ResponseTap/ResponseTapPhone';
import TrustpilotHorizontal from '../components/Trustpilot/TrustpilotHorizontal';
import {
  DefaultModal,
  DefaultNormalModal,
  DesktopLarge,
  Indent,
  Mobile,
  RefreshResultsModal,
  Tablet,
  Text,
} from 'travelopod-components';
import {
  closeDefaultModals,
  get10MinuteModalStatus,
  get25SecModalStatus,
  getCallUsData,
  getCallUsFooterStatus,
  getIsBannersShown,
  getOneDayModalStatus,
  getPhoneData,
  getSameDayModalStatus,
  getSuperDealModalStatus,
  toggle10MinuteModal,
  toggle25SecModal,
  toggleCallUsFooterModal,
  toggleNoSeatsModal,
  togglePriceChangedModal,
} from '../store/modules/modalGeneral';

const searchPathName = '/search';
const SuperDealLabel = () => (
  <Text>
    This is a
    <Text as="span" weight="bold">
      &nbsp;special unpublished fare&nbsp;
      <DesktopLarge>
        <br />
      </DesktopLarge>
    </Text>
    and can only be booked by calling us
  </Text>
);

const ModalMain = (props) => {
  const {
    children,
    callUsData,
    oneDayModalStatus,
    sameDayModalStatus,
    superDealModalStatus,
    closeDefaultModals,
    modal10MinuteStatus,
    toggle10MinuteModal,
    location,
    callUsStatus,
    toggleCallUsFooterModal,
    isBannersShown,
  } = props;

  const isSearchPage = location.pathname.includes('/search');

  return (
    <>
      <DefaultModal
        inverted
        isOpen={callUsStatus}
        onClose={toggleCallUsFooterModal}
        destination={callUsData.destination}
        label="Up to $200 lower than online"
      >
        <Indent className="modal-block-with-girl" />
        <Text
          weight="bold"
          color="orange"
          className="modal-block-response-tap default-modal-component"
          as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberCallUsForBestDeals" />}
          title={i18n.t('components.header.phone')}
        />
      </DefaultModal>

      {isSearchPage && (
        <>
          {isBannersShown && (
            <DefaultModal
              className="super-deal-modal"
              destination={callUsData.destination}
              description="Most of our customers get significant savings using super saver deals"
              descriptionMobile="Most of our customers get significant savings using super saver deals"
              responseTapDescription="Call our travel experts at:"
              title="SUPER SAVER DEAL"
              label={<SuperDealLabel />}
              inverted
              superDeal={superDealModalStatus}
              isOpen={superDealModalStatus}
              onClose={closeDefaultModals}
            >
              <Indent className="super-deal-modal-block-with-girl" />
              <Text
                weight="bold"
                color="orange"
                className="super-deal-modal-block-response-tap default-modal-component"
                as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberSuperDeal" />}
                title={i18n.t('components.header.phone')}
              />
            </DefaultModal>
          )}
          <DefaultNormalModal className="same-day-modal" onClose={closeDefaultModals} isOpen={sameDayModalStatus}>
            <Indent className="same-day-modal-block-with-girl-wrapper">
              <DesktopLarge>
                <Indent className="same-day-modal-block-with-girl" />
              </DesktopLarge>
              <Tablet>
                <Indent className="same-day-modal-block-with-girl" />
                <Indent className="same-day-modal-block-with-girl-shadow" />
              </Tablet>
              <Mobile>
                <Indent className="same-day-modal-block-with-girl" />
                <Indent className="same-day-modal-block-with-girl-shadow" />
              </Mobile>
            </Indent>
            <Text
              weight="bold"
              color="orange"
              className="same-day-modal-block-response-tap default-modal-component"
              as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberDefault" />}
              title={i18n.t('components.header.phone')}
            />
            <TrustpilotHorizontal />
          </DefaultNormalModal>
          <DefaultNormalModal
            className="travel-within-1-day-modal"
            onClose={closeDefaultModals}
            isOpen={oneDayModalStatus}
            title="Travel within 1 day can only be booked over phone"
            label="We have special last minute phone deals to be booked on phone."
          >
            <Indent className="same-day-modal-block-with-girl-wrapper">
              <DesktopLarge>
                <Indent className="same-day-modal-block-with-girl one-day" />
              </DesktopLarge>
              <Tablet>
                <Indent className="same-day-modal-block-with-girl one-day" />
                <Indent className="same-day-modal-block-with-girl-shadow one-day" />
              </Tablet>
              <Mobile>
                <Indent className="same-day-modal-block-with-girl one-day" />
                <Indent className="same-day-modal-block-with-girl-shadow one-day" />
              </Mobile>
            </Indent>
            <Text
              weight="bold"
              color="orange"
              className="same-day-modal-block-response-tap default-modal-component"
              as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberDefault" />}
              title={i18n.t('components.header.phone')}
            />
            <TrustpilotHorizontal />
          </DefaultNormalModal>
          <RefreshResultsModal
            isOpen={modal10MinuteStatus}
            shouldCloseOnOverlayClick={false}
            onClose={toggle10MinuteModal}
          />
        </>
      )}
      {children}
    </>
  );
};

export default compose(
  withModal,
  withRouter,
  withFilters(),
  withDomain(),
  reduxConnect(
    (state) => ({
      callUsData: getCallUsData(state),
      callUsStatus: getCallUsFooterStatus(state),
      oneDayModalStatus: getOneDayModalStatus(state),
      sameDayModalStatus: getSameDayModalStatus(state),
      superDealModalStatus: getSuperDealModalStatus(state),
      callUs25SecModalStatus: get25SecModalStatus(state),
      modal10MinuteStatus: get10MinuteModalStatus(state),
      phoneData: getPhoneData(state),
      isBannersShown: getIsBannersShown(state),
    }),
    {
      toggleCallUsFooterModal,
      closeDefaultModals,
      toggle25SecModal,
      toggle10MinuteModal,
      toggleNoSeatsModal,
      togglePriceChangedModal,
    },
  ),
)(ModalMain);
