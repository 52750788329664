import queryString from 'query-string';
import { compile } from 'path-to-regexp';

export const NOT_FOUND = '/404';
export const SEARCH = '/search/*';
export const ITINERARY_DETAILS = '/itinerary-details';
export const BOOK_FLIGHT_SUCCESS = '/flights/book/success';
export const BOOK_FLIGHT = '/flights/book/:flightKey/:searchHash';
export const BILLING_CONFIRMATION = '/billing-confirmation/:domain?';
export const SUCCESSFUL_CONFIRMATION = '/successful-confirmation/:domain?';
export const FLIGHT_ITINERARY_DETAILS = '/flights/:pnr/:supplierCode/itinerary-details';

export const bookFlightUrl = compile(BOOK_FLIGHT);
export const flightItineraryDetailsUrl = compile(FLIGHT_ITINERARY_DETAILS);

const bookFlightSuccessPath = compile(BOOK_FLIGHT_SUCCESS);

export const bookFlightSuccessUrl = (path, query) => {
  const url = bookFlightSuccessPath(path);

  if (query) {
    return `${url}?${queryString.stringify(query)}`;
  }

  return url;
};
