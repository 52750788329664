import defaultProps from 'recompose/defaultProps';
import { AirlineLogo } from 'travelopod-components';

import { IBE_BASE_URL } from '../../constants/config';

const LOGO_BASE_URL = `${IBE_BASE_URL}/images/airlinelogo`;

export default defaultProps({
  getAirlineLogoSrc: ({ airlineCode }) => `${LOGO_BASE_URL}/${airlineCode}.png`,
  getFallBackLogoSrc: () => `${LOGO_BASE_URL}/default_logo.png`,
})(AirlineLogo);
