import Cookies from 'js-cookie';

export const getSource = () => {
  let referrerCookie = Cookies.get('__ref_tp') || '';
  //eslint-disable-next-line
  return referrerCookie.match(/[^\/\|]*/)[0] || '(direct)'; // Match cookie value up to the first '|' or '/'
};

export const getSubId = () => {
  let referrerCookie = Cookies.get('__ref_tp') || '';
  let cookieParts = referrerCookie.split('/');

  return referrerCookie ? `${cookieParts[1]} | ${cookieParts[3]}` : '(none)';
};

export const getTrafficSource = () => ({
  source: getSource(),
  subId: getSubId(),
  userAgent: window.navigator.userAgent,
});
