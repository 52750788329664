/* eslint-disable no-plusplus */
import './PassengerInfo.css';

import React from 'react';

import Passenger from './Passenger';

const PassengerList = ({ passengers }) => (
  <ul className="passenger-list">
    {!!passengers.length &&
      passengers.map((passenger, index) => {
        return (
          <li key={index}>
            <Passenger index={++index} passengerData={passenger} />
          </li>
        );
      })}
  </ul>
);

export default PassengerList;
