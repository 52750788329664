// @flow
import './Aside.css';

import React from 'react';
import {
  AccreditedBusinessBanner,
  AnimatedWrapper,
  BookWithUsBanner,
  Desktop,
  Indent,
  Mobile,
} from 'travelopod-components';

import HelpBanner from '../../components/Banners/HelpBanner';
import TrustpilotList from '../../components/Trustpilot/TrustpilotList';
import BookingCountdown from '../BookingCountdown';
const DELAY = 2500;

type Props = {
  children: React.Node,
  asideRoot?: React.Node,
};

const MobileWrapper = ({ children }) => {
  const [asideWrapper] = React.Children.toArray(children);
  const [animatedWrapperInner] = React.Children.toArray(asideWrapper.props.children);
  const [aside] = React.Children.toArray(animatedWrapperInner.props.children);
  const [, accreditedBusinessBannerWithWrapper, helpBanner, bookWithUsBanner] = React.Children.toArray(
    aside.props.children,
  );
  const [accreditedBusinessBanner] = React.Children.toArray(accreditedBusinessBannerWithWrapper.props.children);

  return (
    <Mobile>
      <AnimatedWrapper delay={DELAY}>
        <div className="aside">
          {helpBanner}
          {bookWithUsBanner}
          <Indent ma="t">{accreditedBusinessBanner}</Indent>
        </div>
      </AnimatedWrapper>
    </Mobile>
  );
};

const AsideContent = ({ children, asideRoot: AsideRoot }) => (
  <React.Fragment>
    <Desktop>
      <AsideRoot>{children}</AsideRoot>
    </Desktop>
    <MobileWrapper children={children} />
  </React.Fragment>
);

class Aside extends React.Component<Props> {
  render() {
    const { children, asideRoot: AsideRoot, isSmallAsideWidth, bookFlightPage } = this.props;

    const [safeAndGuaranty] = React.Children.toArray(children);

    return (
      <AsideContent asideRoot={AsideRoot}>
        <div className="aside-wrapper">
          <AnimatedWrapper delay={DELAY}>
            <div className="aside">
              {bookFlightPage && <BookingCountdown isAside />}
              <TrustpilotList />
              {safeAndGuaranty}
              <Indent ma="b">
                <div className="accreditedBusinessBanner">
                  <AccreditedBusinessBanner isSmallWrapperWidth={isSmallAsideWidth} />
                </div>
              </Indent>
              <HelpBanner />
              <Indent ma="t">
                <BookWithUsBanner />
              </Indent>
            </div>
          </AnimatedWrapper>
        </div>
      </AsideContent>
    );
  }
}

Aside.defaultProps = {
  asideRoot: 'div',
};

export default Aside;
