import isMobile from 'ismobilejs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import ResponseTapConfig from '../constants/responseTap';

export const formatPhone = (phone, format = 'INTERNATIONAL') => {
  let phoneNumber = phone;

  if (!phoneNumber) {
    phoneNumber = isMobile.phone
      ? ResponseTapConfig.DEFAULT_MOBILE_PHONE.phone
      : ResponseTapConfig.DEFAULT_DESKTOP_PHONE.phone;
  }

  let result = parsePhoneNumberFromString(`+${phoneNumber.replace('+', '')}`).format(format);

  if (format === 'INTERNATIONAL') {
    result = result
      .replace('+', '')
      .split(' ')
      .join('-');
  }

  return result;
};
