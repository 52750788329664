import cardValidator from 'card-validator';
import get from 'lodash/get';

import { CARD_TYPES } from '../constants/payment';
import api from './index';

const saveItineraryData = ({ token, id }) => {
  const itineraryData = JSON.parse(localStorage.getItem('itinerary-details')) || [];

  itineraryData.push({
    token,
    id,
  });

  localStorage.setItem('itinerary-details', JSON.stringify(itineraryData));
};

export const confirmPayment = async ({
  token,
  kountSessionId,
  paymentConfirmation,
  paymentInfo,
  additionalServices,
  upgradeSupportPackage,
  referralInfo,
}) => {
  const request = {
    token,
    kountSessionId,
    ...referralInfo,
  };

  if (
    upgradeSupportPackage &&
    upgradeSupportPackage !== 'UPGRADE_BASIC' &&
    upgradeSupportPackage !== 'SUPPORT_STANDARD'
  ) {
    request.additionalService = { type: upgradeSupportPackage };
  }

  if (additionalServices) {
    const { billingData, quote } = additionalServices;

    request.bookingInsurance = {
      quoteDetailUrl: quote.responseObject.PlanForQuoteRS.QuoteDetail.QuoteDetailURL,
      planCost: quote.insurancePlanCost,
      basePremium: quote.insuranceBasePremium,
      billingStateId: billingData.billingState.id,
      billingZipCode: billingData.billingZipCode,
      planId: quote.planId,
      address: billingData.address,
      city: billingData.city,
    };
  }

  if (paymentInfo) {
    const tokenizedCard = await api().payment.vaultCardAndGetToken({
      card: {
        cardNumber: paymentInfo.cardNumber,
        cardExpiry: paymentInfo.expirationDate,
        cardHolderName: paymentInfo.cardName,
        cvv: paymentInfo.cvv,
      },
    });

    request.billingDetails = {
      customerDetails: {
        phone: paymentInfo.phone,
      },
      addressDetails: {
        address: paymentInfo.address,
        billingStateId: paymentInfo.state.id,
        billingCountryId: paymentInfo.country.id,
        zipCode: paymentInfo.zip,
        city: paymentInfo.city,
      },
      cardDetails: {
        cardholderName: paymentInfo.cardName,
        expDate: paymentInfo.expirationDate,
        last4: paymentInfo.cardNumber.slice(-4),
        cardType: CARD_TYPES[get(cardValidator.number(paymentInfo.cardNumber), 'card.niceType')],
        cardToken: tokenizedCard.data.data.token,
      },
    };
  }

  const response = await api().payment.confirmPayment(request);
  const { data } = response.data;

  saveItineraryData(data);

  return data;
};
