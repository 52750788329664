import CONTACT_PHONE_MAP from '../../../constants/responseTap';
import { formatPhone } from '../../../helpers/phone';

export const UPDATE_PHONE = 'phoneData/UPDATE_PHONE';
export const UPDATE_PHONE_SUCCESS = 'phoneData/UPDATE_PHONE_SUCCESS';
export const UPDATE_PHONE_ERROR = 'phoneData/UPDATE_PHONE_ERROR';

export function updatePhoneTFN(data) {
  return { type: UPDATE_PHONE, data };
}



export const setBookingFailedPhone = () => {
  return {
    type: UPDATE_PHONE_SUCCESS,
    result: formatPhone(CONTACT_PHONE_MAP.BOOKING_FAILED_PHONE),
  };
};

export const setBookingPhone = () => {
  return {
    type: UPDATE_PHONE_SUCCESS,
    result: formatPhone(CONTACT_PHONE_MAP.BOOKING_PHONE),
  };
};

type State = {
  phone: string,
};

export const getDefaultNumber = () =>
  formatPhone(localStorage.getItem('call_tracking_initialNumber') || CONTACT_PHONE_MAP.DEFAULT_PHONE);

const initialState: State = {
  phone: getDefaultNumber(),
};

// selectors
export const getPhoneDataSelector = (state) => state.responseTap.phoneData;

export default function phoneData(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PHONE_SUCCESS:
    case UPDATE_PHONE_ERROR:
      return {
        ...state,
        phone: action.result,
      };
    default:
      return state;
  }
}

