import moment from 'moment-timezone';

import { PST_FORMAT } from '../constants/global';

export const leadDateToFormat = (date: number, format: string): string => {
  return moment.unix(date).format(format);
};

export const unixToUtc = (unix: number, format?: string) => {
  const date = moment.unix(unix).utc();

  if (format) {
    return date.format(format);
  }

  return date;
};

export const stringToUtc = (stringDate: string, format?: string) => moment.utc(stringDate, format);

export const formatDateToPSTTime = (date: string): string => {
  return `${moment(date).tz(PST_FORMAT.PST_TIME_ZONE).format(PST_FORMAT.PST_TIME_FORMAT)} (PST)`;
};

//  to_do: version formatDateToPSTTime() without moment timezones
// currently result of invocation isDST() refer to local time
// export const formatDateToPSTTime = (authorizationTime: string): string => {
//   const date = moment.utc(authorizationTime);
//
//   date.utcOffset(date.isDST() ? -8 : -7);
//
//   return `${date.format(PST_FORMAT.PST_TIME_FORMAT)} (PST)`;
// };

export const formatTime = (num) => moment.utc(+num * 60 * 1000).format('h:mm a');
