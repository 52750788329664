import React from 'react';
import { compose } from 'recompose';
import { Indent, Link, Text } from 'travelopod-components';

import { MARKETING_PRIVACY_POLICY, MARKETING_TERMS_AND_CONDITIONS } from '../../constants/global';
import { getBaggageFeeUrl } from '../../helpers/passengers';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';

type Props = {
  airlineCode: string,
  showBaggageFeesLink?: boolean,
};

const CommonLinks = ({ airlineCode, showBaggageFeesLink, buildMarketingUrl }: Props) => (
  <Indent pa="v-double h-big">
    <Text color="blue">
      {showBaggageFeesLink && (
        <Link ma="r-double" href={`${getBaggageFeeUrl(airlineCode)}`} target="_blank">
          Baggage Fees
        </Link>
      )}
      <Link href={buildMarketingUrl(MARKETING_TERMS_AND_CONDITIONS)} target="_blank">
        Terms and Conditions
      </Link>
      &nbsp;&&nbsp;
      <Link href={buildMarketingUrl(MARKETING_PRIVACY_POLICY)} target="_blank">
        Privacy Policy
      </Link>
    </Text>
  </Indent>
);

CommonLinks.defaultProps = {
  showBaggageFeesLink: true,
};

export default compose(withCallTrackingUrl())(CommonLinks);
