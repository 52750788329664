import './Trustpilot.css';

import React from 'react';
import { Container, Desktop } from 'travelopod-components';

import Trustpilot from './Trustpilot';

const TrustpilotList = props => (
  <Desktop>
    <div className="trustpilot-wrapper">
      <div className="trustpilot-list" />
      <Container ma="b" size="small">
        <Trustpilot {...props} />
      </Container>
    </div>
  </Desktop>
);

TrustpilotList.defaultProps = {
  'data-template-id': '539ad60defb9600b94d7df2c',
  'data-style-height': '460px',
};

export default TrustpilotList;
