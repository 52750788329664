import React from 'react';

import type { Props as TextProps } from './Text';
import Text from './Text';

type HeaderT = 'h1' | 'h2' | 'h3';

type Props = {
  as: HeaderT,
};

const Header = (props: Props & TextProps) => <Text {...props} />;

export default Header;
