// @flow
import { put, takeEvery } from 'redux-saga/effects';

import api from '../../api';
import { CONFIRM_PAYMENT, CONFIRM_PAYMENT_FAILURE, CONFIRM_PAYMENT_SUCCESS } from '../modules/payment/confirm';
import {
  GET_BILLING_COUNTRIES,
  GET_BILLING_COUNTRIES_FAILURE,
  GET_BILLING_COUNTRIES_SUCCESS,
} from '../modules/payment/countries';
import { CREATE_PAYMENT, CREATE_PAYMENT_FAILURE, CREATE_PAYMENT_SUCCESS } from '../modules/payment/create';
import { FETCH_RECEIPT, FETCH_RECEIPT_FAILURE, FETCH_RECEIPT_SUCCESS } from '../modules/payment/receipt';

function* createPayment({ data }) {
  try {
    const response = yield api().payment.createPayment(data);
    const result = response.data.data;
    yield put({ type: CREATE_PAYMENT_SUCCESS, result });
  } catch (err) {
    const error = err.response.data.error;
    yield put({ type: CREATE_PAYMENT_FAILURE, error });
  }
}

function* confirmPayment({ data }) {
  if (data.cardTokenRequestData) {
    try {
      const response = yield api().payment.getCardToken(data.cardTokenRequestData);

      if (response.data.error) {
        yield put({ type: CONFIRM_PAYMENT_FAILURE, error: 'global' });

        return;
      }

      const { token } = response.data.result;
      const { data: confirmData } = data.confirmRequestData;
      const { bookingInsurance } = data;
      confirmData.cardDetails.cardToken = token;

      let newResponse;
      if (confirmData.remark && confirmData.remark.length) {
        newResponse = yield api().payment.confirmPayment({
          token: confirmData.token,
          billingDetails: {
            remark: confirmData.remark,
            customerDetails: confirmData.customerDetails,
            addressDetails: confirmData.addressDetails,
            cardDetails: confirmData.cardDetails,
          },
          kountSessionId: data.kountSessionId,
          bookingInsurance,
        });
      } else {
        newResponse = yield api().payment.confirmPayment({
          token: confirmData.token,
          billingDetails: {
            customerDetails: confirmData.customerDetails,
            addressDetails: confirmData.addressDetails,
            cardDetails: confirmData.cardDetails,
          },
          kountSessionId: data.kountSessionId,
          bookingInsurance,
        });
      }
      const result = newResponse.data;
      yield put({ type: CONFIRM_PAYMENT_SUCCESS, result });
    } catch (err) {
      const error = err.response.data.error;
      yield put({ type: CONFIRM_PAYMENT_FAILURE, error });
    }
  } else {
    try {
      const response = yield api().payment.confirmPayment(data);
      const result = response.data;
      yield put({ type: CONFIRM_PAYMENT_SUCCESS, result });
    } catch (err) {
      const error = err.response.data.err;
      yield put({ type: CONFIRM_PAYMENT_FAILURE, error });
    }
  }
}

function* getBillingCountries() {
  try {
    const res = yield api().payment.getBillingCountries();
    const { data } = res.data;
    yield put({ type: GET_BILLING_COUNTRIES_SUCCESS, data });
  } catch (err) {
    const error = err.response.data.err;
    yield put({ type: GET_BILLING_COUNTRIES_FAILURE, error });
  }
}

function* getReceipt({ data }) {
  try {
    const response = yield api().payment.getReceipt(data);
    const result = response.data.data;
    yield put({ type: FETCH_RECEIPT_SUCCESS, result });
  } catch (err) {
    const error = err.response.data.error;
    yield put({ type: FETCH_RECEIPT_FAILURE, error });
  }
}

export default function* (): Generator<*, *, *> {
  yield takeEvery(CREATE_PAYMENT, createPayment);
  yield takeEvery(CONFIRM_PAYMENT, confirmPayment);
  yield takeEvery(GET_BILLING_COUNTRIES, getBillingCountries);
  yield takeEvery(FETCH_RECEIPT, getReceipt);
}
