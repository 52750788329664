import './BookFlight.css';

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Button, Container, Divider, FlexBox, Grid, Header, Icon, Indent, Text } from 'travelopod-components';

import TripsInfo from './TripsInfo';
import Aside from '../../components/Aside';
import withDomain from '../../Hocs/withDomain';
import withLayout from '../../Hocs/withLayout';
import Currency from '../../components/Currency';
import { getFullname } from '../../helpers/passengers';
import { markAsOpened } from '../../helpers/exitIntent';
import PassengerIcon from '../../assets/icons/passengers.svg';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import { flightItineraryDetailsUrl } from '../../constants/routes';
import Suggestions from '../../components/Suggestions/Suggestions';
import CommonLinks from '../../components/CommonLinks/CommonLinks';
import withScrollRestoration from '../../Hocs/withScrollRestoration';
import { calculateAdditionalServices } from '../../helpers/payments';
import PaymentSummary from '../../components/PaymentSummary/PaymentSummary';
import ConfirmationNumber from '../../components/ConfirmationNumber/ConfirmationNumber';
import { setBookingPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';
import { getSessionStorageItem, setSessionStorageItem } from '../../helpers/sessionStorageHelper';

class BookFlightSuccessPage extends React.Component {
  render() {
    const { location, params, buildMarketingUrl } = this.props;
    const locationStatefromSessionStorage = getSessionStorageItem('locationState');
    const bookFlightSuccessData =
      locationStatefromSessionStorage?.page === 'bookingSuccessPage' ? locationStatefromSessionStorage.state : null;
    const {
      body: { bookedFlight, passengerDetails, paymentDetail, pnr, creditCard },
      emailAddress,
      phone,
      quote = 0,
      additionalService,
      flight,
      passengers,
    } = location.state || bookFlightSuccessData;

    if (location.state && Object.keys(location?.state).length) {
      setSessionStorageItem('locationState', { state: location.state, page: 'bookingSuccessPage' });
    }

    const trips = flight.trips;
    const passengersData = {
      ADULT: 0,
      CHILD: 0,
      INFANT: 0,
    };
    passengers.forEach(({ passengerType }) => (passengersData[passengerType] += 1));

    const { compain } = params;
    const path = compain
      ? {
          compain,
          pnr,
          supplierCode: bookedFlight.supplierCode,
        }
      : { pnr, supplierCode: bookedFlight.supplierCode };

    const amount =
      quote +
      paymentDetail.total +
      (additionalService
        ? calculateAdditionalServices(
            { upgradeSupportPackage: additionalService.type },
            passengersData,
            trips.length > 1,
          )
        : 0);

    return (
      <React.Fragment>
        <Indent ma="b-double">
          <Container fluid>
            <Indent pa="v-double h-big">
              <FlexBox alignItems="center" justifyContent="space-between">
                <Header className="e2e-page-title" as="h1">
                  Booking Confirmation
                </Header>
              </FlexBox>
              <Text ma="v-double" size="big" align="justify">
                Your booking is complete! We will send your tickets to <strong>{emailAddress}</strong> within 24 hours.
                Thank you for using Travelopod.
              </Text>
              <ConfirmationNumber ma="b-double" pnr={pnr} />
              <Header ma="t b-double" as="h2">
                Just Booked
              </Header>
              <Header as="h3">
                {bookedFlight.origin.airportCode} - {bookedFlight.destination.airportCode}
              </Header>
              <Grid.Row gutter={40} md={[6, 10]} sm={[6, 10]} xs={[8, 8]}>
                <TripsInfo trips={bookedFlight.trips} flight={bookedFlight} />
                <div>
                  {passengerDetails.map((passenger, index) => (
                    <FlexBox alignItems="center" ma="b-half" key={index}>
                      <Icon secondary src={PassengerIcon} ma="r-half" size="small" />
                      <Text as="span">{getFullname(passenger)}</Text>
                    </FlexBox>
                  ))}
                </div>
              </Grid.Row>
              <Indent ma="v-double">
                <Button
                  secondary
                  target="_blank"
                  href={buildMarketingUrl(flightItineraryDetailsUrl(path))}
                  className="view-itinerary"
                >
                  View Itinerary
                </Button>
              </Indent>
            </Indent>
            <Divider />
            <PaymentSummary
              amount={<Currency currency="USD" amount={amount} as="span" />}
              authorizationTime={paymentDetail.CreatedOn}
              cardHolderName={creditCard.cardHolder}
              cardType={creditCard.cardType.toUpperCase()}
              cardNumber={creditCard.number.toString().slice(-4)}
              phoneNumber={paymentDetail.billingAddress ? paymentDetail.billingAddress.phoneNumber : phone}
            />
            <Divider />
            <CommonLinks airlineCode={bookedFlight.trips[0].options[0].airline.code} />
          </Container>
        </Indent>
        <Suggestions destinationCity={bookedFlight.destination.city} />
      </React.Fragment>
    );
  }
}

export default compose(
  withScrollRestoration,
  withCallTrackingUrl(),
  connect(null, {
    updatePhoneTFN,
    setBookingPhone,
  }),
  withLayout({ Aside }),
  withDomain(),
  lifecycle({
    componentDidMount() {
      markAsOpened();

      if (!localStorage.getItem('call_tracking_initial_params')) {
        this.props.setBookingPhone();
      }
    },
  }),
)(BookFlightSuccessPage);
