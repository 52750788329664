import { branch, compose, renderNothing, withProps } from 'recompose';
import { Sorting } from 'travelopod-components';

import withFilters from '../../Hocs/withFilters';

const defaultSorting = {
  cheapest: {
    name: 'cheapest',
    price: '',
  },
  quickest: {
    name: 'quickest',
    price: '',
  },
  best: {
    name: 'best',
    price: '',
  },
};

const defaultFilters = () => ({ sort: 'cheapest' });

export default compose(
  branch(
    props =>
      !props.fields ||
      !props.fields.value ||
      (props.fields && props.fields.value && props.fields.value.sorting === null),
    renderNothing,
  ),
  withFilters(defaultFilters),
  withProps(props => ({
    value: props.queryFilters.sort || 'cheapest',
    fields:
      props.fields && props.fields.value
        ? {
            cheapest: { price: props.fields.value.sorting.cheapest.price, name: 'cheapest' },
            quickest: { price: props.fields.value.sorting.quickest.price, name: 'quickest' },
            best: { price: props.fields.value.sorting.best.price, name: 'best' },
          }
        : defaultSorting,
    onClick: ({ name }) => {
      props.updateFilters({ sort: name });
    },
  })),
)(Sorting);
