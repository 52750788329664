import './FlexBox.css';

import classNames from 'classnames';
import React from 'react';

type Props = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse',
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between' | 'center',
  alignItems?: 'flex-start' | 'flex-end' | 'center',
  alignContent?: 'flex-start' | 'flex-end' | 'center',
  className?: string,
};

const FlexBox = ({
  direction,
  justifyContent,
  alignItems,
  alignContent,
  children,
  className: baseClassName,
}: Props) => {
  const className = classNames(baseClassName, 'flex-box', {
    [`direction-${direction}`]: direction,
    [`justify-content-${justifyContent}`]: justifyContent,
    [`align-items-${alignItems}`]: alignItems,
    [`align-content-${alignContent}`]: alignContent,
  });

  return <div className={className}>{children}</div>;
};

export default FlexBox;
