import withRouter from './wihRouter';
import { compose, withHandlers, withProps } from 'recompose';
import { withTheme } from 'travelopod-components';
import { domains } from '../constants/global';

export default () =>
  compose(
    withRouter,
    withTheme,
    withHandlers({
      getDomainInfo: (props) => () => {
        const { params, theme } = props;
        let domain = 'A';

        return {
          name: params.compain || '',
          domain,
          theme: theme && theme.mode,
        };
      },
    }),
    withProps((props) => ({
      isOnline: () => props.getDomainInfo().domain === domains['B'],
      isOffline: () => props.getDomainInfo().domain === domains['A'],
      isFlykart: () => props.getDomainInfo().theme === 'flykart',
    })),
    withProps(() => ({
      getDomainName: () => {
        return 'travelopodb';
      },
    })),
  );
