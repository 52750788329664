import './SecondaryContainer.css';

import React from 'react';

const SecondaryContainer = ({ children, className, align }) => (
  <div className={`secondary-container ${className} ${align}`}>{children}</div>
);

SecondaryContainer.defaultProps = {
  className: 'primary',
};

export default SecondaryContainer;
