import american_express from './american_express.svg';
import discover from './discover.svg';
import master_card from './mastercard.svg';
import visa from './visa.svg';

export default {
  master_card,
  visa,
  american_express,
  discover,
};
