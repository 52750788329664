import debounce from 'lodash/debounce';

import { SupportPackages, UpgradePackages } from '../constants/global';

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const validatePostal = (formValues, availableZipCodesRegExps): boolean => {
  if (!formValues.postal.length) {
    return false;
  }

  if (typeof formValues.country === 'string') {
    return false;
  }

  return availableZipCodesRegExps[formValues.country.value].test(formValues.postal);
};

export const debounceHandler = (cb, ms = 500) => debounce(cb, ms);

export const setupUpgradeSupportPackage = travelMarket => {
  let result = null;

  if (!travelMarket) {
    return null;
  }

  if (UpgradePackages.markets.some(market => travelMarket.includes(market))) {
    result = UpgradePackages.plans.basic.value;
  } else if (SupportPackages.markets.some(market => travelMarket.includes(market))) {
    result = SupportPackages.plans.standard.value;
  }

  return result;
};
