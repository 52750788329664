import React from 'react';
import { Container, Text } from 'travelopod-components';

type Props = {
  pnr: string,
  ma?: string,
};

const ConfirmationNumber = ({ pnr, ma }: Props) => (
  <Container ma={ma} pa primary noShadow>
    <Text>
      Confirmation Number: <strong className="e2e-pnrNumber">{pnr}</strong>
    </Text>
    <Text secondary>Use this number for online check-in</Text>
  </Container>
);

export default ConfirmationNumber;
