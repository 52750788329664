import './ItineraryDetails.css';

import React from 'react';
import { Container, FlexBox, Header, Indent, Text, TripDetails } from 'travelopod-components';

import { pnrTripsToFlight } from '../../helpers/flights';
import { formPassengerDetails } from '../../helpers/trips';
import AirlineLogo from '../../components/AirlineLogo/AirlineLogo';

const ItineraryDetails = (props) => {
  const { receipt } = props;
  const flight = pnrTripsToFlight(receipt);

  return (
    <Container className="itinerary-details-page">
      <Indent pa="double">
        <FlexBox alightItems="center" justifyContent="space-between" ma="b-double">
          <Header as="h1">Itinerary Details</Header>
        </FlexBox>

        <div className="passenger-details">
          <Header as="h2" ma="b-double">
            Passenger Details
          </Header>

          <Header as="h3" ma="b">
            Passengers:
          </Header>

          <div className="passenger-details-list">
            {receipt.passengerDetail && formPassengerDetails(receipt.passengerDetail)}
          </div>

          <Text secondary>
            <Text weight="bold" as="span" secondary>
              Disclaimer:&nbsp;
            </Text>
            All meal preferences, seat requests are not guaranteed. Please contact your airline to reconfirm that they
            have received this request and confirmed it.
          </Text>
        </div>

        <Header as="h2" ma="v-double">
          Flight Summary
        </Header>

        {flight?.trips.map((trip, index) => (
          <TripDetails
            key={index}
            flight={flight}
            trip={trip}
            airlineLogoComponent={AirlineLogo}
            tripOrigin={flight.origin}
            tripDestination={flight.destination}
            serviceClass={receipt.serviceClass}
          />
        ))}

        <Text secondary>
          <Text weight="bold" as="span" secondary>
            Please note:&nbsp;
          </Text>
          As Airlines have frequent schedule changes, please call the Airline 24 hours before departure to reconfirm
          your flight details. For any changes to dates or routing, please call our Customer Service. These changes may
          have airline penalty and our fees. Some flights may be completely NON-CHANGEABLE even with an airline penalty.
        </Text>
      </Indent>
    </Container>
  );
};

export default ItineraryDetails;
