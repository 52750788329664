import * as Sentry from '@sentry/browser';

import { APP_ENVIRONMENT, SENTRY_DSN } from './constants/config';

const levels = {
  fatal: 'fatal',
  error: 'error',
  warning: 'warning',
  log: 'log',
  info: 'info',
  debug: 'debug',
  critical: 'critical',
};

export const catchDefaultError = ({ error, tags = [], level = 'warning', extraData = {}, userData = {} }) => {
  Sentry.withScope((scope) => {
    scope.setUser({ ...userData });
    tags.forEach(({ tagName, tagValue }) => {
      scope.setTag(tagName, tagValue);
    });
    Object.keys(extraData).forEach((key) => {
      scope.setExtra(key, extraData[key]);
    });
    scope.setLevel(level);
    Sentry.captureException(error);
  });
};

export const catchServerError = (error, { request, response = { status: 500 } }) => {
  try {
    if (!response || response.status >= 500) {
      Sentry.withScope((scope) => {
        const extraData = {
          code: response.status || 500,
          request: {
            url: response.url || request.url,
            method: request.body ? 'POST' : 'GET',
            ...(request.body && { body: request.body }),
          },
        };
        Object.keys(extraData).forEach((key) => {
          scope.setExtra(key, extraData[key]);
        });
        scope.setTag('Server response code', '5**');
        scope.setLevel(levels.critical);
        Sentry.captureMessage(`Server error with status code: ${response.status}`);
      });
    }
  } catch (err) {
    catchDefaultError({
      error: err,
      level: levels.fatal,
      extraData: { request, response, error },
      tags: [{ tagName: 'fatality-types', tagValue: 'crash catchServerError method' }],
    });
  }
};

export const sentryInit = () => {
  Sentry.init({
    enabled: APP_ENVIRONMENT === 'production',
    dsn: SENTRY_DSN,
    environment: APP_ENVIRONMENT,
    release: 'travelopod',
  });
};
