import PubNub from 'pubnub';
const { REACT_APP_PUBNUB_SUBSCRIBE_KEY, REACT_APP_PUBNUB_PUBLISH_KEY } = process.env;

const pubnub = new PubNub({
  subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  publishKey: REACT_APP_PUBNUB_PUBLISH_KEY,
  ssl: true,
  uuid: 'travelopod-b-uuid',
});

export default pubnub;
