// @flow
import { withStateHandlers } from 'recompose';

export type ModalPropsT = {
  isOpen: boolean,
  onClose: () => void,
  onOpen: () => void,
};

export default withStateHandlers(
  { isOpen: false },
  {
    onClose: () => () => ({
      isOpen: false,
    }),
    onOpen: () => () => ({
      isOpen: true,
    }),
  },
);
