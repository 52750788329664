import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Loader from '../../components/Loader';
import { Error } from 'travelopod-components';
import withRouter from '../../Hocs/wihRouter';
import withDomain from '../../Hocs/withDomain';
import withLayout from '../../Hocs/withLayout';
import Aside from '../../components/Aside/Aside';
import withRefetch from '../../Hocs/withRefetch';
import ItineraryDetails from './ItineraryDetails';
import { IBE_BASE_URL } from '../../constants/config';
import { FLIGHT_SEGMENT_TYPE } from '../../constants/global';
import { triggerPageView } from '../../helpers/GoogleAnalytics';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import { branch, compose, lifecycle, withProps } from 'recompose';
import { setBookingPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';

const DATE_FORMAT = 'DDMMYYHHmm';

const transformConfirmationToReceipt = ({ body: { bookedFlight, passengerDetails, pnr, searchParams } }) => {
  return {
    isAlternate: bookedFlight.isAlternate,
    isNearby: bookedFlight.isNearby,
    isMkt: bookedFlight.isMkt,
    pnr: pnr,
    passengerDetail: passengerDetails,
    serviceClass: searchParams.cabin,
    pnrTrips: bookedFlight.trips.map((trip) => {
      const flightSegment = [];
      trip.options.forEach((segment) => {
        const departureDate = `${segment.departureDate}${segment.departureTime}`;
        const arrivalDate = `${segment.arrivalDate}${segment.arrivalTime}`;

        flightSegment.push({
          ...segment,
          originAirport: {
            code: bookedFlight.origin.airportCode,
            name: bookedFlight.origin.airportName,
            airportCity: {
              name: bookedFlight.origin.city,
            },
          },
          destinationAirport: {
            code: bookedFlight.destination.airportCode,
            name: bookedFlight.destination.airportName,
            airportCity: {
              name: bookedFlight.destination.city,
            },
          },
          departureDate: moment.utc(departureDate, DATE_FORMAT).unix(),
          arrivalDate: moment.utc(arrivalDate, DATE_FORMAT).unix(),
          serviceClass: segment.cabin,
          type: FLIGHT_SEGMENT_TYPE.SEGMENT,
          baggageType: bookedFlight.baggageInfo.unit,
          baggageAmount: Number(bookedFlight.baggageInfo.amount),
        });

        if (segment.layover) {
          flightSegment.push({
            layoverTime: segment.layover.duration / 1000,
            type: FLIGHT_SEGMENT_TYPE.LAYOVER,
          });
        }
      });

      return {
        ...trip,
        departureDate: moment.utc(`${trip.departureDate}${trip.departureTime}`, DATE_FORMAT).unix(),
        arrivalDate: moment.utc(`${trip.arrivalDate}${trip.arrivalTime}`, DATE_FORMAT).unix(),
        direction: trip.direction === 'INTERMEDIATE' ? 'INBOUND' : trip.direction,
        flightSegment,
        tripOriginAirport: {
          airportCity: {
            name: bookedFlight.origin.city,
          },
          airportCountry: {
            code: bookedFlight.origin.countryCode,
            name: bookedFlight.origin.country,
          },
        },
        tripDestinationAirport: {
          airportCity: {
            name: bookedFlight.destination.city,
          },
          airportCountry: {
            code: bookedFlight.destination.countryCode,
            name: bookedFlight.destination.country,
          },
        },
      };
    }),
  };
};

export default compose(
  withRouter,
  withRefetch(({ params }) => {
    return {
      bookingConfirmationFetch: `${IBE_BASE_URL}/loadConfirmation?${queryString.stringify({
        pnr: params.pnr,
        ...(params.supplierCode && { supplierCode: params.supplierCode }),
      })}`,
    };
  }),
  withCallTrackingUrl(),
  connect(null, {
    updatePhoneTFN,
    setBookingPhone,
  }),
  branch(
    ({ bookingConfirmationFetch }) => bookingConfirmationFetch.pending,
    () => Loader,
  ),
  withLayout({ Aside }),
  branch(
    ({ bookingConfirmationFetch }) => bookingConfirmationFetch.rejected,
    () => () => <Error message="Failed to load itinerary details" />,
  ),
  lifecycle({
    componentDidMount() {
      triggerPageView();
    },
  }),
  withProps(({ bookingConfirmationFetch }) => ({
    receipt: transformConfirmationToReceipt(bookingConfirmationFetch.value),
  })),
  withDomain(),
  lifecycle({
    componentDidMount() {
      triggerPageView();
      if (!localStorage.getItem('call_tracking_initial_params')) {
        this.props.setBookingPhone();
      }
    },
  }),
)(ItineraryDetails);
