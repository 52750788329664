//@flow
import React from 'react';

type Props = {
  currency: string,
  className?: string,
  amount?: number,
  as?: React.Node,
};

const currencyMap = {
  USD: '$',
  CAD: 'C$',
  EUR: '€',
  INR: '₹',
};

const Currency = ({ className, currency, amount, as: As }: Props) => {
  return (
    <As className={className}>
      {currencyMap[currency]}
      {amount}
    </As>
  );
};

Currency.defaultProps = {
  as: 'p',
};

export default Currency;
