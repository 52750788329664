// @flow
import { combineReducers } from 'redux';

import saveInsuranceQuoteReducer from './insuranceQuote/save';
import modalGeneral from './modalGeneral';
import payment from './payment';
import priceCalendar from './priceCalendar';
import responseTap from './responseTap';
import authUser from './authUser/user';

const appReducer = combineReducers({
  payment,
  priceCalendar,
  responseTap,
  modalGeneral,
  quote: saveInsuranceQuoteReducer,
  authUser,
});

export default function (state: {}, action: { type: string }) {
  return appReducer(state, action);
}
