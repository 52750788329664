export const PassengerType = {
  INFANT: {
    name: 'INFANT',
    label: 'Infant',
    shortLabel: 'Inf',
  },
  CHILD: {
    name: 'CHILD',
    label: 'Child',
    shortLabel: 'Chd',
  },
  ADULT: {
    name: 'ADULT',
    label: 'Adult',
    shortLabel: 'Adt',
  },
};
