import moment from 'moment';
import React from 'react';
import { Grid, Header, Indent, Text } from 'travelopod-components';

import { formatDateToPSTTime } from '../../helpers/dateHelper';
import { getCardLabel } from '../../helpers/formatHelper';
import { formatPhone } from '../../helpers/phone';

type Props = {
  amount: React.Node,
  authorizationTime: Object,
  cardHolderName: string,
  cardType: string,
  cardNumber: string,
  phoneNumber: string,
};

const PaymentSummary = ({ amount, authorizationTime, cardHolderName, cardType, cardNumber, phoneNumber }: Props) => (
  <Indent pa="v-double h-big">
    <Header as="h3" ma="b">
      Payment Summary
    </Header>
    <Grid.Row gutter={40} md={[6, 10]} sm={[6, 10]} xs={[8, 8]}>
      <div>
        <Text secondary ma="b-half">
          Amount:
        </Text>
        <Text secondary ma="b-half">
          Time of authorization:
        </Text>
        {cardHolderName && (
          <Text secondary ma="b-half">
            Name on Credit Card:
          </Text>
        )}
        {cardType && (
          <Text secondary ma="b-half">
            Credit Card:
          </Text>
        )}
        {phoneNumber && (
          <Text secondary ma="b-half">
            Phone Number:
          </Text>
        )}
      </div>
      <div>
        <Text ma="b-half" className="e2e-payment-amount">
          {amount}
        </Text>
        <Text ma="b-half">{formatDateToPSTTime(moment.utc(authorizationTime))}</Text>
        {cardHolderName && <Text ma="b-half">{cardHolderName}</Text>}
        {cardType && (
          <Text ma="b-half">
            {getCardLabel(cardType)} - {cardNumber}
          </Text>
        )}
        {phoneNumber && <Text ma="b-half">{formatPhone(phoneNumber)}</Text>}
      </div>
    </Grid.Row>
  </Indent>
);
export default PaymentSummary;
