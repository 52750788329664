import React from 'react';
import get from 'lodash/get';
import PageContent from './PageContent.view';
import withFilters from '../../../../Hocs/withFilters';
import { NoFlights, PriceCalendar } from 'travelopod-components';
import { branch, compose, renderComponent, withProps } from 'recompose';

const calendarData = (calendarResponse, calendarStashResponse) => {
  return get(calendarResponse, 'value.data') || get(calendarStashResponse, 'value.data') || [];
};

const NoFlightsStub = () => {
  return (
    <React.Fragment>
      <PriceCalendar loading={true} data={[]} onDatesChange={() => {}} />
      <NoFlights />
    </React.Fragment>
  );
};

const isNoFlights = (searchFlightsResponse) => {
  if (!searchFlightsResponse) {
    return false;
  }

  return searchFlightsResponse.settled && searchFlightsResponse.rejected;
};

export default compose(
  withFilters(),
  withProps(({ calendarResponse, calendarStashResponse }) => ({
    calendarData: calendarData(calendarResponse, calendarStashResponse),
    calendarLoading:
      (!calendarStashResponse || calendarStashResponse.pending) && (!calendarResponse || calendarResponse.pending),
  })),
  branch(
    (props) => props.calendarResponse && props.calendarResponse.rejected && isNoFlights(props.searchFlightsResponse),
    renderComponent(NoFlightsStub),
  ),
)(PageContent);
