import ReactGA from 'react-ga';

import { GA_ID } from '../constants/config';
import { ACTIONS, CATEGORY, LABELS } from '../constants/googleAnalyticsEvents';

const getCategory = domainName => CATEGORY[domainName];

export const initTracking = () => {
  ReactGA.initialize(GA_ID);
};

export const trigger = (category, event) => {
  ReactGA.event({
    category: getCategory(category),
    ...event,
  });
};

export const triggerPageView = (url = window.location.pathname + window.location.search) => {
  ReactGA.pageview(url);
};

function createEvent(action, label) {
  return category => trigger(category, { action, label });
}

export const triggerExitIntentShow = createEvent(ACTIONS.EXIT_INTENT, LABELS.EXIT_INTENT_SHOW);
export const triggerExitIntentHide = createEvent(ACTIONS.EXIT_INTENT, LABELS.EXIT_INTENT_HIDE);
export const triggerExitIntentSubmit = createEvent(ACTIONS.EXIT_INTENT, LABELS.EXIT_INTENT_SUBMIT);
