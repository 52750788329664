import './Banner.css';

import React from 'react';
import { compose, lifecycle, withState } from 'recompose';

import { getBannerInfo } from '../../api/modules/banner';
import { markAsClosed, wasClosed } from '../../helpers/banner';

function splitToComponents(message) {
  if (message.indexOf('[') === -1 || message.indexOf(']') === -1) {
    return [message];
  }
  const linkIndexes = [];
  let openIndex = null;

  for (let i = 0; i < message.length; i++) {
    if (openIndex !== null && message[i] === ']') {
      linkIndexes.push([openIndex, i]);
      openIndex = null;
    }

    if (openIndex === null && message[i] === '[') {
      openIndex = i;
    }
  }
  const components = [];
  let startIndex = 0;

  for (let i in linkIndexes) {
    const [start, end] = linkIndexes[i];
    const stringComponent = message.slice(startIndex, start);
    components.push(stringComponent);
    startIndex = end + 1;
    const link = message.slice(start + 1, end);
    const [text, href] = link.split('::');

    components.push(
      <a className="banner-link" target="_blank" href={href}>
        {text}
      </a>,
    );
  }
  components.push(message.slice(startIndex));
  return components;
}

function Banner(props) {
  if (props.isClosed || !props.messages || props.messages.length === 0) {
    return null;
  }

  return (
    <div className="banner_container">
      <ul>
        {props.messages.map((msg) => (
          <li className="banner__container--item" key={msg.id}>
            {splitToComponents(msg.message)}
          </li>
        ))}
      </ul>
      <button
        className="banner__container--close-btn"
        onClick={() => {
          markAsClosed();
          props.close(true);
        }}
      >
        x
      </button>
    </div>
  );
}
export default compose(
  withState('messages', 'onMessagesChange', null),
  withState('isClosed', 'close', !!wasClosed()),
  lifecycle({
    async componentDidMount() {
      const bannerInfo = await getBannerInfo();
      if (Array.isArray(bannerInfo)) {
        this.props.onMessagesChange(bannerInfo);
      }
    },
  }),
)(Banner);
