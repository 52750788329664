export const FETCH_RECEIPT: 'payment/FETCH_RECEIPT' = 'payment/FETCH_RECEIPT';
export const FETCH_RECEIPT_SUCCESS: 'payment/FETCH_RECEIPT_SUCCESS' = 'payment/FETCH_RECEIPT_SUCCESS';
export const FETCH_RECEIPT_FAILURE: 'payment/FETCH_RECEIPT_FAILURE' = 'payment/FETCH_RECEIPT_FAILURE';

type FetchReceipt = {
  type: typeof FETCH_RECEIPT,
};
export type FetchReceiptSuccess = {
  type: typeof FETCH_RECEIPT_SUCCESS,
};
type FetchReceiptFailure = {
  type: typeof FETCH_RECEIPT_FAILURE,
  error: string,
};

type ActionType = FetchReceipt | FetchReceiptSuccess | FetchReceiptFailure;

export type State = {
  data: Object,
  loading: boolean,
  error: ?string,
  loaded: boolean,
};

const initialState: State = {
  data: {},
  loading: false,
  error: null,
  loaded: false,
};

export default function paymentConfirmReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RECEIPT:
      return {
        ...initialState,
        loading: true,
      };

    case FETCH_RECEIPT_SUCCESS:
      return {
        ...initialState,
        data: action.result,
        loading: false,
      };

    case FETCH_RECEIPT_FAILURE:
      return {
        ...initialState,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}

export function fetchReceipt(data) {
  return {
    type: FETCH_RECEIPT,
    data,
  };
}
