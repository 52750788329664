// @flow
import React from 'react';
import { connect as reduxConnect } from 'react-redux';

import type { ModalPropsT } from '../../../../Hocs/withModal';
import { getCallUsFooterStatus, toggleCallUsFooterModal } from '../../../../store/modules/modalGeneral';

const FlightsFooter = (props: ModalPropsT) => {
  return <React.Fragment></React.Fragment>;
};

export default reduxConnect((state) => ({ callUsStatus: getCallUsFooterStatus(state) }), { toggleCallUsFooterModal })(
  FlightsFooter,
);
