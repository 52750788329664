import classNames from 'classnames';
import React from 'react';
import { defaultProps } from 'recompose';

import Trustpilot from './Trustpilot';

const TrustpilotHorizontal = props => (
  <div className={classNames('trustpilot-wrapper', { fluid: props.fluid })}>
    <div className="trustpilot-horizontal" />
    <Trustpilot {...props} />
  </div>
);

export default defaultProps({
  'data-template-id': '5406e65db0d04a09e042d5fc',
  'data-style-height': '24px',
})(TrustpilotHorizontal);
