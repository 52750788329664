import React from 'react';

class Trustpilot extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps['data-style-height'] !== this.props['data-style-height']) {
      const styles = this.trustpilotFrame.children[0].getAttribute('style').split(';');

      styles.forEach((item, index) => {
        if (item.includes('height')) {
          styles[index] = ` height: ${this.props['data-style-height']}`;
        }
      });

      this.trustpilotFrame.children[0].setAttribute('style', styles.join(';'));
    }
  }

  setTrustpilotRef = node => {
    node && this.loadFromElement(node);
    this.trustpilotFrame = node;
  };

  loadFromElement(element) {
    const { Trustpilot } = window;

    Trustpilot && Trustpilot.loadFromElement(element);
  }

  render() {
    return (
      <div ref={this.setTrustpilotRef} {...this.props}>
        <a href="https://www.trustpilot.com/review/travelopod.com" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    );
  }
}

Trustpilot.defaultProps = {
  className: 'trustpilot-widget',
  'data-businessunit-id': '51c1ebb90000640005477b45',
  'data-locale': 'en-US',
  'data-style-width': '100%',
  'data-theme': 'light',
  'data-stars': '5',
};

export default Trustpilot;
