import moment from 'moment';
import { connect } from 'react-redux';
import {
  getAirportsTo,
  getAirportsFrom,
  getAirportsReturnTo,
  writeSearchToReports,
  getAirportsReturnFrom,
} from '../../../../api/modules/searchFlights';
import { withLoader } from 'travelopod-components';
import withFilters from '../../../../Hocs/withFilters';
import withRefetch from '../../../../Hocs/withRefetch';
import { compose, lifecycle, withProps } from 'recompose';
import PriceCalendarFilters from './PriceCalendarFilters.view';
import withCallTrackingUrl from '../../../../Hocs/withCallTrackingUrl';
import { MARKETING_URL_GROUP_TRAVEL } from '../../../../constants/global';
import { updatePhoneTFN } from '../../../../store/modules/responseTap/phoneData';

const defaultFilters = () => ({
  startDate: moment().utc().startOf('day').unix(),
  endDate: moment().utc().startOf('day').unix(),
  serviceClass: 'ECONOMY',
  optionsSearch: 'ALTERNATE',
  tripType: 'ROUND_TRIP',
  from: null,
  to: null,
  adults: 1,
  children: 0,
  infants: 0,
});

export default compose(
  withCallTrackingUrl(),
  connect(
    (state) => ({
      userData: state.authUser.user,
    }),
    {
      updatePhoneTFN,
    },
  ),
  withRefetch(() => ({
    getAirportsFrom,
    getAirportsTo,
    getAirportsReturnFrom,
    getAirportsReturnTo,
    writeSearchToReports,
  })),
  withLoader(({ responses }) => responses.some((el) => el && el.pending)),
  withFilters(defaultFilters),
  withProps((props) => ({
    onInit: props.onInit,
    startDate: props.queryFilters.startDate || '',
    endDate: props.queryFilters.endDate || '',
    from: props.queryFilters.from || '',
    to: props.queryFilters.to || '',
    returnFrom: props.queryFilters.returnFrom || '',
    returnTo: props.queryFilters.returnTo || '',
    handleFromChange: (_, { value }) => {
      return props.getAirportsFrom(value);
    },
    handleToChange: (_, { value }) => {
      return props.getAirportsTo(value);
    },
    handleReturnFromChange: (_, { value }) => {
      return props.getAirportsReturnFrom(value);
    },
    handleReturnToChange: (_, { value }) => {
      return props.getAirportsReturnTo(value);
    },
    handleSearch: (value) => {
      const nextFilters = {
        ...value,
        from: value.from.code,
        to: value.to.code,
        returnFrom: value.tripType === 'MULTI_CITY' ? value.returnFrom.code : '',
        returnTo: value.tripType === 'MULTI_CITY' ? value.returnTo.code : '',
        sort: 'cheapest',
        stops: 'anyStopsCount',
        airlines: [],
        departing: [],
        arriving: [],
        amenities: [],
        layover: [],
        outbound: [],
        return: [],
        maxFlightDuration: value.maxFlightDuration,
      };
      delete nextFilters.maxFlightDuration;
      props.onSearch();

      props.updatePhoneTFN({ destinationIATA: value.to.code, cabin: value.serviceClass });

      props.upsertFilters(nextFilters);
      props.handleSearch();
      props.onInit([value.from, value.to, value.returnFrom, value.returnTo]);
      props.writeSearchToReports(
        { ...nextFilters, ...(props.userData?.email && { userEmail: props.userData.email }) },
        props.getDomainName(true),
      );
    },
    redirectToGroupPage: (values) => {
      const url = props.buildMarketingUrl(MARKETING_URL_GROUP_TRAVEL, values);
      window.open(url, '_blank');
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.updateAirportIATA();
    },
  }),
)(PriceCalendarFilters);
