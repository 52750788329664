import { SupportUpgradeMap } from '../constants/global';

export const parseExpirationDate = (expirationDate: string) => {
  const month = expirationDate.slice(0, 2);
  const year = expirationDate.slice(2);

  return {
    month,
    year,
  };
};

export const calculateAdditionalServices = ({ upgradeSupportPackage }, passengers, isRoundTrip) => {
  if (!upgradeSupportPackage) {
    return 0;
  }
  const packCost = SupportUpgradeMap[upgradeSupportPackage].cost;
  const roundTripRatio = upgradeSupportPackage.includes('UPGRADE') && isRoundTrip ? 2 : 1;
  return (passengers.ADULT + passengers.CHILD) * roundTripRatio * packCost;
};
