import React from 'react';
import {
  AnimatedWrapper,
  BannerSkeleton,
  FlightCardSkeleton,
  FlightListBannerSkeleton,
  SortingSkeleton,
} from 'travelopod-components';

const DELAY = 0;

export default function Skeleton() {
  return (
    <AnimatedWrapper delay={DELAY}>
      <SortingSkeleton />
      <BannerSkeleton />
      <FlightListBannerSkeleton />
      <FlightCardSkeleton />
      <FlightCardSkeleton />
      <FlightCardSkeleton />
      <FlightListBannerSkeleton />
      <FlightCardSkeleton />
      <FlightCardSkeleton />
      <FlightCardSkeleton />
    </AnimatedWrapper>
  );
}

export function FlightCardListSkeleton({ countOfFlights }) {
  const countOfFlightCardInGroup = 3;
  return (
    <AnimatedWrapper delay={DELAY}>
      {[...Array(Math.ceil(countOfFlights / countOfFlightCardInGroup))].map((_, i) => (
        <div key={i}>
          <FlightListBannerSkeleton />
          <FlightCardSkeleton />
          <FlightCardSkeleton />
          <FlightCardSkeleton />
        </div>
      ))}
    </AnimatedWrapper>
  );
}
